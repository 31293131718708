import React, { useCallback, useReducer, useMemo, useState } from 'react';
import { isEmpty, debounce } from 'lodash';
import { Cookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Container,
  TextField,
  Box,
  Typography,
  Button,
  Divider,
  Grid,
} from '@material-ui/core';
import FilesUploadButton from '../../components/FilesUploadButton';
import {
  generatePresignedUrl,
  getTechnicalDrawingExtractorUploadedPdfS3Key,
  uploadFileToS3,
} from '../../services/s3Service';
import { getItemDetailsApi } from '../../apis/itemApi';

import { notifyError, notifySuccess } from '../../services/notificationService';
import {
  extractTechnicalDrawing,
  extractFirstPageImage,
} from '../../apis/technicalDrawingExtractionApi';
import SingleImage from '../../components/images/SingleImage';

import { USER_AGENT_INFO_KEY } from '../../constants';
import { TDE_LOCATIONS } from '../../constants/technicalDrawingExtractorConstants';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

const OPTIONS = {
  PDF_URL: 'PDF Url',
  UPLOAD_FILE: 'Upload PDF File',
  PART_ID: 'Item ID',
};

export default function TechnicalDrawingExtractor() {
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  // We separate image state and extracted info state
  // since they are using separate endpoints and React does async state updates
  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      option: OPTIONS.PDF_URL,
      pdfUrl: undefined,
      partID: undefined,
      processing: false,
      uploadedFile: undefined,
      isFileUploading: false,
      processed: undefined,
      output: undefined,
    }
  );
  const [signedImageUrl, setSignedImageUrl] = useState(undefined);

  const handleUploadPdfFile = (files) => {
    const file = files[0];
    updateLocalState({
      uploadedFile: file,
      isFileUploading: true,
      pdfUrl: undefined,
    });
    uploadFileToS3(file, getTechnicalDrawingExtractorUploadedPdfS3Key(file))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        updateLocalState({ pdfUrl: s3ObjectUrl });
      })
      .catch((err) => {
        notifyError(`Couldn't upload PDF to S3.`);
        console.error(err);
      })
      .finally(() => {
        updateLocalState({ isFileUploading: false });
      });
  };

  const requestForTechnicalDrawing = useCallback(async (partId) => {
    getItemDetailsApi(partId)
      .then((itemDetails) => {
        let techDrawingS3Url;
        const techDrawingS3UrlFromOriginalFiles = itemDetails.originalFiles
          ?.split(',')
          .filter((url) => url.toLowerCase().includes('.pdf'))[0];

        if (techDrawingS3UrlFromOriginalFiles) {
          notifySuccess(
            `Found technical drawing for Part ${partId} in customer's original files!`
          );
          techDrawingS3Url = techDrawingS3UrlFromOriginalFiles;
        } else {
          // No technical drawing found
          updateLocalState({ pdfUrl: undefined });
          return;
        }

        updateLocalState({ pdfUrl: techDrawingS3Url });
      })
      .catch(() => {
        // itemApi returns Bad Request
        updateLocalState({ pdfUrl: undefined });
      });
  }, []);

  const debouncedRequestForTechnicalDrawing = useMemo(() => {
    return debounce(requestForTechnicalDrawing, 500);
  }, [requestForTechnicalDrawing]);

  const handlePartIdChange = async (event) => {
    const partId = event.target.value;
    updateLocalState({ partID: partId });

    if (isEmpty(partId)) {
      updateLocalState({ pdfUrl: undefined });
      return;
    }
    debouncedRequestForTechnicalDrawing(partId);
  };

  const handleExtract = async () => {
    const userAgentInfo = cookies.get(USER_AGENT_INFO_KEY);
    const params = {
      pdfUrl: localState.pdfUrl,
      tdeLocation: TDE_LOCATIONS.ADMIN_PLATFORM_GENERATION_TOOLS,
      userAgentInfo,
      userID: user.userID,
      rom_switch: false,
    };
    updateLocalState({
      processing: true,
      processed: false,
    });
    setSignedImageUrl(undefined);
    const infoPromise = extractTechnicalDrawing(params).then((data) => {
      updateLocalState({
        output: { ...localState.output, ...data },
        processed: 'done',
      });
    });
    const imagePromise = extractFirstPageImage(params)
      .then((data) => {
        return generatePresignedUrl(data.image_url);
      })
      .then((res) => {
        setSignedImageUrl(res);
      });

    Promise.all([infoPromise, imagePromise])
      .catch((err) => {
        notifyError(err.message);
      })
      .finally(() => {
        updateLocalState({
          processing: false,
        });
      });
  };

  return (
    <Container>
      <FormControl component='fieldset' style={{ marginTop: '2rem' }}>
        <FormLabel id='shipping-mode'>Select Option</FormLabel>
        <RadioGroup
          row
          value={localState.option}
          onChange={(event) => {
            // Reset state whenever we change option
            // This is to prevent stale data from previous option and
            // to prevent user from accidentally submitting data from previous option
            updateLocalState({
              option: event.target.value,
              pdfUrl: undefined,
              partID: undefined,
              uploadedFile: undefined,
            });
          }}
        >
          {Object.entries(OPTIONS).map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={<Radio color='primary' />}
                label={value}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      {localState.option === OPTIONS.PDF_URL && (
        <TextField
          required
          label='Url'
          id='image-url'
          variant='outlined'
          value={localState.pdfUrl || ''} // When value is undefined, the component becomes uncontrolled
          onChange={(event) => updateLocalState({ pdfUrl: event.target.value })}
          margin='dense'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}

      {localState.option === OPTIONS.UPLOAD_FILE && (
        <div
          style={{
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FilesUploadButton
            buttonText='Upload PDF'
            handleUploadFiles={handleUploadPdfFile}
            multiple={false}
            accept='.pdf'
          />
          <Box style={{ width: '1rem' }} />
          {localState.uploadedFile && (
            <Typography>{localState.uploadedFile.name}</Typography>
          )}
          &nbsp;
          {localState.isFileUploading === true && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
        </div>
      )}

      {localState.option === OPTIONS.PART_ID && (
        <TextField
          required
          error={isEmpty(localState.pdfUrl) && !isEmpty(localState.partID)}
          helperText={
            isEmpty(localState.pdfUrl) && !isEmpty(localState.partID)
              ? 'Part does not have technical drawing or invalid Part ID'
              : ''
          }
          label='Part ID'
          id='part-id'
          variant='outlined'
          value={localState.partID || ''}
          onChange={handlePartIdChange}
          margin='dense'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}

      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleExtract}
          disabled={
            isEmpty(localState.pdfUrl) || localState.processing === true
          }
        >
          {localState.processing === true && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Extract
        </Button>
      </div>

      <div
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
        }}
      >
        <Divider />
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
        }}
      >
        {signedImageUrl && (
          <Grid item md={8} xs={12}>
            <SingleImage url={signedImageUrl} width={'100%'} height={'100%'} />
          </Grid>
        )}
        {localState.processed && (
          <Grid item md={4} xs={12}>
            {Object.entries(localState.output).map(([key, value]) => {
              if (key === 'image_url') return;

              if (Array.isArray(value)) {
                value = value.join(', ');
              }
              return (
                <TextField
                  key={key}
                  label={key}
                  id={key}
                  variant='filled'
                  value={value}
                  margin='dense'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              );
            })}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import { ceil, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import {
  Button,
  IconButton,
  Link as LinkButton,
  Tooltip,
} from '@material-ui/core';

import { Lens as LensIcon, Replay as ReplayIcon } from '@material-ui/icons';

import { regeneratePpeForItem } from '../../apis/dbMigrationApi';
import {
  adminExportInstantLogAsCsv,
  adminSearchPPELogs,
} from '../../apis/instantQuotesLogsApi';

import { FtrButton } from '../../components/ftr-components';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import {
  NonPPEIcon,
  PPEIcon,
  RepeatOrderIcon,
  SimilarOrderIcon,
} from '../../components/icons/ItemIcon';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import JsonInfoDisplayPopup from '../../components/popups/JsonInfoDisplayPopup';
import RequestResponseInfoPopup from '../../components/popups/RequestResponseInfoPopup';
import DataGridJsonDisplayCell from '../../components/tables/cells/DataGridJsonDisplayCell';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';

import { getPpePriceForItem } from '../../apis/ppeApi';

import withRegeneratePpeItemsPopupHOC from '../../hocs/withRegeneratePpeItemsPopupHOC';
import withRepeatSimilarOrderDetailsPopupHOC from '../../hocs/withRepeatSimilarOrderDetailsPopupHOC';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  buildItemFromPpeRequest,
  getIsRepeatOrder,
  getIsSimilarOrder,
  getMatchedItemID,
  getMatchedQuotationID,
} from '../../utils/itemUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { ITEM_PPE_TYPE } from '../../components/popups/RepeatSimilarOrderDetailsPopup';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

function InstantQuotesLogsDataGridPagination(props) {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const {
    updateRepeatSimilarOrderDetailsPopupHOCState = () => {},
    updateRegeneratePpeItemsPopupHOCState = () => {},
  } = props;

  const [tableData, setTableData] = useState([]);
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [instantLogInfoPopup, updateInstantLogInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [singleInfoPopupState, updateSingleInfoPopupState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      title: '',
      selectedData: {},
      jsonData: {},
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    adminSearchPPELogs(tableQueryParams)
      .then((data) => {
        setTableData(data.rows);
        updateTableQueryParams({
          totalCount: data.totalCount,
          loading: false,
        });
        updateTableQueryParams({ loading: false });
      })
      .catch(() => {
        updateTableQueryParams({ loading: false });
      });
  };

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  const handleRegeneratePpeLog = (rowData) => {
    const parameters = rowData?.meta?.request?.parameters;
    if (isEmptyValue(parameters)) {
      return;
    }
    const body = {
      ...rowData?.meta?.request,
      ...(parameters.customer_image_file_location && {
        imageUrl: parameters.customer_image_file_location,
      }),
      parameters: {
        fileSize: parameters.fileSize,
        file_location: [
          parameters.customer_cad_file_location,
          parameters.customer_pdf_file_location,
        ].join(','),
        finish: parameters.finish,
        material: parameters.material,
        quantity: parameters.quantity,
        tolerance: parameters.tolerance,
        toleranceCheck: parameters.toleranceCheck,
      },
    };
    const toastId = toast(
      `Regenerating PPE Instant Log for Tracking ID ${rowData.id}`,
      { type: toast.TYPE.INFO, autoClose: false }
    );
    getPpePriceForItem(body)
      .then(getTableData)
      .then(() => {
        toast.update(toastId, {
          renderCell: `Regenerating PPE Instant Log for Tracking ID ${rowData.id} successfully.`,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.update(toastId, {
          renderCell: `Regenerating PPE Instant Log for Tracking ID ${rowData.id} failed.`,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      });
  };

  const handleRegenerateItemPpeLog = (itemID) => {
    const toastId = toast(`Regenerating PPE Instant Log for itemID=${itemID}`, {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    regeneratePpeForItem({ itemIDs: [itemID] })
      .then(getTableData)
      .then(() => {
        toast.update(toastId, {
          renderCell: `Regenerating PPE Instant Log for itemID=${itemID} successfully.`,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.update(toastId, {
          renderCell: `Regenerating PPE Instant Log for itemID=${itemID} failed.`,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      });
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = async () => {
    setIsGeneratingCsv(true);
    try {
      // Call the API to generate the CSV and get the download URL
      const response = await adminExportInstantLogAsCsv(tableQueryParams);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Download the CSV file using the download URL
      const link = document.createElement('a');
      link.href = downloadUrl;
      const fileName = `Instant Quote Logs ${generateCurrentCustomDateTimeString()}.csv`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
      setIsGeneratingCsv(false);
    }
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <FtrButton
          color='blue'
          onClick={() => {
            updateRegeneratePpeItemsPopupHOCState({
              open: true,
              refetchData: getTableData,
            });
          }}
        >
          Generate Log for Item
        </FtrButton>
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              disabled={isGeneratingCsv === true}
              loading={isGeneratingCsv === true}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  const columns = [
    {
      headerName: 'Unseen',
      field: 'unseen',
      renderCell: ({ row: rowData }) => {
        if (rowData.hasSeen !== 0) return null;
        return (
          <Tooltip title='Unseen log'>
            <LensIcon color='error' fontSize='small' />
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Item ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
              <Button
                className={classes.linkButton}
                href={`/item/edit/${rowData.itemID}`}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
            <Tooltip title='Regenerate PPE Instant Log'>
              <IconButton
                className={classes.iconButton}
                aria-label='regenerate'
                size='small'
                onClick={() => {
                  if (rowData.itemID) {
                    handleRegenerateItemPpeLog(rowData.itemID);
                  } else {
                    handleRegeneratePpeLog(rowData);
                  }
                }}
              >
                <ReplayIcon color='primary' style={{ fontSize: '1.2rem' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      width: 120,
    },
    {
      headerName: 'Customer ID',
      field: 'customerId',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.customerId} />
      ),
    },
    {
      headerName: 'Customer Email',
      field: 'customerEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.customerEmail} />
      ),
      width: 200,
    },
    {
      headerName: 'Request',
      field: 'request',
      renderCell: ({ row: rowData }) => (
        <DataGridJsonDisplayCell
          jsonObj={rowData.meta.request}
          seeMoreButtonClick={() => {
            updateInstantLogInfoPopup({
              open: true,
              selectedData: rowData,
            });
          }}
        />
      ),
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.request) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Response',
      field: 'response',
      renderCell: ({ row: rowData }) => (
        <DataGridJsonDisplayCell
          jsonObj={rowData.meta.response}
          seeMoreButtonClick={() => {
            updateInstantLogInfoPopup({
              open: true,
              selectedData: rowData,
            });
          }}
        />
      ),
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.response) ?? '';
      },
      width: 250,
    },
    {
      title: 'Item Type',
      headerName: 'Item Type',
      field: 'itemType',
      renderCell: ({ row: rowData }) => {
        const isPPE = Number(rowData?.meta?.response?.customerPrice);
        const parameters = rowData?.meta?.response?.additionalParameters;

        return (
          <FlexRow>
            {isPPE ? <PPEIcon /> : <NonPPEIcon />}
            {getIsRepeatOrder(parameters) && (
              <RepeatOrderIcon
                onClick={() => {
                  const request = rowData?.meta?.request;
                  const response = rowData?.meta?.response;

                  const matchedItemID = getMatchedItemID(parameters);
                  const refQuoteID = getMatchedQuotationID(parameters);
                  const requestItem = buildItemFromPpeRequest(
                    request,
                    response
                  );

                  updateRepeatSimilarOrderDetailsPopupHOCState({
                    open: true,
                    itemID: rowData.itemID,
                    matchedItemID,
                    refQuoteID,
                    requestItem: isEmptyValue(rowData.itemID)
                      ? requestItem
                      : undefined,
                    type: ITEM_PPE_TYPE.REPEAT,
                  });
                }}
              />
            )}
            {getIsSimilarOrder(parameters) && (
              <SimilarOrderIcon
                onClick={() => {
                  const request = rowData?.meta?.request;
                  const response = rowData?.meta?.response;
                  const matchedItemID = getMatchedItemID(parameters);
                  const refQuoteID = getMatchedQuotationID(parameters);
                  const requestItem = buildItemFromPpeRequest(
                    request,
                    response
                  );

                  updateRepeatSimilarOrderDetailsPopupHOCState({
                    open: true,
                    itemID: rowData.itemID,
                    requestItem: isEmptyValue(rowData.itemID)
                      ? requestItem
                      : undefined,
                    matchedItemID,
                    refQuoteID,
                    type: ITEM_PPE_TYPE.SIMILAR,
                  });
                }}
              />
            )}
          </FlexRow>
        );
      },
      width: 120,
    },
    {
      headerName: 'PPE Error',
      field: 'ppeError',
      renderCell: ({ row: rowData }) => {
        const response = rowData.meta.response;

        if (typeof response === 'string') {
          return response; // guard if ppe failed when called
        }

        let error = get(rowData, 'meta.response.additionalParameters.error');
        let errorList = [];
        if (typeof error === 'string') {
          const text = `"error" = "${error}"`;
          errorList.push(text);
        } else if (typeof error === 'object') {
          if (!isEmptyValue(error)) {
            errorList = [
              ...errorList,
              ...Object.values(error)
                .filter((val) => val !== 'None' && !Array.isArray(val))
                .map((val) => val),
            ];
          }
        }

        error = get(rowData, 'meta.error');
        if (typeof error === 'string') {
          const text = `"error" = "${error}"`;
          errorList.push(text);
        } else if (typeof error === 'object') {
          if (!isEmptyValue(error)) {
            errorList = [
              ...errorList,
              ...Object.values(error)
                .filter((val) => val !== 'None' && !Array.isArray(val))
                .map((val) => val),
            ];
          }
        }

        error = get(rowData, 'meta.response.error');
        if (typeof error === 'object') {
          if (!isEmptyValue(error)) {
            errorList = [
              ...errorList,
              ...Object.values(error)
                .filter((val) => val !== 'None' && !Array.isArray(val))
                .map((val) => val),
            ];
          }
        }

        if (isEmptyValue(errorList)) return 'N.A.';

        return (
          <DataGridWrapCell>
            <ul style={{ paddingInlineStart: '18px' }}>
              {errorList.map((val) => (
                <li key={val}>{val}</li>
              ))}
            </ul>
          </DataGridWrapCell>
        );
      },
      width: 250,
    },
    {
      headerName: 'Platform Error',
      field: 'platformError',
      renderCell: ({ row: rowData }) => {
        const fallOutReasons = rowData.meta?.fallOutReasons;
        if (isEmptyValue(fallOutReasons)) {
          return 'N.A.';
        }
        return (
          <div
            style={{
              maxHeight: 90,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {fallOutReasons.map((reason) => {
              return <DataGridWrapTextCell key={reason} text={`- ${reason}`} />;
            })}
          </div>
        );
      },
      width: 250,
    },
    {
      headerName: 'User Agent Info',
      field: 'userAgentInfo',
      renderCell: ({ row: rowData }) => {
        const userAgentInfo = rowData.meta?.userAgentInfo;
        return (
          <DataGridJsonDisplayCell
            jsonObj={userAgentInfo}
            seeMoreButtonClick={() => {
              updateSingleInfoPopupState({
                open: true,
                title: 'User Agent Info',
                selectedData: rowData,
                jsonData: userAgentInfo,
              });
            }}
          />
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.meta.userAgentInfo) ?? '';
      },
      width: 200,
    },
    {
      headerName: 'Other Info',
      field: 'otherInfo',
      renderCell: ({ row: rowData }) => {
        const meta = rowData.meta ?? {};
        const { request, response, userAgentInfo, ...otherInfo } = meta;
        return (
          <DataGridJsonDisplayCell
            jsonObj={otherInfo}
            seeMoreButtonClick={() => {
              updateSingleInfoPopupState({
                open: true,
                title: 'Other Info',
                selectedData: rowData,
                jsonData: otherInfo,
              });
            }}
          />
        );
      },
      valueGetter: ({ row: rowData }) => {
        const meta = rowData.meta ?? {};
        const { request, response, userAgentInfo, ...otherInfo } = meta;
        return JSON.stringify(otherInfo) ?? '';
      },
      width: 200,
    },
    {
      headerName: 'Log ID',
      field: 'id',
      width: 300,
    },
    {
      headerName: 'Caller ID',
      field: 'callerID',
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      renderCell: ({ row: rowData }) => {
        return (
          <div>{format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}</div>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        paginationMode='server'
        rows={tableData}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={100}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={tableQueryParams.loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
      {instantLogInfoPopup.open && (
        <RequestResponseInfoPopup
          open={instantLogInfoPopup.open}
          handleClose={() => updateInstantLogInfoPopup({ open: false })}
          request={JSON.stringify(
            instantLogInfoPopup.selectedData?.meta?.request,
            null,
            2
          )}
          response={JSON.stringify(
            instantLogInfoPopup.selectedData?.meta?.response,
            null,
            2
          )}
          title={`Tracking ID ${instantLogInfoPopup.selectedData?.id}`}
        />
      )}
      {singleInfoPopupState.open && (
        <JsonInfoDisplayPopup
          open={singleInfoPopupState.openUserAgentInfo}
          handleClose={() => updateSingleInfoPopupState({ open: false })}
          rowData={singleInfoPopupState.selectedData}
          title={singleInfoPopupState.title}
          jsonData={singleInfoPopupState.jsonData}
        />
      )}
    </div>
  );
}

export default compose(
  withRepeatSimilarOrderDetailsPopupHOC,
  withRegeneratePpeItemsPopupHOC
)(InstantQuotesLogsDataGridPagination);

const dateFns = require('date-fns');

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { isEmptyValue } from './commonUtils';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.extend(isSameOrAfter);

export const DELIVERY_DATE_FORMAT = 'ddd, DD MMM YYYY';
export const DATE_WITH_TIME_FORMAT = 'DD MMM YYYY HH:mm';
export const DATE_FORMAT = 'DD MMM YYYY';
export const SIMPLE_FORMAT = 'YYYY-MM-DD';
export const TITLE_DATE_FORMAT = 'Do MMMM YYYY'; // example: 26th July 2023

export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const ONE_HOUR_IN_MS = ONE_HOUR_IN_SECONDS * 1000;
export const ONE_DAY_IN_SECONDS = 24 * ONE_HOUR_IN_SECONDS;
export const FOURTEEN_DAYS_IN_MS = 14 * 24 * 60 * 60 * 1000;
export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const ONE_MONTH_IN_SECONDS = ONE_DAY_IN_SECONDS * 30;

const withDateValidation = (fn) => (dateStr) => {
  if (isEmptyValue(dateStr) || !dayjs(dateStr).isValid()) {
    return '';
  }
  return fn(dateStr);
};

export const getDefaultExpiredDate = () => {
  const expiredDate = new Date(Date.now() + FOURTEEN_DAYS_IN_MS);
  return dateFns.endOfDay(expiredDate).setMilliseconds(0);
};

export const getDaysElapsed = (datePosted) => {
  return Math.floor((Date.now() - Date.parse(datePosted)) / ONE_DAY_IN_MS);
};

export const getHrsElapsed = (datePosted) => {
  return (Date.now() - Date.parse(datePosted)) / ONE_HOUR_IN_MS;
};

export const compareDate = (dateStr1, dateStr2) => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return date1.getTime() - date2.getTime();
};

export const isDateInThePast = (dateStr) => {
  return dateFns.isPast(dateFns.endOfDay(new Date(dateStr)));
};

/**
 * Checks if the first date is the same as the second date, up to the specified unit of time.
 *
 * @param {Date|string|number} date1 - The first date to compare.
 * @param {Date|string|number} date2 - The second date to compare.
 * @param {string} [unit='day'] - The unit of time to compare. Default is 'day'.
 * @return {boolean} Returns true if the first date is the same as the second date, false otherwise.
 */
export const isSameDate = (date1, date2, unit = 'day') => {
  return dayjs(date1).isSame(date2, unit);
};

export const isSameOrBefore = (date1, date2) => {
  return date1.getTime() <= date2.getTime();
};

export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

/**
 * return a date string in the format eg: 2022-06-09 06:18:00
 */
export const getDateStr = (date) => {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return dateFns.format(dateObj, 'yyyy-MM-dd HH:mm:ss.SS');
};

export const getCurrentDateStr = () => {
  return getDateStr(new Date());
};

export const getDateStrWithMonth = (date, monthType = 'short') => {
  if (date === null) {
    return 'N.A';
  }
  const dateFormat = { year: 'numeric', month: monthType, day: 'numeric' };
  return new Date(date).toLocaleDateString('en-GB', dateFormat);
};

export const getSGTDateStrWithMonth = (date, monthType = 'long') => {
  if (date === null) {
    return 'N.A';
  }
  const dateFormat = {
    year: 'numeric',
    month: monthType,
    day: 'numeric',
    timeZone: 'Asia/Singapore',
  };
  return new Date(date).toLocaleString('en-GB', dateFormat);
};

export const addSeconds = (date, numOfSeconds) => {
  return dateFns.addSeconds(date, numOfSeconds);
};

export const addDays = (date, numOfDays) => {
  return dateFns.addDays(date, numOfDays);
};

export const addBusinessDays = (date, numOfDays) => {
  return dateFns.addBusinessDays(date, numOfDays);
};

export const addMonths = (date, numOfMonths) => {
  return dateFns.addMonths(date, numOfMonths);
};

export const dateTzSingapore = (date, formatStr = DATE_WITH_TIME_FORMAT) => {
  return dayjs(date).tz('Singapore').format(formatStr);
};

export const formatDateWithTime = (date) => {
  return dayjs(date).format(DATE_WITH_TIME_FORMAT);
};

export const formatDeliveryDate = (dateStr) => {
  return dayjs(dateStr).format(DELIVERY_DATE_FORMAT);
};

export const formatDeliveryDateWithCheck =
  withDateValidation(formatDeliveryDate);

export const formatSimpleDate = (dateStr) => {
  return dayjs(dateStr).format(SIMPLE_FORMAT);
};

export const formatDate = (dateStr, formatStr = SIMPLE_FORMAT) => {
  return dayjs(dateStr).format(formatStr);
};

/**
 *
 * @param {String | Date} dateStr
 * @example 26th July 2023
 */
export const formatTitleDate = (dateStr) => {
  return dayjs(dateStr).format(TITLE_DATE_FORMAT);
};

export const addHoursAndRoundUpMinute = (
  date,
  numOfHours = 2,
  format = DATE_WITH_TIME_FORMAT
) => {
  const parsedDate = dayjs(date);
  // Round up the hours if minutes are greater than 0
  if (parsedDate.minute() > 0) {
    return dayjs(date)
      .add(numOfHours + 1, 'hour')
      .minute(0)
      .format(format);
  }
  return dayjs(date).add(numOfHours, 'hour').format(format);
};

export const getMinDate = (arrObjDate, keyDate = 'date') => {
  return new Date(
    Math.min(
      ...arrObjDate.map((element) => {
        return new Date(element[keyDate]);
      })
    )
  );
};

/**
 * Checks if the first date is the same or after the second date, up to the specified unit of time.
 *
 * @param {Date|string|number} date1 - The first date to compare.
 * @param {Date|string|number} date2 - The second date to compare.
 * @param {string} [unit='day'] - The unit of time to compare. Default is 'day'.
 * @return {boolean} Returns true if the first date is the same or after the second date, false otherwise.
 */
export const isSameOrAfterDate = (date1, date2, unit = 'day') => {
  return dayjs(date1).isSameOrAfter(date2, unit);
};

/**
 * Checks if a string or a number is a valid  timestamp.
 *
 * This function assumes that the timestamp must be a string of 13 digits
 * (a timestamp in milliseconds) without leading zeroes.
 *
 * This implies that timestamps smaller than 1000000000000 are invalid, and so
 * date times before Sunday, 9 September 2001 are invalid.
 *
 * @param timestamp
 * @returns {boolean}
 */
export const isUnixTimestampValid = (timestamp) => {
  if (typeof timestamp !== 'string' && typeof timestamp !== 'number') {
    return false;
  }

  const timestampStr = String(timestamp);

  // Ensure the timestamp has exactly 13 digits (milliseconds-based Unix timestamp)
  if (!/^\d{13}$/.test(timestampStr)) {
    return false;
  }

  const timestampNumber = Number(timestamp);
  if (isNaN(timestampNumber)) {
    return false;
  }

  const dateFromMilliseconds = new Date(timestampNumber);
  return dateFns.isValid(dateFromMilliseconds);
};

/**
 * To get max date from array of date
 * @param {*} listDate
 * @returns
 */
export const getMaxDate = (listDate = []) => {
  if (isEmptyValue(listDate)) {
    return null;
  }

  return new Date(Math.max(...listDate.map((date) => new Date(date))));
};

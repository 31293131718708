import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Popover } from '@material-ui/core';

import { Pagination } from '@material-ui/lab';

import { Cancel, Check } from '@material-ui/icons';

import FtrChip from '../../components/chips/FtrChip';
import {
  FtrButton,
  FtrH4,
  FtrTextField,
} from '../../components/ftr-components';
import FtrDismissGuideText from '../../components/ftr-components/FtrDismissGuideText';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowSpaceBetween,
} from '../../components/layouts/FlexLayouts';

import useAutoBalloonCanvas from '../../hooks/useAutoBalloonCanvas';

import { getUserIDSelector } from '../../selectors/userSelector';

import { asyncMap } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { notifyError } from '../../services/notificationService';
import { generatePresignedUrl } from '../../services/s3Service';

import { ACTION_TRACKING_TYPE } from '../../constants/actionTrackingConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  canvas: {
    zIndex: 10,
    PointerEvent: 'auto',
  },
  icon: {
    '&:hover': {
      opacity: '80%',
    },
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: 16,
  },
  checkIcon: {
    backgroundColor: colors.successGreen,
    padding: 3,
  },
  cancelIcon: {
    backgroundColor: colors.hotRed,
    fill: colors.fontWhite,
    fontSize: 22,
  },
}));

function AutoBalloonPresentational(props) {
  const classes = useStyles();

  const {
    originalImageUrls = [],
    annotationData = [],
    totalPages = 0,
    annotatedImageUrls = [],
    autoBallooning = false,
    manualBallooning = false,
    processing = false,
    handleDeleteBalloon = () => {},
    addManualBalloons = () => {},
    setAnnotationData = () => {},
  } = props;

  const userID = useSelector(getUserIDSelector);

  const {
    overlayCanvasRef,
    canvasWrapperRef,
    currentPage,
    pageImageRatioMapping,
    getRectangles,
    setImageUrls,
    setCurrentPage,
    setWrapperDimensions,
    removeAllRectangles,
  } = useAutoBalloonCanvas();

  const [selectedAnnotationIndex, setSelectedAnnotationIndex] =
    React.useState(null);
  const [editBalloonText, setEditBalloonText] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openEditBalloonText = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (isEmptyValue(annotatedImageUrls)) {
      return;
    }

    asyncMap(annotatedImageUrls, async (imgUrl) =>
      generatePresignedUrl(imgUrl)
    ).then((imageUrls) => {
      setImageUrls(imageUrls);
    });
  }, [annotatedImageUrls]);

  useEffect(() => {
    if (canvasWrapperRef.current) {
      const rect = canvasWrapperRef.current.getBoundingClientRect();
      setWrapperDimensions({
        width: rect.width - 10, // prevent scrollbar
        height: rect.height - 10, // prevent scrollbar
      });
    }
  }, [canvasWrapperRef.current]);

  const handleAddBalloons = () => {
    const rects = getRectangles();
    if (isEmptyValue(rects)) {
      notifyError('Please draw rectangles first');
      return;
    }

    const bboxes = rects.map((rect) => rect.getBoundingRect());
    const newBalloon = bboxes.map((bbox) => ({
      text: 'x',
      bbox: [
        Math.round(bbox.left / (pageImageRatioMapping[currentPage] || 1)),
        Math.round(bbox.top / (pageImageRatioMapping[currentPage] || 1)),
        Math.round(bbox.width),
        Math.round(bbox.height),
      ],
      manual: true,
      page: currentPage + 1,
    }));

    addManualBalloons(newBalloon)
      .then(() => {
        removeAllRectangles();
      })
      .catch(() => {
        // do nothing
      });
  };

  const handleEditBalloon = (event, annotationIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedAnnotationIndex(annotationIndex);
    const data = annotationData[annotationIndex];
    setEditBalloonText(data.text);
  };

  const handleCloseEditBalloonText = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FlexRowSpaceBetween
        style={{ alignItems: 'start', backgroundColor: 'white' }}
      >
        {renderCanvas()}
        {renderBallooningInfo()}
      </FlexRowSpaceBetween>
      <Popover
        id={id}
        open={openEditBalloonText}
        anchorEl={anchorEl}
        onClose={handleCloseEditBalloonText}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            marginTop: -8,
            borderRadius: 10,
            paddingTop: 8,
            boxShadow: 'none',
          },
        }}
      >
        <FlexRow>
          <FtrTextField
            label='Balloon Text'
            value={editBalloonText}
            onChange={(e) => setEditBalloonText(e.target.value)}
            fullWidth
            style={{
              marginBottom: 0,
            }}
          />
          <Check
            className={`${classes.icon} ${classes.checkIcon}`}
            onClick={() => {
              const data = annotationData[selectedAnnotationIndex];
              data.text = editBalloonText;
              setAnnotationData([...annotationData]);
              handleCloseEditBalloonText();
            }}
          />
          <Cancel
            className={`${classes.icon} ${classes.cancelIcon}`}
            onClick={() => {
              handleCloseEditBalloonText();
            }}
          />
        </FlexRow>
      </Popover>
    </>
  );

  function renderCanvas() {
    if (isEmptyValue(originalImageUrls)) {
      return;
    }

    return (
      <FlexColumnCenter
        style={{
          padding: '1rem',
          width: '100%',
          boxSizing: 'border-box',
          flexGrow: 1,
          maxWidth: 'calc(100vw - 320px)',
        }}
      >
        <FlexRowSpaceBetween style={{ width: '100%', boxSizing: 'border-box' }}>
          <FtrDismissGuideText
            text={
              <>
                Can draw rectangles inside the image then click{' '}
                <span style={{ fontWeight: 'bold', color: colors.blue060 }}>
                  Add Manual Balloons
                </span>{' '}
                to add manual balloons.
              </>
            }
            userID={userID}
            actionTrackingKey={
              ACTION_TRACKING_TYPE.ADMIN_BALLOON_DRAW_RECTANGLE_DISMISS
            }
          />
          {renderAddManualBalloonsButton()}
        </FlexRowSpaceBetween>
        <div
          ref={canvasWrapperRef}
          style={{
            zIndex: '9',
            visibility: 'visible',
            border: colors.darkGreyBorder,
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            position: 'relative',
            backgroundColor: 'white',
          }}
        >
          <canvas
            id='canvas'
            ref={overlayCanvasRef}
            className={classes.canvas}
          />
        </div>
        <Pagination
          count={totalPages}
          page={currentPage + 1}
          onChange={(_, newPage) => setCurrentPage(newPage - 1)}
        />
      </FlexColumnCenter>
    );
  }

  function renderBallooningInfo() {
    if (isEmptyValue(annotationData) || annotationData.length <= 0) {
      return;
    }

    return (
      <FlexColumn
        style={{
          marginTop: '1rem',
          flexGrow: 0,
          maxHeight: '100vh',
          gap: '1rem',
          boxSizing: 'border-box',
          backgroundColor: 'white',
          width: 'fit-content',
          minWidth: 170,
          maxWidth: 370,
        }}
      >
        <FlexColumn style={{ zIndex: 1, backgroundColor: 'white' }}>
          {!isEmptyValue(originalImageUrls) && (
            <FtrH4>Total Balloons: {annotationData.length}</FtrH4>
          )}
          {!isEmptyValue(annotationData) && (
            <FlexRow style={{ width: '100%', boxSizing: 'border-box' }}>
              <FtrDismissGuideText
                text='Click on the balloon to edit the text.'
                userID={userID}
                actionTrackingKey={
                  ACTION_TRACKING_TYPE.ADMIN_BALLOON_EDIT_TEXT_DISMISS
                }
              />
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn
          style={{
            overflow: 'auto',
            flexGrow: 1,
            boxSizing: 'border-box',
            padding: '1rem',
            border: colors.darkGreyBorder,
            borderRadius: 10,
            maxHeight: '100vh',
          }}
        >
          {annotationData
            .sort((a, b) => a.number - b.number)
            .map((data, index) => {
              const text = `${data.number}: ${data.text}`;
              return (
                <FlexRow
                  key={text}
                  style={{ margin: '0 1.5rem', width: 'fit-content' }}
                >
                  <FtrChip
                    type='info'
                    label={text}
                    tooltip={`Number: ${data.number}, Text: ${data.text}`}
                    onDelete={() => handleDeleteBalloon(data)}
                    onClick={(e) => handleEditBalloon(e, index)}
                  />
                </FlexRow>
              );
            })}
        </FlexColumn>
      </FlexColumn>
    );
  }

  function renderAddManualBalloonsButton() {
    if (isEmptyValue(originalImageUrls)) {
      return;
    }

    return (
      <FtrButton
        color='blue'
        variant='outlined'
        onClick={handleAddBalloons}
        disabled={
          isEmptyValue(annotatedImageUrls) || autoBallooning || processing
        }
        loading={manualBallooning}
      >
        Add Manual Balloons
      </FtrButton>
    );
  }
}

export default AutoBalloonPresentational;

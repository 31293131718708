import { CsvBuilder } from 'filefy';
import { ceil, isEmpty, sortBy } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

import { Avatar, IconButton, TextField, Tooltip } from '@material-ui/core';

import {
  Cancel,
  Delete,
  Edit,
  EqualizerOutlined,
  Save,
  VerifiedUser,
} from '@material-ui/icons';

import emailPending from '../../assets/icons/email_pending.svg';
import emailSent from '../../assets/icons/email_sent.svg';
import emailStopped from '../../assets/icons/email_stopped.svg';

import DisplayAddress from '../../components/DisplayAddress';
import MenuList from '../../components/MenuList';
import VerifiablePendingStatus from '../../components/VerifiablePendingStatus';
import MultiSelectChipDropdown from '../../components/dropdowns/MultiSelectChipDropdown';
import { FtrB2, FtrDropdownV2 } from '../../components/ftr-components';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import EditorAddressesPopup from '../../components/popups/EditorAddressesPopup';
import YesNoPopup from '../../components/popups/YesNoPopup';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import withSalesCustomersAssociationPopupHOC from '../../hocs/withSalesCustomersAssociationPopupHOC';

import { updateSupplierAdminControl } from '../../apis/adminControlApi';
import { updateSkipNewSignupProcesses } from '../../apis/emailTracking';
import {
  downloadSignNowPartnershipAgreement,
  signNowAgreementIsSignedBy,
} from '../../apis/signNowApi';
import {
  adminVerifyNDA,
  editSupplierInfo,
  uploadPartnershipAgreement,
  uploadPoTerms,
  uploadQuotationTerms,
  verifyUser,
} from '../../apis/userApi';

import {
  deleteUser,
  editUserData,
  getAllUsers,
  resetUsersAction,
} from '../../actions';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  getContactNumberForCSv,
  getFormattedContactNumber,
} from '../../utils/contactUtils';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import {
  addHoursAndRoundUpMinute,
  formatDateWithTime,
  getDateStrWithMonth,
} from '../../utils/dateTimeUtils';
import { downloadS3File } from '../../utils/fileUtils';
import { isAdminOrHigherRole, isSuperAdminRole } from '../../utils/roleUtils';
import {
  capitalizeString,
  getStepFromSnakeCase,
} from '../../utils/stringUtils';
import {
  convertOldFormattedContact,
  getCountryLookupDataGrid,
  getCurrencyLookupDataGrid,
  getCustomerStatusLookupDataGrid,
  getCustomerTypeLookupDataGrid,
  getDefaultShippingDeliveryInfo,
  getIndustryLookupDataGrid,
} from '../../utils/userUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';
import {
  getCustomerNDAFileS3Key,
  getCustomerQuotationTermsS3Key,
  getSupplierPartnershipAgreementS3Key,
  getSupplierPoTermsS3Key,
  uploadFileToS3,
  uploadPublicFileToS3,
} from '../../services/s3Service';

import {
  creditTypeCol,
  keyAccountCol,
  paymentTermCol,
} from '../../constants/userTableConstants';

import { ROLE_TYPES, YES_NO_VALUE_MAPPING } from '../../constants';
import {
  ONBOARDING_TECHNOLOGY_OPTION_TYPE,
  TECH_KEY_NAME_MAPPING,
} from '../../constants/NewPartConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { CUSTOMER_STATUS_TYPES } from '../../constants/customerConstants';
import { DFM_TYPES } from '../../constants/dfmConstants';
import {
  AUTOMATIC_CHASER_EMAIL_TYPE,
  EMAIL_TEMPLATE_TRIGGER,
} from '../../constants/emailConstants';
import { ANODIZING_TYPE_OPTIONS } from '../../constants/itemConstants';
import { DEFAULT_MANUAL_RFQ_MARKUP } from '../../constants/markupConstants';
import { VIEW_PARTNER_DASHBOARD_CONSTANT } from '../../constants/partnerDataDashboardConstants';
import { UNIT_TYPES } from '../../constants/unitConstants';
import { PPE_TYPES, TDE_TYPE } from '../../constants/userConstant';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
      alignItems: 'center',
    },
  },
  containerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
  },
  itemMenu: {
    padding: '5px',
    textDecoration: 'none',
    color: colors.fontGrey,
    fontSize: '0.8125rem',
    cursor: 'pointer',
    '&:is(:hover, :active)': {
      backgroundColor: colors.solidBlue,
      color: colors.fontWhite,
    },
  },
  pending: {
    backgroundColor: `${colors.warningYellow}75`,
    '&:hover': {
      backgroundColor: `${colors.warningYellow}95`,
    },
  },
  signed: {
    backgroundColor: `${colors.successGreen}75`,
    '&:hover': {
      backgroundColor: `${colors.successGreen}95`,
    },
  },
  termButton: {
    backgroundColor: `${colors.successGreen}75`,
    '&:hover': {
      backgroundColor: `${colors.successGreen}95`,
    },
  },
}));

function RowMenuCell(props) {
  const classes = useStyles();

  const { api, id, editUser, editSupplierInfo, deleteUser, setIsEditing } =
    props;
  const isInEditMode = api.getRowMode(id) === 'edit';

  const [mode, setMode] = useState('view');
  const [deleteUserPopupState, updateDeleteUserPopupState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      userID: null,
    }
  );

  const handleEditClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, 'edit');
    setMode('edit');
    setIsEditing(true);
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    api.commitRowChange(id);
    api.setRowMode(id, 'view');
    setMode('view');
    setIsEditing(false);

    const row = api.getRow(id);
    const partUploadRevampFeature =
      YES_NO_VALUE_MAPPING[row.partUploadRevampFeature] ?? null;
    const keyAccount = YES_NO_VALUE_MAPPING[row.keyAccount] ?? null;
    editUser({ ...row, partUploadRevampFeature, keyAccount });
    editSupplierInfo({ ...row });
    api.updateRows([{ ...row, isNew: false }]);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, 'view');
    setMode('view');
    setIsEditing(false);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    updateDeleteUserPopupState({
      open: true,
      userID: id,
    });
  };

  const handleDeleteUser = () => {
    deleteUser(id);
  };

  if (mode === 'edit' || isInEditMode) {
    return (
      <div className={classes.actionsColumn}>
        <IconButton
          color='primary'
          size='small'
          aria-label='save'
          onClick={handleSaveClick}
        >
          <Save fontSize='small' />
        </IconButton>
        <IconButton
          color='inherit'
          size='small'
          aria-label='cancel'
          className={classes.textPrimary}
          onClick={handleCancelClick}
        >
          <Cancel fontSize='small' />
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.actionsColumn}>
      <IconButton
        color='inherit'
        className={classes.textPrimary}
        size='small'
        aria-label='edit'
        onClick={handleEditClick}
      >
        <Edit fontSize='small' />
      </IconButton>
      <IconButton
        color='inherit'
        size='small'
        aria-label='delete'
        onClick={handleDeleteClick}
      >
        <Delete fontSize='small' />
      </IconButton>
      {deleteUserPopupState.open && (
        <YesNoPopup
          title='Are you sure that you want to delete this user?'
          open={deleteUserPopupState.open}
          handleNo={() => updateDeleteUserPopupState({ open: false })}
          handleYes={() => {
            handleDeleteUser();
            updateDeleteUserPopupState({ open: false });
          }}
          body={`UserID: ${deleteUserPopupState.userID}`}
          showCloseButton={false}
        />
      )}
    </div>
  );
}

function SelectedTagEditCell({ value, id, api, field }) {
  const selectedTagIds = value ? value.split(',') : [];

  const optionList = Object.entries(TECH_KEY_NAME_MAPPING).map(
    ([key, value]) => ({
      key,
      text: value,
    })
  );

  const handleChange = (value) => {
    api.setEditCellValue({ id, field, value });
  };

  return (
    <MultiSelectChipDropdown
      id='multi-select-tech-tags'
      label='Select Technologies'
      itemList={optionList}
      selectedItems={selectedTagIds}
      onSelect={(values) => {
        handleChange(values.join(','));
      }}
    />
  );
}

function QAndAEditCell({ value, id, api, field }) {
  const qAndA = value;

  const handleChange = (value) => {
    api.setEditCellValue({ id, field, value });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <input
        type='text'
        value={typeof qAndA === 'string' ? qAndA : qAndA?.answer || ''}
        onChange={(e) => {
          handleChange({
            ...(typeof qAndA === 'object' && qAndA),
            answer: e.target.value,
            question: qAndA?.question
              ? qAndA.question
              : 'How did you hear about us?',
          });
        }}
        placeholder='Answer'
        style={{
          width: '80%',
        }}
      />
      <input
        type='text'
        value={qAndA?.details || ''}
        onChange={(e) => {
          handleChange({
            ...qAndA,
            details: e.target.value,
            question: qAndA?.question
              ? qAndA.question
              : 'How did you hear about us?',
          });
        }}
        placeholder='Details'
        style={{
          width: '80%',
        }}
      />
    </div>
  );
}

const DefaultAnodizingTypeDropdown = ({ value, id, api, field }) => {
  const handleChangeDefaultAnodizingType = (value) => {
    api.setEditCellValue({ id, field, value });
  };

  return (
    <FtrDropdownV2
      id='anodizing-type-dropdown'
      key='anodizing-type-dropdown'
      fullWidth
      value={value || ANODIZING_TYPE_OPTIONS[0].key}
      handleChange={handleChangeDefaultAnodizingType}
      items={ANODIZING_TYPE_OPTIONS}
    />
  );
};

function AllUsersDataGrid(props) {
  const classes = useStyles();

  const {
    users = [],
    usersLoading,
    onPageLoad,
    editUser,
    editSupplierInfo,
    deleteUser,
    role,
    updateSalesCustomersAssociationPopupHOCState = () => {},
  } = props;

  const apiRef = useGridApiRef();

  const history = useHistory();

  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: users,
    });

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [showEditorAddresses, setShowEditorAddresses] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showChaserEmailPopup, setShowChaserEmailPopup] = useState({
    open: false,
    type: '',
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    // TODO this filter should be done at BE side
    let sortedUsers;
    if (role === ROLE_TYPES.REVIEWER) {
      sortedUsers = sortBy(
        users.filter((filteredUser) =>
          [ROLE_TYPES.SUPPLIER, ROLE_TYPES.BUYER].includes(filteredUser.role)
        ),
        (user) => user.userID
      ).reverse();
    } else {
      sortedUsers = sortBy(
        users.filter(
          (filteredUser) =>
            ![ROLE_TYPES.ONBOARDING_SUPPLIER].includes(filteredUser.role)
        ),
        (user) => user.userID
      ).reverse();
    }
    setSourceData(sortedUsers);
  }, [users]);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  const renderPartnerDashboard = (type) => {
    return (
      <Tooltip title={VIEW_PARTNER_DASHBOARD_CONSTANT[type].tooltip}>
        <EqualizerOutlined
          style={{
            color: VIEW_PARTNER_DASHBOARD_CONSTANT[type].color,
            cursor: type === 'default' ? 'default' : 'pointer',
          }}
        />
      </Tooltip>
    );
  };

  const handleAllowViewDashboard = (userID, body) => {
    updateSupplierAdminControl(userID, body)
      .then(() => {
        notifySuccess('Successfully updated view dashboard!');
        onPageLoad();
      })
      .catch((err) =>
        notifyError(err?.message || 'Failed updating view dashboard!')
      );
  };

  const handleVerifyUser = (userId) => {
    verifyUser(userId)
      .then(() =>
        notifySuccess(`User ${userId} has been verified successfully`)
      )
      .then(onPageLoad);
  };

  const handleAdminUploadNDA = async (rowData, files) => {
    const file = files[0];
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    const { userID, companyName } = rowData;
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `Factorem NDA - ${companyName} - Signed By All.${fileExtension}`;
      const data = await uploadFileToS3(
        file,
        getCustomerNDAFileS3Key(file),
        fileName
      );
      const s3ObjectUrl = data.Location;
      const body = {
        url: s3ObjectUrl,
      };
      await adminVerifyNDA(userID, body);
      toast.update(toastId, {
        render: 'NDA uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      onPageLoad();
    } catch (err) {
      notifyError('Error uploading NDA');
    }
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleChangeImage = async (evt, rowData) => {
    const file = evt.target.files[0];
    const s3Key = process.env.REACT_APP_PROFILEPIC_DIRNAME + '/' + file.name;
    const s3URL = await uploadPublicFileToS3(file, s3Key);
    editUser({ ...rowData, profilePic: s3URL.Location.split(' ').join('%20') });
  };

  const handleAdminUploadQuotationTerms = async (rowData, files) => {
    const file = files[0];
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    const { userID } = rowData;
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `Customer Quotation Terms - ${userID}${fileExtension}`;
      const data = await uploadFileToS3(
        file,
        getCustomerQuotationTermsS3Key(file),
        fileName
      );
      const s3ObjectUrl = data.Location;
      const body = {
        url: s3ObjectUrl,
      };
      await uploadQuotationTerms(userID, body);
      toast.update(toastId, {
        render: 'Quotation terms uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      onPageLoad();
    } catch (err) {
      notifyError('Error uploading quotation terms');
    }
  };

  const handleAdminUploadPoTerms = async (rowData, files) => {
    const file = files[0];
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    const { userID, companyName } = rowData;
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `Factorem PO Terms - ${companyName}${fileExtension}`;
      const data = await uploadFileToS3(
        file,
        getSupplierPoTermsS3Key(file),
        fileName
      );
      const s3ObjectUrl = data.Location;
      const body = {
        url: s3ObjectUrl,
      };
      await uploadPoTerms(userID, body);
      toast.update(toastId, {
        render: 'PO terms uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      onPageLoad();
    } catch (err) {
      notifyError('Error uploading PO terms');
    }
  };

  const handleAdminUploadPartnershipAgreement = async (rowData, files) => {
    const file = files[0];
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    const { userID, companyName } = rowData;
    try {
      const fileExtension = file.name.split('.').pop();
      const fileName = `Partnership Agreement - ${companyName}${fileExtension}`;
      const data = await uploadFileToS3(
        file,
        getSupplierPartnershipAgreementS3Key(file),
        fileName
      );
      const s3ObjectUrl = data.Location;
      const body = {
        url: s3ObjectUrl,
      };
      await uploadPartnershipAgreement(userID, body);
      toast.update(toastId, {
        render: 'Partnership agreement uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      onPageLoad();
    } catch (err) {
      notifyError('Error uploading partnership agreement');
    }
  };

  const handleRemoveAllChaserEmails = () => {
    const trackingIDs = selectedUser?.newSignupEmailTracking
      ?.filter(
        (el) =>
          el.emailTemplate?.includes(showChaserEmailPopup.type) &&
          !el.skip &&
          !el.sent
      )
      .map((el) => el.trackingID);
    if (isEmpty(trackingIDs)) {
      notifyError('TrackingIDs are empty');
      return;
    }
    updateSkipNewSignupProcesses({ trackingIDs })
      .then(() => {
        notifySuccess('Successfully updated new sign up tracking!');
        onPageLoad();
      })
      .catch((err) =>
        notifyError(err?.message || 'Failed updating new sign up tracking!')
      );
  };

  const renderAddress = (rowData, data) => {
    return (
      <Tooltip title='Click to see more or edit'>
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedUser(rowData);
            setShowEditorAddresses(true);
          }}
        >
          {data?.address ? <DisplayAddress data={data} /> : 'N.A.'}
        </div>
      </Tooltip>
    );
  };

  const renderCopyNDALink = (rowData) => {
    return (
      (isSuperAdminRole(role) || role === ROLE_TYPES.ADMIN) && (
        <div
          className={classes.itemMenu}
          onClick={() => {
            navigator.clipboard.writeText(rowData.ndaFileUrl);
          }}
        >
          Copy Link
        </div>
      )
    );
  };

  const renderUploadNDABtn = (rowData) => {
    return (
      isSuperAdminRole(role) && (
        <a className={classes.itemMenu}>
          <input
            id={`upload-nda-${rowData.userID}`}
            hidden
            accept='.pdf'
            type='file'
            onChange={(evt) => handleAdminUploadNDA(rowData, evt.target.files)}
            onClick={(event) => (event.target.value = null)}
            disabled={role !== ROLE_TYPES.SUPER_ADMIN}
          />
          <label htmlFor={`upload-nda-${rowData.userID}`}>
            Upload Signed NDA
          </label>
        </a>
      )
    );
  };

  const renderNAOrPendingButton = (rowData, isPending) => {
    const title = isPending ? 'Pending' : 'N.A.';
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MenuList title={title} buttonClassName={isPending && classes.pending}>
          <div className={classes.containerMenu}>
            {isPending && (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(rowData.ndaFileUrl);
                }}
              >
                Download NDA
              </Link>
            )}
            {renderUploadNDABtn(rowData)}
            {isPending && renderCopyNDALink(rowData)}
          </div>
        </MenuList>
      </div>
    );
  };

  const renderSignedButton = (rowData) => {
    const verificationLog = rowData.ndaVerificationLog;
    return (
      <div>
        <MenuList title='Signed' buttonClassName={classes.signed}>
          <div className={classes.containerMenu}>
            <Link
              className={classes.itemMenu}
              onClick={(e) => {
                e.stopPropagation();
                downloadS3File(rowData.ndaFileUrl);
              }}
            >
              Download NDA
            </Link>
            {renderUploadNDABtn(rowData)}
            {renderCopyNDALink(rowData)}
          </div>
        </MenuList>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '0.75rem',
            lineHeight: 'normal',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          {getDateStrWithMonth(rowData.ndaVerifiedDate)}
          <br />
          {verificationLog.name} ({verificationLog.userID})
        </div>
      </div>
    );
  };

  const renderUploadQuotationTermsButton = (rowData) => {
    return (
      <a className={classes.itemMenu}>
        <input
          id={`upload-quotationTerms-${rowData.userID}`}
          hidden
          accept='.pdf'
          type='file'
          onChange={(evt) =>
            handleAdminUploadQuotationTerms(rowData, evt.target.files)
          }
          onClick={(event) => (event.target.value = null)}
        />
        <label htmlFor={`upload-quotationTerms-${rowData.userID}`}>
          Upload Custom Quotation Terms
        </label>
      </a>
    );
  };

  const renderCopyQuotationTermsLink = (rowData) => {
    return (
      (isSuperAdminRole(role) || role === ROLE_TYPES.ADMIN) && (
        <div
          className={classes.itemMenu}
          onClick={() => {
            navigator.clipboard.writeText(rowData.quotationTermsUrl);
          }}
        >
          Copy Link
        </div>
      )
    );
  };

  const renderQuotationTermsButton = (rowData) => {
    const title = rowData.quotationTermsUrl
      ? 'Custom Quotation Terms'
      : 'Standard Quotation Terms';
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          maxWidth: 150,
        }}
      >
        <MenuList
          title={title}
          buttonClassName={
            rowData.quotationTermsUrl ? classes.termButton : null
          }
        >
          <div className={classes.containerMenu}>
            {rowData.quotationTermsUrl ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(rowData.quotationTermsUrl);
                }}
              >
                Download Current Quotation Terms
              </Link>
            ) : (
              <a
                href='QuotationTerms.pdf'
                download
                className={classes.itemMenu}
              >
                Download Current Quotation Terms
              </a>
            )}
            {renderUploadQuotationTermsButton(rowData)}
            {rowData.quotationTermsUrl && renderCopyQuotationTermsLink(rowData)}
          </div>
        </MenuList>
      </div>
    );
  };

  const renderUploadPoTermsButton = (rowData) => {
    return (
      <a className={classes.itemMenu}>
        <input
          id={`upload-poTerms-${rowData.userID}`}
          hidden
          accept='.pdf'
          type='file'
          onChange={(evt) =>
            handleAdminUploadPoTerms(rowData, evt.target.files)
          }
          onClick={(event) => (event.target.value = null)}
        />
        <label htmlFor={`upload-poTerms-${rowData.userID}`}>
          Upload Custom PO Terms
        </label>
      </a>
    );
  };

  const renderCopyPoTermsLink = (rowData) => {
    return (
      (isSuperAdminRole(role) || role === ROLE_TYPES.ADMIN) && (
        <div
          className={classes.itemMenu}
          onClick={() => {
            navigator.clipboard.writeText(rowData.poTermsUrl);
          }}
        >
          Copy Link
        </div>
      )
    );
  };

  const renderPOTermsButton = (rowData) => {
    const title = rowData.poTermsUrl ? 'Custom PO Terms' : 'Standard PO Terms';
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MenuList
          title={title}
          buttonClassName={rowData.poTermsUrl ? classes.signed : null}
        >
          <div className={classes.containerMenu}>
            {rowData.poTermsUrl ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(rowData.poTermsUrl);
                }}
              >
                Download Current PO Terms
              </Link>
            ) : (
              <a href='POTerms.pdf' download className={classes.itemMenu}>
                Download Current PO Terms
              </a>
            )}
            {renderUploadPoTermsButton(rowData)}
            {rowData.poTermsUrl && renderCopyPoTermsLink(rowData)}
          </div>
        </MenuList>
      </div>
    );
  };

  const renderUploadPartnershipAgreementButton = (rowData) => {
    return (
      <a className={classes.itemMenu}>
        <input
          id={`upload-partnershipAgreement-${rowData.userID}`}
          hidden
          accept='.pdf'
          type='file'
          onChange={(evt) =>
            handleAdminUploadPartnershipAgreement(rowData, evt.target.files)
          }
          onClick={(event) => (event.target.value = null)}
        />
        {(rowData.partnershipAgreementUrl ||
          rowData.onboardingCounterSignedPartnershipAgreementUrl ||
          rowData.onboardingPartnershipAgreementUrl) && (
          <label htmlFor={`upload-partnershipAgreement-${rowData.userID}`}>
            Upload New Partnership Agreement
          </label>
        )}
        {!rowData.partnershipAgreementUrl &&
          !rowData.onboardingCounterSignedPartnershipAgreementUrl &&
          !rowData.onboardingPartnershipAgreementUrl && (
            <label htmlFor={`upload-partnershipAgreement-${rowData.userID}`}>
              Upload Signed Agreement
            </label>
          )}
      </a>
    );
  };

  const renderPartnershipAgreementButton = (rowData) => {
    const title =
      rowData.partnershipAgreementUrl ||
      rowData.onboardingCounterSignedPartnershipAgreementUrl ||
      rowData.onboardingPartnershipAgreementUrl ||
      (rowData.docID && rowData.docName && rowData.partnerFieldInviteID)
        ? 'Partnership Agreement'
        : 'N.A.';

    const [isSignedByPartner, setIsSignedByPartner] = useState(false);

    useEffect(() => {
      const checkPartnerSignStatus = async () => {
        if (rowData.docID && rowData.partnerFieldInviteID) {
          const result = await signNowAgreementIsSignedBy(
            rowData.docID,
            rowData.partnerFieldInviteID
          );
          setIsSignedByPartner(result);
        }
      };

      checkPartnerSignStatus();
    }, [rowData]);

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MenuList
          title={title}
          buttonClassName={
            rowData.partnershipAgreementUrl ? classes.signed : null
          }
        >
          <div className={classes.containerMenu}>
            {rowData.partnershipAgreementUrl ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(rowData.partnershipAgreementUrl);
                }}
              >
                Download Current Partnership Agreement
              </Link>
            ) : rowData.onboardingCounterSignedPartnershipAgreementUrl ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(
                    rowData.onboardingCounterSignedPartnershipAgreementUrl
                  );
                }}
              >
                Download Current Partnership Agreement (Onboarding,
                Countersigned)
              </Link>
            ) : rowData.onboardingPartnershipAgreementUrl ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(rowData.onboardingPartnershipAgreementUrl);
                }}
              >
                Download Current Partnership Agreement (Onboarding, Initial)
              </Link>
            ) : rowData.docID &&
              rowData.docName &&
              rowData.partnerFieldInviteID &&
              isSignedByPartner ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadSignNowPartnershipAgreement(
                    rowData.docID,
                    rowData.docName
                  );
                }}
              >
                Download Current Partnership Agreement (Onboarding,
                Countersigned)
              </Link>
            ) : rowData.docID &&
              rowData.docName &&
              rowData.partnerFieldInviteID ? (
              <Link
                className={classes.itemMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadSignNowPartnershipAgreement(
                    rowData.docID,
                    rowData.docName
                  );
                }}
              >
                Download Current Partnership Agreement (Onboarding, Initial)
              </Link>
            ) : null}

            {renderUploadPartnershipAgreementButton(rowData)}
          </div>
        </MenuList>
      </div>
    );
  };

  const renderChaserEmailIcon = (tracking, rowData) => {
    const { skip, sent, emailTemplate } = tracking;
    const type = emailTemplate.includes(
      AUTOMATIC_CHASER_EMAIL_TYPE.VERIFY_EMAIL
    )
      ? AUTOMATIC_CHASER_EMAIL_TYPE.VERIFY_EMAIL
      : AUTOMATIC_CHASER_EMAIL_TYPE.SIGNUP_FOLLOW_UP;
    const trigger = EMAIL_TEMPLATE_TRIGGER[emailTemplate];
    const triggerDate = addHoursAndRoundUpMinute(
      rowData[trigger?.keyDate],
      trigger?.afterInHours,
      'YYYY/MM/DD HH:mm'
    );
    const [date, time] = triggerDate.split(' ');
    const typeInfoText =
      type === AUTOMATIC_CHASER_EMAIL_TYPE.VERIFY_EMAIL
        ? 'Verify Emails'
        : 'Follow Up Emails';
    const clickButtonText = `Click icon to stop this email and any ${typeInfoText} after it.`;
    let textToolTip = `${emailTemplate} scheduled to be sent on ${date} at ${time} SGT. ${clickButtonText}`;
    let imgIcon = emailPending;
    if (!rowData[trigger?.keyDate]) {
      textToolTip = `${emailTemplate} will not scheduled, ${trigger?.keyDate} is empty. ${clickButtonText}`;
    }
    if (skip) {
      textToolTip = 'Automated chaser email discontinued.';
      imgIcon = emailStopped;
    }
    if (sent) {
      textToolTip = `${emailTemplate} sent successfully on ${date} at ${time} SGT.`;
      imgIcon = emailSent;
    }
    const isPendingIcon = imgIcon === emailPending;
    return (
      <Tooltip title={textToolTip}>
        <img
          style={{ cursor: isPendingIcon ? 'pointer' : 'default' }}
          onClick={() => {
            if (isPendingIcon) {
              setShowChaserEmailPopup({ open: true, type });
              setSelectedUser(rowData);
            }
          }}
          src={imgIcon}
          alt='Chaser Email Icon'
        />
      </Tooltip>
    );
  };

  const renderQAndA = (rowData) => {
    if (typeof rowData.qAndA === 'string') {
      return rowData.qAndA;
    }
    const qAndA = rowData.qAndA || {};
    let text = '';
    if (!isEmpty(qAndA.details)) {
      text = `${qAndA.answer}: ${qAndA.details}`;
    } else {
      text = !isEmpty(qAndA.answer) ? `${qAndA.answer}` : '';
    }
    return text;
  };

  const EditRolesLookup = useMemo(() => {
    switch (role) {
      case ROLE_TYPES.SUPER_ADMIN:
        return {
          buyer: ROLE_TYPES.BUYER,
          supplier: ROLE_TYPES.SUPPLIER,
          admin: ROLE_TYPES.ADMIN,
          sales_consultant: ROLE_TYPES.SALES_CONSULTANT,
          reviewer: ROLE_TYPES.REVIEWER,
          rocket_man: ROLE_TYPES.ROCKET_MAN,
        };
      case ROLE_TYPES.ADMIN:
        return {
          buyer: ROLE_TYPES.BUYER,
          supplier: ROLE_TYPES.SUPPLIER,
          sales_consultant: ROLE_TYPES.SALES_CONSULTANT,
          reviewer: ROLE_TYPES.REVIEWER,
          rocket_man: ROLE_TYPES.ROCKET_MAN,
        };
      default:
        return {};
    }
  }, [role]);

  const EditRoleValueOptions = useMemo(() => {
    switch (role) {
      case ROLE_TYPES.SUPER_ADMIN:
        return [
          { value: 'buyer', label: ROLE_TYPES.BUYER },
          { value: 'supplier', label: ROLE_TYPES.SUPPLIER },
          { value: 'admin', label: ROLE_TYPES.ADMIN },
          { value: 'sales_consultant', label: ROLE_TYPES.SALES_CONSULTANT },
          { value: 'reviewer', label: ROLE_TYPES.REVIEWER },
          { value: 'rocket_man', label: ROLE_TYPES.ROCKET_MAN },
        ];
      case ROLE_TYPES.ADMIN:
        return [
          { value: 'buyer', label: ROLE_TYPES.BUYER },
          { value: 'supplier', label: ROLE_TYPES.SUPPLIER },
          { value: 'sales_consultant', label: ROLE_TYPES.SALES_CONSULTANT },
          { value: 'reviewer', label: ROLE_TYPES.REVIEWER },
          { value: 'rocket_man', label: ROLE_TYPES.ROCKET_MAN },
        ];
      default:
        return [];
    }
  }, [role]);

  const associatedAccountsColumn = {
    field: 'associatedAccounts',
    headerName: 'Associated Accounts',
    renderCell: ({ row: rowData }) => {
      if (
        ![
          ROLE_TYPES.SUPER_ADMIN,
          ROLE_TYPES.ADMIN,
          ROLE_TYPES.BUYER,
          ROLE_TYPES.SALES_CONSULTANT,
        ].includes(rowData.role)
      ) {
        return <DataGridWrapTextCell text='N.A.' />;
      }

      const associatedAccounts = rowData.customers || rowData.sales || [];

      return (
        <Tooltip title='Click to edit'>
          <div
            style={{
              overflowY: 'auto',
              maxHeight: 120,
              cursor: 'pointer',
              minWidth: 160,
              whiteSpace: 'normal',
              lineHeight: 'normal',
              boxSizing: 'border-box',
            }}
            onClick={() => {
              updateSalesCustomersAssociationPopupHOCState({
                open: true,
                userID: rowData.userID,
              });
            }}
          >
            {isEmptyValue(associatedAccounts) && (
              <DataGridWrapTextCell text='N.A.' />
            )}
            {!isEmptyValue(associatedAccounts) &&
              associatedAccounts.map((account, index) => (
                <FtrB2 key={index} style={{ textAlign: 'left' }}>
                  • {account.name} ({account.userID}) ({account.email})
                </FtrB2>
              ))}
          </div>
        </Tooltip>
      );
    },
    sortable: false,
    width: 200,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    export: false,
    align: 'left',
    hide: ![ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(role),
  };

  const ppePriceAccountMarkupColumn = {
    headerName: 'Customer Account Markup',
    field: 'ppePriceMarkup',
    hidden: !isAdminOrHigherRole(role),
    editable: true,
    renderCell: ({ row: rowData }) => (
      <DataGridWrapTextCell text={`${rowData.ppePriceMarkup || 0}%`} />
    ),
  };

  const manualRfqAccountMarkupColumn = {
    headerName: 'Manual RFQ Markup',
    field: 'manualRfqMarkup',
    hidden: !isAdminOrHigherRole(role),
    editable: true,
    renderCell: ({ row: rowData }) => (
      <DataGridWrapTextCell
        text={`${rowData.manualRfqMarkup || DEFAULT_MANUAL_RFQ_MARKUP}%`}
      />
    ),
  };

  const defaultColumns = [
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (props) => (
        <RowMenuCell
          {...props}
          editUser={editUser}
          editSupplierInfo={editSupplierInfo}
          deleteUser={deleteUser}
          setIsEditing={setIsEditing}
        />
      ),
      sortable: false,
      width: 100,
      headerAlign: 'center',
      filterable: false,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      export: false,
    },
    {
      headerName: 'User ID',
      field: 'userID',
      editable: false,
      renderCell: ({ row: rowData }) => {
        let type = 'default';
        if (rowData.supplierDashboardID && rowData.allowViewDashboard === 1) {
          type = 'active';
        } else if (
          rowData.supplierDashboardID &&
          rowData.allowViewDashboard === 0
        ) {
          type = 'inactive';
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <span>{rowData.userID}</span>
            {rowData.role === ROLE_TYPES.SUPPLIER && (
              <MenuList parent={renderPartnerDashboard(type)}>
                <div className={classes.containerMenu}>
                  <span
                    className={classes.itemMenu}
                    onClick={() => {
                      history.push(`/data-dashboard/${rowData.userID}`);
                    }}
                  >
                    View Partner Data Dashboard
                  </span>
                  {type === 'active' && (
                    <span
                      className={classes.itemMenu}
                      onClick={() => {
                        handleAllowViewDashboard(rowData.userID, {
                          allowViewDashboard: 0,
                        });
                      }}
                    >
                      Click to Disable
                    </span>
                  )}
                  {type === 'inactive' && (
                    <span
                      className={classes.itemMenu}
                      onClick={() => {
                        handleAllowViewDashboard(rowData.userID, {
                          allowViewDashboard: 1,
                        });
                      }}
                    >
                      Click to Enable
                    </span>
                  )}
                </div>
              </MenuList>
            )}
          </div>
        );
      },
    },
    {
      headerName: 'Company ID',
      field: 'companyID',
      editable: isSuperAdminRole(role),
    },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.name} />
      ),
      editable: true,
      width: 120,
    },
    {
      headerName: 'Role',
      field: 'role',
      lookup: EditRolesLookup,
      editable: isAdminOrHigherRole(role),
      type: 'singleSelect',
      valueOptions: EditRoleValueOptions,
      width: 130,
    },
    {
      headerName: 'Email',
      field: 'email',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.email} />
      ),
      width: 250,
      editable: true,
    },
    {
      headerName: 'Finance Email',
      field: 'financeEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.financeEmail} />
      ),
      editable: true,
      width: 150,
    },
    {
      headerName: 'Finance Address',
      field: 'financeAddress',
      editable: false,
      renderCell: ({ row: rowData }) => {
        const financeAddress = (rowData.addresses || []).find(
          (address) => address.defaultBilling
        );
        const address = renderAddress(rowData, financeAddress);
        return <DataGridWrapTextCell text={address || 'N.A.'} />;
      },
      valueGetter: ({ row: rowData }) => {
        const financeAddress = rowData.addresses?.find(
          (address) => address.defaultBilling
        );
        return financeAddress?.address || 'N.A.';
      },
      width: 300,
    },
    {
      headerName: 'Country',
      field: 'country',
      editable: true,
      type: 'singleSelect',
      valueOptions: getCountryLookupDataGrid(),
      width: 120,
    },
    {
      headerName: 'Currency',
      field: 'currency',
      lookup: CURRENCY_CODE,
      editable: true,
      type: 'singleSelect',
      valueOptions: getCurrencyLookupDataGrid(),
    },
    {
      headerName: 'Tech Tags',
      field: 'techTags',
      editable: true,
      renderCell: ({ row: rowData }) => {
        if (
          isEmptyValue(rowData.techTags) &&
          isEmptyValue(rowData.machiningCapabilities)
        ) {
          return '';
        }

        let finalTechTags = [];

        if (!isEmptyValue(rowData.techTags)) {
          rowData.techTags.split(',').map((tech) => {
            const currTech = TECH_KEY_NAME_MAPPING[tech] || tech;
            if (!finalTechTags.includes(currTech)) {
              finalTechTags.push(currTech);
            }
          });
        }

        if (!isEmptyValue(rowData.machiningCapabilities)) {
          rowData.machiningCapabilities.split(',').map((tech) => {
            const currTech = ONBOARDING_TECHNOLOGY_OPTION_TYPE[tech] || tech;
            if (!finalTechTags.includes(currTech)) {
              if (!isEmptyValue(rowData.techTags)) {
                rowData.techTags = rowData.techTags + ',' + currTech; // Allows for the currTech to appear as a selected option?
              }
              finalTechTags.push(currTech);
            }
          });
        }

        finalTechTags = finalTechTags.join(',\n');

        return <DataGridWrapTextCell text={finalTechTags} />;
      },
      renderEditCell: SelectedTagEditCell,
      width: 250,
    },
    {
      headerName: 'Qualifications',
      field: 'qualifications',
      renderCell: ({ row: rowData }) => {
        if (isEmptyValue(rowData.qualifications)) {
          return '';
        }
        const qualifications = rowData.qualifications.split(',').join(',\n');
        return <DataGridWrapTextCell text={qualifications} />;
      },
      editable: true,
      width: 250,
    },
    {
      headerName: 'Supplier GST %',
      field: 'supplierGstPercent',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={rowData.supplierGstPercent ? rowData.supplierGstPercent : '0'}
        />
      ),
      editable: true,
      type: 'number',
    },
    {
      headerName: 'Pay-per-sale Program %',
      field: 'supplierDiscountPercent',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={
            rowData.supplierDiscountPercent
              ? rowData.supplierDiscountPercent
              : '0'
          }
        />
      ),
      editable: true,
      type: 'number',
    },
    {
      headerName: "Company's name",
      field: 'companyName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.companyName} />
      ),
      editable: true,
    },
    {
      headerName: 'Company Description',
      field: 'companyDesc',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.companyDesc} />
      ),
      editable: true,
      width: 120,
    },
    {
      headerName: 'Secondary POC Name',
      field: 'secondaryName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.secondaryName} />
      ),
      editable: true,
    },
    {
      headerName: 'Secondary POC Email',
      field: 'secondaryEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.secondaryEmail} />
      ),
      editable: true,
      width: 250,
    },
    {
      headerName: 'Secondary POC Contact',
      field: 'secondaryContact',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={getFormattedContactNumber(
            rowData.secondaryContact,
            rowData.country
          )}
        />
      ),
      exportData: ({ row: rowData }) => {
        return getContactNumberForCSv(
          rowData.secondaryContact,
          rowData.country
        );
      },
      editable: true,
      width: 150,
    },
    {
      headerName: 'Address',
      field: 'address',
      editable: true,
      renderCell: ({ row: rowData }) => {
        const shippingAddress = rowData.addresses?.find(
          (address) => address.defaultShipping
        );
        if (isEmptyValue(shippingAddress)) {
          return (
            <DataGridWrapTextCell
              text={
                rowData.address ? (
                  <DisplayAddress
                    data={{
                      address: rowData.address,
                      contactName: rowData.name,
                      contactNumber: convertOldFormattedContact(
                        rowData.contact,
                        rowData.county
                      ),
                    }}
                  />
                ) : (
                  'N.A.'
                )
              }
            />
          );
        }
        const address = renderAddress(rowData, shippingAddress);
        return <DataGridWrapTextCell text={address || 'N.A.'} />;
      },
      valueGetter: ({ row: rowData }) => {
        const shippingAddress = getDefaultShippingDeliveryInfo(
          rowData,
          rowData.addresses || []
        );
        return shippingAddress?.address || 'N.A.';
      },
      width: 300,
    },
    {
      headerName: 'Contact',
      field: 'contact',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={getFormattedContactNumber(rowData.contact, rowData.country)}
        />
      ),
      exportData: ({ row: rowData }) => {
        return getContactNumberForCSv(rowData.contact, rowData.country);
      },
      editable: true,
      width: 150,
    },
    {
      headerName: 'Registration date',
      field: 'registrationDate',
      renderCell: ({ row: rowData }) =>
        rowData.registrationDate
          ? rowData.registrationDate.substring(0, 10)
          : '',
      width: 120,
    },
    {
      headerName: 'Verified Email',
      field: 'emailVerifiedAt',
      valueGetter: ({ row: rowData }) => {
        if (rowData.emailVerifiedAt) {
          return 'Verified';
        } else if (rowData.verificationDate) {
          // Handle old users who do not have value for emailVerifiedAt
          return 'N.A.';
        } else {
          return 'Pending';
        }
      },
      renderCell: ({ row: rowData }) => {
        if (rowData.emailVerifiedAt) {
          return (
            <Tooltip title='Verified'>
              <FlexColumn
                style={{
                  lineHeight: 'normal',
                  gap: 0,
                }}
              >
                <VerifiedUser style={{ color: 'green' }} />
                {rowData.verificationMethod && (
                  <span style={{ color: colors.solidBlue, fontSize: '0.9rem' }}>
                    via {rowData.verificationMethod}
                  </span>
                )}
              </FlexColumn>
            </Tooltip>
          );
        } else if (rowData.verificationDate) {
          // Handle old users who do not have value for emailVerifiedAt
          return 'N.A.';
        } else {
          return (
            <span style={{ color: 'orange', marginRight: '4px' }}>Pending</span>
          );
        }
      },
      width: 150,
    },
    {
      headerName: 'Verification Method',
      field: 'verificationMethod',
      valueGetter: ({ row: rowData }) => rowData.verificationMethod || 'N.A.',
      hide: true,
    },
    {
      headerName: 'Verification Status',
      field: 'verificationStatus',
      valueGetter: ({ row: rowData }) =>
        rowData.verificationDate ? 'Verified' : 'Pending',
      renderCell: ({ row: rowData }) =>
        rowData.verificationDate ? (
          <Tooltip title='Verified'>
            <VerifiedUser style={{ color: 'green' }} />
          </Tooltip>
        ) : (
          <div
            style={{
              lineHeight: 'normal',
            }}
          >
            <VerifiablePendingStatus
              onButtonClick={() => handleVerifyUser(rowData.userID)}
            />
            <div style={{ display: 'flex', columnGap: '5px' }}>
              {rowData.newSignupEmailTracking
                ?.filter((el) =>
                  el.emailTemplate?.includes(
                    AUTOMATIC_CHASER_EMAIL_TYPE.VERIFY_EMAIL
                  )
                )
                // sort by email step
                .sort(
                  (a, b) =>
                    getStepFromSnakeCase(a.emailTemplate) -
                    getStepFromSnakeCase(b.emailTemplate)
                )
                .map((tracking) => (
                  <React.Fragment key={tracking.id}>
                    {renderChaserEmailIcon(tracking, rowData)}
                  </React.Fragment>
                ))}
            </div>
          </div>
        ),
      export: true,
      width: 150,
    },
    {
      headerName: 'Verification Date',
      field: 'verificationDate',
      renderCell: ({ row: rowData }) =>
        rowData.verificationDate
          ? formatDateWithTime(rowData.verificationDate)
          : 'N.A.',
      width: 150,
    },
    {
      headerName: 'Verification Link',
      field: 'verificationLink',
      valueGetter: ({ row: rowData }) => {
        if (
          rowData.verificationDate ||
          isEmptyValue(rowData.verificationCode)
        ) {
          return 'N.A.';
        }
        const domain = window.location.origin;
        const url = `${domain}/email-confirmation/${rowData.verificationCode}`;
        return url;
      },
      renderCell: ({ row: rowData }) => {
        if (
          rowData.verificationDate ||
          isEmptyValue(rowData.verificationCode)
        ) {
          return 'N.A.';
        }
        const domain = window.location.origin;
        const url = `${domain}/email-confirmation/${rowData.verificationCode}`;
        return <DataGridWrapTextCell text={url} />;
      },
      width: 300,
    },
    {
      headerName: 'Customer Status',
      field: 'customerStatus',
      editable: true,
      type: 'singleSelect',
      valueOptions: getCustomerStatusLookupDataGrid(),
      width: 180,
      renderCell: ({ row: rowData }) => {
        const customerStatus = rowData.customerStatus || 'N.A.';
        const lastUploadedDate = rowData.lastUploadedDate
          ? formatDateWithTime(rowData.lastUploadedDate)
          : 'N.A.';
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 'normal',
            }}
          >
            <DataGridWrapTextCell text={customerStatus} />
            {rowData.customerStatus ===
              CUSTOMER_STATUS_TYPES.FORMAL_ENQUIRY && (
              <Tooltip title='Last Uploaded Date'>
                <span style={{ color: colors.solidBlue, fontSize: '0.9rem' }}>
                  <DataGridWrapTextCell text={lastUploadedDate} />
                </span>
              </Tooltip>
            )}
            {[
              CUSTOMER_STATUS_TYPES.QUALIFIED,
              CUSTOMER_STATUS_TYPES.QUALIFIED_WITH_SAMPLE,
            ].includes(rowData.customerStatus) && (
              <div style={{ display: 'flex', columnGap: '5px' }}>
                {rowData.newSignupEmailTracking
                  ?.filter((el) =>
                    el.emailTemplate?.includes(
                      AUTOMATIC_CHASER_EMAIL_TYPE.SIGNUP_FOLLOW_UP
                    )
                  )
                  // sort by email step
                  .sort(
                    (a, b) =>
                      getStepFromSnakeCase(a.emailTemplate) -
                      getStepFromSnakeCase(b.emailTemplate)
                  )
                  .map((tracking) => (
                    <React.Fragment key={tracking.id}>
                      {renderChaserEmailIcon(tracking, rowData)}
                    </React.Fragment>
                  ))}
              </div>
            )}
          </div>
        );
      },
    },
    {
      headerName: 'Last Accepted Order Date',
      field: 'lastAcceptedOrderDate',
      renderCell: ({ row: rowData }) =>
        rowData.lastAcceptedOrderDate
          ? formatDateWithTime(rowData.lastAcceptedOrderDate)
          : 'N.A.',
      valueGetter: ({ row: rowData }) =>
        rowData.lastAcceptedOrderDate
          ? formatDateWithTime(rowData.lastAcceptedOrderDate)
          : 'N.A.',
      width: 150,
    },
    {
      headerName: 'Industry Sector',
      field: 'industrySector',
      editable: true,
      type: 'singleSelect',
      valueOptions: getIndustryLookupDataGrid(),
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.industrySector} />
      ),
      width: 200,
    },
    {
      headerName: 'Customer Type',
      field: 'customerType',
      editable: true,
      type: 'singleSelect',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.customerType} />
      ),
      valueOptions: getCustomerTypeLookupDataGrid(),
    },
    { ...keyAccountCol },
    {
      headerName: 'NDA',
      field: 'ndaVerified',
      valueGetter: ({ row: rowData }) => {
        if (rowData.ndaVerified === null) {
          return 'N.A.';
        }
        if (Boolean(rowData.ndaVerified) === true) {
          return 'Yes';
        } else if (Boolean(rowData.ndaVerified) === false) {
          return 'Pending';
        } else {
          return 'N.A.';
        }
      },
      renderCell: ({ row: rowData }) => {
        if (rowData.ndaVerified === null) {
          return renderNAOrPendingButton(rowData, false);
        }
        if (Boolean(rowData.ndaVerified) === true) {
          return renderSignedButton(rowData);
        } else if (Boolean(rowData.ndaVerified) === false) {
          return renderNAOrPendingButton(rowData, true);
        } else {
          return renderNAOrPendingButton(rowData, false);
        }
      },
      width: 115,
    },
    {
      headerName: 'Customer Quotation Terms',
      field: 'quotationTermsUrl',
      renderCell: ({ row: rowData }) =>
        rowData.role === ROLE_TYPES.BUYER
          ? renderQuotationTermsButton(rowData)
          : null,
      width: 150,
    },
    {
      headerName: 'Partnership Agreement',
      field: 'partnershipAgreementUrl',
      renderCell: ({ row: rowData }) =>
        rowData.role === ROLE_TYPES.SUPPLIER
          ? renderPartnershipAgreementButton(rowData)
          : null,
      width: 150,
    },
    {
      headerName: 'Supplier PO Terms',
      field: 'poTermsUrl',
      renderCell: ({ row: rowData }) =>
        rowData.role === ROLE_TYPES.SUPPLIER
          ? renderPOTermsButton(rowData)
          : null,
      width: 150,
    },
    { ...creditTypeCol },
    {
      headerName: 'Credits',
      field: 'credits',
      editable: true,
      renderCell: ({ row: rowData }) =>
        `${rowData.currency} ${rowData.credits}`,
      renderEditCell: ({ row: rowData, value, id, api, field }) => {
        const handleChange = (value) => {
          api.setEditCellValue({ id, field, value });
        };

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{rowData.currency}</div>
            <TextField
              type='number'
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              style={{ marginLeft: '10px' }}
            />
          </div>
        );
      },
    },
    { ...paymentTermCol },
    {
      headerName: 'Last Uploaded Date',
      field: 'lastUploadedDate',
      valueGetter: ({ row: rowData }) =>
        rowData.lastUploadedDate
          ? formatDateWithTime(rowData.lastUploadedDate)
          : 'N.A.',
      hide: true,
      export: true,
    },
    ppePriceAccountMarkupColumn,
    manualRfqAccountMarkupColumn,
    {
      headerName: 'Fee Type',
      field: 'feeType',
      lookup: { no_fee: 'no-fee', has_fee: 'has-fee' },
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'no_fee', label: 'no_fee' },
        { value: 'has_fee', label: 'has_fee' },
      ],
    },
    {
      headerName: 'Combined Service Fee',
      field: 'combinedServiceFee',
      lookup: { 1: 'Yes', 0: 'No' },
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
      ],
      renderCell: ({ row: rowData }) => {
        return rowData.combinedServiceFee ? 'Yes' : 'No';
      },
    },
    {
      headerName: 'PPE Type',
      field: 'ppeType',
      lookup: Object.values(PPE_TYPES).map((value) => ({ value: value })),
      editable: true,
      type: 'singleSelect',
      valueOptions: Object.values(PPE_TYPES).map((value) => ({
        value,
        label: value,
      })),
      width: 150,
    },
    {
      headerName: 'TDE Type',
      field: 'tdeType',
      lookup: {
        [TDE_TYPE.NO_TDE]: TDE_TYPE.NO_TDE,
        [TDE_TYPE.HAS_TDE]: TDE_TYPE.HAS_TDE,
      },
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: TDE_TYPE.NO_TDE, label: TDE_TYPE.NO_TDE },
        { value: TDE_TYPE.HAS_TDE, label: TDE_TYPE.HAS_TDE },
      ],
    },
    {
      headerName: 'DFM Type',
      field: 'dfmType',
      lookup: Object.values(DFM_TYPES).map((value) => ({ value: value })),
      editable: true,
      type: 'singleSelect',
      valueOptions: Object.values(DFM_TYPES).map((value) => ({
        value,
        label: value,
      })),
      width: 150,
      renderCell: ({ row: rowData }) => {
        return <DataGridWrapTextCell text={rowData.dfmType} />;
      },
    },
    {
      headerName: 'Default Anodizing Type',
      field: 'defaultAnodizingType',
      width: 120,
      editable: true,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={capitalizeString(rowData.defaultAnodizingType)}
          />
        );
      },
      renderEditCell: DefaultAnodizingTypeDropdown,
    },
    {
      headerName: 'See Promo Code',
      field: 'seePromoCode',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
      ],
      renderCell: ({ row: rowData }) => {
        return rowData.seePromoCode ? 'Yes' : 'No';
      },
    },
    {
      headerName: 'Units',
      field: 'unitType',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: UNIT_TYPES.METRIC, label: UNIT_TYPES.METRIC },
        { value: UNIT_TYPES.IMPERIAL, label: UNIT_TYPES.IMPERIAL },
      ],
    },
    {
      headerName: 'Profile Picture',
      field: 'profilePic',
      renderCell: ({ row: rowData }) => {
        return isSuperAdminRole(role) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input
              id={`profile-img-${rowData.userID}`}
              type='file'
              accept='.png, .jpg, .jpeg'
              onChange={(evt) => handleChangeImage(evt, rowData)}
              style={{
                display: 'none',
              }}
            />
            <label
              htmlFor={`profile-img-${rowData.userID}`}
              style={{ cursor: 'pointer' }}
            >
              <Tooltip title='Click here to change image'>
                <Avatar
                  src={rowData.profilePic}
                  style={{
                    height: '3rem',
                    width: '3rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                />
              </Tooltip>
            </label>
          </div>
        ) : (
          <Avatar
            src={rowData.profilePic}
            style={{
              height: '3rem',
              width: '3rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          />
        );
      },
    },
    {
      headerName: 'Subjected to GDPR',
      field: 'isGDPRSubjected',
      renderCell: ({ row: rowData }) =>
        rowData.isGDPRSubjected === 1
          ? 'Yes'
          : rowData.isGDPRSubjected === 0
            ? 'No'
            : '',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
      ],
    },
    {
      headerName: 'Contactable via WhatsApp',
      field: 'isContactableWhatsApp',
      renderCell: ({ row: rowData }) =>
        rowData.isContactableWhatsApp === 1
          ? 'Yes'
          : rowData.isContactableWhatsApp === 0
            ? 'No'
            : '',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
      ],
    },
    {
      ...associatedAccountsColumn,
    },
    {
      headerName: 'How did you hear about us?',
      field: 'qAndA',
      editable: true,
      exportData: ({ row: rowData }) => renderQAndA(rowData),
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={renderQAndA(rowData)} />
      ),
      renderEditCell: QAndAEditCell,
      width: 300,
    },
    {
      headerName: 'Last Login Date',
      field: 'lastLoginDate',
      valueGetter: ({ row: rowData }) =>
        rowData.maxLoginDateTime
          ? formatDateWithTime(rowData.maxLoginDateTime)
          : 'N.A',
      width: 150,
    },
  ];

  const [columns, _] = useState(defaultColumns);

  const handleDownloadCsv = () => {
    const fileName = `All Users New ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const getCustomerToolbar = useCallback(() => {
    const searchBar = (
      <SearchBar
        key='search'
        onSearch={handleSearch}
        searchTerm={tableQueryParams.search}
        autoFocus={!isEditing}
      />
    );

    const exportButton = (
      <ExportCsvButton
        key='export-csv'
        handleClick={handleDownloadCsv}
        show={isSuperAdminRole(role)}
      />
    );

    const buttons = [searchBar, exportButton];

    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar buttons={buttons} />
      </DataGridToolbarLayout>
    );
  }, [columns, tableQueryParams.search, isEditing, filteredData]);

  return (
    <div className={classes.body}>
      <DataGrid
        className={classes.dataGrid}
        autoHeight
        apiRef={apiRef}
        editMode='row'
        rows={filteredData ?? []}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.userID}
        rowHeight={120}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading || usersLoading}
        onCellClick={(params, e) => {
          e.stopPropagation();
        }}
        onCellDoubleClick={(params, e) => {
          e.stopPropagation();
        }}
      />
      {showEditorAddresses && (
        <EditorAddressesPopup
          open={showEditorAddresses}
          onClose={() => setShowEditorAddresses(false)}
          userID={selectedUser.userID}
          onSuccess={onPageLoad}
        />
      )}
      {showChaserEmailPopup.open && (
        <YesNoPopup
          title='Are you sure you want to discontinue automated chaser emails for this user?'
          body={`All remaining automated emails for ${
            showChaserEmailPopup.type ===
            AUTOMATIC_CHASER_EMAIL_TYPE.VERIFY_EMAIL
              ? 'Sign Up VERIFY'
              : 'Sign Up FOLLOW UP'
          } will get not be sent.`}
          handleNo={() =>
            setShowChaserEmailPopup((prev) => ({ ...prev, open: false }))
          }
          handleYes={() => {
            handleRemoveAllChaserEmails();
            setShowChaserEmailPopup((prev) => ({ ...prev, open: false }));
          }}
          noButtonLabel='CANCEL'
          yesButtonLabel='CONFIRM DISCONTINUE'
          open={showChaserEmailPopup.open}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    usersLoading: state.users.getUsersLoading,
    role: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => {
      dispatch(resetUsersAction());
      dispatch(getAllUsers());
    },
    editUser: (newData) => dispatch(editUserData(newData)),
    editSupplierInfo: (newData) => dispatch(editSupplierInfo(newData)),
    deleteUser: (userToDelete) => dispatch(deleteUser(userToDelete)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(
  withSalesCustomersAssociationPopupHOC(AllUsersDataGrid)
);

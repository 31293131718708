import React from 'react';

import {
  UpdateOutlined,
  RateReviewOutlined,
  LocalShippingOutlined,
  CheckOutlined,
  PlaylistAddCheck,
  PlayCircleFilledWhiteOutlined,
  AssignmentTurnedIn,
} from '@material-ui/icons';

import { colors } from '../palette';
import { REACT_APP_ENV } from '../constants';
import {
  QC_COATING_FINISHING_CERTIFICATE,
  QC_FORMAL_CMM_REPORT,
  QC_MATERIAL_CERTIFICATE,
  QC_NO_QC_REPORT,
  QC_OTHERS,
  QC_STANDARD_QC_REPORT,
} from './projectConstants';

// ----------------------------------------------------------------------------

export const ORDER_READY_STATUS = {
  NOT_STARTED: 'not_started',
  SUBMITTED: 'submitted',
  VERIFIED: 'verified',
  NEED_UPDATE: 'need_update',
  READY_TO_SHIP: 'ready_to_ship',
  IN_TRANSIT: 'in_transit',
  DELIVERED: 'delivered',
};

export const DELIVERY_STARTED_OR_COMPLETE_STATUSES = [
  ORDER_READY_STATUS.IN_TRANSIT,
  ORDER_READY_STATUS.DELIVERED,
];

export const DELIVERY_STATUSES = [
  ORDER_READY_STATUS.READY_TO_SHIP,
  ...DELIVERY_STARTED_OR_COMPLETE_STATUSES,
];

export const ORDER_READY_STATUS_MAPPING = {
  [ORDER_READY_STATUS.NOT_STARTED]: 'Not started',
  [ORDER_READY_STATUS.SUBMITTED]: 'Pending review',
  [ORDER_READY_STATUS.NEED_UPDATE]: 'Update required',
  [ORDER_READY_STATUS.VERIFIED]: 'Verified',
  [ORDER_READY_STATUS.READY_TO_SHIP]: 'Ready for Delivery',
  [ORDER_READY_STATUS.IN_TRANSIT]: 'In-transit',
  [ORDER_READY_STATUS.DELIVERED]: 'Delivered',
};

export const ORDER_READY_STATUS_MAPPING_NUMBER = {
  [ORDER_READY_STATUS.NOT_STARTED]: 1,
  [ORDER_READY_STATUS.SUBMITTED]: 2,
  [ORDER_READY_STATUS.NEED_UPDATE]: 3,
  [ORDER_READY_STATUS.VERIFIED]: 4,
  [ORDER_READY_STATUS.READY_TO_SHIP]: 5,
  [ORDER_READY_STATUS.IN_TRANSIT]: 6,
  [ORDER_READY_STATUS.DELIVERED]: 7,
};

export const ORDER_READY_STATUS_FILTER_ARRAY = Object.entries(
  ORDER_READY_STATUS_MAPPING
).map(([key, value]) => ({ name: value, value: key }));

export const ORDER_READY_STATUS_TOOLTIP = {
  [ORDER_READY_STATUS.NOT_STARTED]:
    'Upload photos/QC reports once order is ready',
  [ORDER_READY_STATUS.SUBMITTED]:
    'Your submission is being reviewed for shipment',
  [ORDER_READY_STATUS.VERIFIED]:
    'Verified for shipment, awaiting Factorem Delivery Order to proceed',
  [ORDER_READY_STATUS.NEED_UPDATE]:
    'Review comments to provide updates required',
  [ORDER_READY_STATUS.READY_TO_SHIP]:
    'Download Factorem Delivery Order and proceed to shipment',
  [ORDER_READY_STATUS.IN_TRANSIT]: 'Your order has been shipped',
  [ORDER_READY_STATUS.DELIVERED]: 'Your order has been delivered',
};

export const ORDER_READY_RESOURCE_TYPES = {
  ISOMETRIC_IMAGE: 'ISOMETRIC_IMAGE',
  FRONT_IMAGE: 'FRONT_IMAGE',
  BACK_IMAGE: 'BACK_IMAGE',
  LEFT_IMAGE: 'LEFT_IMAGE',
  RIGHT_IMAGE: 'RIGHT_IMAGE',
  QC_REPORT: 'QC_REPORT',
  DELIVERY_ORDER: 'DELIVERY_ORDER',
  WAYBILL: 'WAYBILL',
  DESIGN_FILE: 'DESIGN_FILE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
  IMAGE: 'IMAGE',
  ADDITIONAL_RESOURCE: 'ADDITIONAL_RESOURCE',
  ADMIN_UPLOAD_FILE: 'ADMIN_UPLOAD_FILE',
  QC_REPORT_DOCUMENT: 'QC_REPORT_DOCUMENT',
  [QC_STANDARD_QC_REPORT]: 'QC_REPORT_STANDARD',
  [QC_FORMAL_CMM_REPORT]: 'QC_REPORT_FORMAL_CMM',
  [QC_MATERIAL_CERTIFICATE]: 'QC_REPORT_MATERIAL',
  [QC_COATING_FINISHING_CERTIFICATE]: 'QC_REPORT_COATING',
  [QC_OTHERS]: 'QC_REPORT_OTHERS',
  [QC_NO_QC_REPORT]: 'NO_QC_REPORT',
};

export const ORDER_READY_IMAGE_RESOURCE_TYPES = [
  ORDER_READY_RESOURCE_TYPES.FRONT_IMAGE,
  ORDER_READY_RESOURCE_TYPES.LEFT_IMAGE,
  ORDER_READY_RESOURCE_TYPES.RIGHT_IMAGE,
];

export const ORDER_READY_REVIEW_COMMENTS = {
  UPDATE_QC_REPORT: 'Need update QC Report',
  UPDATE_PART_IMAGES: 'Need update part images',
  REWORK: 'Need rework',
};

export const SHIPMENT_PROVIDERS = [
  'DHL Express',
  'Lindee',
  'FedEx',
  'UPS',
  'Singapore Post (SingPost)',
  'TNT',
  'Aramex',
  'Ninja Van - Domestic Shipping',
  'Ninja Van - International Shipping',
  'J&T Express',
  'SF Express',
  'Qxpress',
  'Other',
];

// Lindee not included
export const SHIPMENT_TRACKING_LINKS = {
  'DHL Express': (shipmentNumber) =>
    `https://www.dhl.com/en/express/tracking.html?AWB=${shipmentNumber}`,
  FedEx: (shipmentNumber) =>
    `https://www.fedex.com/apps/fedextrack/?tracknumbers=${shipmentNumber}`,
  UPS: (shipmentNumber) =>
    `https://www.ups.com/track?tracknum=${shipmentNumber}`,
  'Singapore Post (SingPost)': (shipmentNumber) =>
    `https://www.singpost.com/track-items?trackingid=${shipmentNumber}`,
  TNT: (shipmentNumber) =>
    `https://www.tnt.com/express/en_sg/site/shipping-tools/tracking.html?searchType=con&cons=${shipmentNumber}`,
  Aramex: (shipmentNumber) =>
    `https://www.aramex.com/en/track/track-results-new?ShipmentNumber=${shipmentNumber}`,
  'J&T Express': (shipmentNumber) =>
    `https://www.jtexpress.my/tracking/${shipmentNumber}`,
  'Ninja Van - Domestic Shipping': (shipmentNumber) =>
    `https://www.ninjavan.co/en-sg/tracking?id=${shipmentNumber}`,
  'Ninja Van - International Shipping': (shipmentNumber) =>
    `https://www.ninjavan.co/en-sg/international/tracking?id=${shipmentNumber}`,
  'SF Express': (shipmentNumber) =>
    `https://www.sf-international.com/sg/en/support/querySupport/waybill?No=${shipmentNumber}`,
  Qxpress: (shipmentNumber) =>
    `https://www.qxpress.net/Customer/PopupTraceParcels?TrackingNo=${shipmentNumber}`,
};

export const ORDER_READY_STATUS_CHIPS = [
  {
    status: ORDER_READY_STATUS.NOT_STARTED,
    config: {
      icon: <PlayCircleFilledWhiteOutlined />,
      textColor: 'black',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.NOT_STARTED],
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.NOT_STARTED],
    },
  },
  {
    status: ORDER_READY_STATUS.NEED_UPDATE,
    config: {
      icon: <UpdateOutlined style={{ color: 'white' }} />,
      textColor: 'white',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.NEED_UPDATE],
      bgColor: colors.bgOrderNeedUpdate,
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.NEED_UPDATE],
    },
  },
  {
    status: ORDER_READY_STATUS.SUBMITTED,
    config: {
      icon: <RateReviewOutlined />,
      textColor: 'black',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.SUBMITTED],
      bgColor: colors.bgSubmittedStatus,
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.SUBMITTED],
    },
  },
  {
    status: ORDER_READY_STATUS.VERIFIED,
    config: {
      icon: <PlaylistAddCheck style={{ color: 'white' }} />,
      textColor: 'white',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.VERIFIED],
      bgColor: colors.blue060,
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.VERIFIED],
    },
  },
  {
    status: ORDER_READY_STATUS.READY_TO_SHIP,
    config: {
      icon: <AssignmentTurnedIn style={{ color: 'white' }} />,
      textColor: 'white',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.READY_TO_SHIP],
      bgColor: colors.bgORderReadyToShip,
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.READY_TO_SHIP],
    },
  },
  {
    status: ORDER_READY_STATUS.IN_TRANSIT,
    config: {
      icon: <LocalShippingOutlined style={{ color: 'white' }} />,
      textColor: 'white',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.IN_TRANSIT],
      bgColor: colors.bgOrderInTransit,
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.IN_TRANSIT],
    },
  },
  {
    status: ORDER_READY_STATUS.DELIVERED,
    config: {
      icon: <CheckOutlined style={{ color: 'white' }} />,
      textColor: 'white',
      toolTip: ORDER_READY_STATUS_TOOLTIP[ORDER_READY_STATUS.DELIVERED],
      bgColor: colors.bgOrderDelivered,
      statusStr: ORDER_READY_STATUS_MAPPING[ORDER_READY_STATUS.DELIVERED],
    },
  },
];

export const DENIED_ADDITIONAL_RESOURCE_TYPES = ['zip', 'sh', 'bash'];

export const SHIPPING_GUIDELINES =
  REACT_APP_ENV === 'prod'
    ? 'https://factorem-s3-bucket.s3.ap-southeast-1.amazonaws.com/Public/Factorem%20Packing%20Guidelines_1738056850912.pdf'
    : 'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/Public/Factorem_Packing_guidelines_for_Partners_1736998204858.pdf';

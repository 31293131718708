import { ceil } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';
import {
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import DataGridJsonDisplayCell from '../../components/tables/cells/DataGridJsonDisplayCell';
import { FlexColumn, FlexRow } from '../../components/layouts/FlexLayouts';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';

import useDataGridPaginationHook from '../../hooks/useDataGridPaginationHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { exportCsvApi } from '../../utils/csvExportUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import { toFixedWithDefault } from '../../utils/numberUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { DATE_FORMAT, dateTzSingapore } from '../../utils/dateTimeUtils';
import { convertPriceToCurrencyBeautified } from '../../utils/currencyUtils';

import { colors } from '../../palette';
import {
  adminExportAllShipmentOrderLogs,
  adminGetAllShipmentOrderLogs,
  cancelOrderJnt,
  getTrackingStatusJnt,
} from '../../apis/shipmentApi';

import withJsonInfoDisplayPopupHOC from '../../hocs/withJsonInfoDisplayPopupHOC';
import withFtrDeletePopupHOC from '../../hocs/withFtrDeletePopupHOC';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { compareStringsIgnoreCase } from '../../utils/stringUtils';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

const ROW_HEIGHT = 90;

function ShipmentOrderLogs(props) {
  const { updateJsonInfoDisplayPopupHOCState, updateDeletePopupHOCState } =
    props;
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [
    { isFetching, tableQueryParams, tableData },
    { handleSearch, updateTableQueryParams, refetch: refetchTable },
  ] = useDataGridPaginationHook(adminGetAllShipmentOrderLogs);

  const [searchAutoFocus, setSearchAutoFocus] = useState(false);

  function handleCancelOrder(rowData) {
    const { referenceNumber } = rowData || {};
    if (isEmptyValue(referenceNumber)) {
      return;
    }
    cancelOrderJnt({
      referenceNumbers: [referenceNumber],
    })
      .then(() => {
        notifySuccess(`Successfully cancel order ${referenceNumber}`);
        refetchTable();
      })
      .catch((error) => {
        notifyError(
          error.message || 'Unable to cancel order. Please try again later.!'
        );
      })
      .finally(() => {
        updateDeletePopupHOCState({
          open: false,
        });
      });
  }

  function handleCheckStatus(rowData) {
    const { referenceNumber } = rowData || {};
    if (isEmptyValue(referenceNumber)) {
      return;
    }
    getTrackingStatusJnt(referenceNumber).then(refetchTable);
  }

  const columns = [
    {
      headerName: 'Date and Time Order Generated',
      field: 'createdAt',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={dateTzSingapore(rowData.createdAt)} />
      ),
      width: 150,
    },
    {
      headerName: 'Created By',
      field: 'createdBy',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.createdByName} (${rowData.createdBy})`}
        />
      ),
      width: 140,
    },
    {
      headerName: 'Item IDs',
      field: 'itemIDsStr',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.itemIDsStr} />
      ),
      width: 120,
    },
    {
      headerName: 'Shipment Fee',
      field: 'shipmentFee',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={convertPriceToCurrencyBeautified({
            currency: CURRENCY_CODE.SGD,
            price: rowData.shipmentFee,
          })}
        />
      ),
    },
    {
      headerName: 'Waybill URL',
      field: 'waybillURL',
      renderCell: ({ row: rowData }) => (
        <a href={rowData.waybillURL} target='_blank' rel='noreferrer'>
          Link
        </a>
      ),
      width: 80,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => (
        <FlexRow>
          <DataGridWrapTextCell text={rowData.status} />
          <Tooltip title='Get Latest Status' arrow>
            <IconButton onClick={() => handleCheckStatus(rowData)} edge='start'>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </FlexRow>
      ),
      width: 170,
    },
    {
      headerName: 'Package Specifications',
      field: 'packageSpecifications',
      renderCell: ({ row: rowData }) => {
        const packageSpecifications = rowData.packageSpecifications?.[0];
        if (isEmptyValue(packageSpecifications)) {
          return 'N.A.';
        }

        const { dimension_unit, weight_unit, height, length, width, weight } =
          packageSpecifications;

        let dimensions = [];
        [height, length, width].forEach((val) => {
          const valueUnit = toFixedWithDefault(val, {
            endWith: dimension_unit?.toLowerCase(),
          });
          dimensions.push(valueUnit);
        });

        const dimensionsStr = dimensions.join(' x ');

        const weightWithUnit = toFixedWithDefault(weight, {
          endWith: weight_unit?.toLowerCase(),
        });

        return (
          <DataGridWrapTextCell text={`${dimensionsStr}, ${weightWithUnit}`} />
        );
      },
      width: 180,
    },
    {
      headerName: 'Pickup Date',
      field: 'pickupDate',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={dateTzSingapore(rowData?.pickupDetails?.date, DATE_FORMAT)}
        />
      ),
      width: 110,
    },
    {
      headerName: 'Pickup Details',
      field: 'pickupDetails',
      renderCell: ({ row: rowData }) => (
        <DataGridJsonDisplayCell
          jsonObj={rowData.pickupDetails}
          seeMoreButtonClick={() => {
            updateJsonInfoDisplayPopupHOCState({
              open: true,
              title: 'Pickup Details',
              selectedData: rowData,
              jsonData: rowData.pickupDetails,
            });
          }}
          maxLength={2}
        />
      ),
      width: 200,
    },
    {
      headerName: 'Delivery Details',
      field: 'deliveryDetails',
      renderCell: ({ row: rowData }) => (
        <DataGridJsonDisplayCell
          jsonObj={rowData.pickupDetails}
          seeMoreButtonClick={() => {
            updateJsonInfoDisplayPopupHOCState({
              open: true,
              title: 'Delivery Details',
              selectedData: rowData,
              jsonData: rowData.deliveryDetails,
            });
          }}
          maxLength={2}
        />
      ),
      width: 200,
    },
    {
      headerName: 'Activities',
      field: 'activities',
      renderCell: ({ row: rowData }) => (
        <DataGridJsonDisplayCell
          jsonObj={rowData.activities}
          seeMoreButtonClick={() => {
            updateJsonInfoDisplayPopupHOCState({
              open: true,
              title: 'Activities Details',
              selectedData: rowData,
              jsonData: rowData.activities,
            });
          }}
          maxLength={3}
        />
      ),
      width: 170,
    },
    {
      headerName: 'Reference Number',
      field: 'referenceNumber',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.referenceNumber} />
      ),
      width: 135,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row: rowData }) => {
        const isStatusCancelled = compareStringsIgnoreCase(
          rowData.status,
          'cancelled'
        );
        return (
          <DataGridWrapCell>
            {!!isStatusCancelled && (
              <FlexColumn>
                <Tooltip title='Cancel Order' arrow>
                  <IconButton
                    color='inherit'
                    size='small'
                    aria-label='delete'
                    onClick={(evt) => {
                      evt.stopPropagation();
                      updateDeletePopupHOCState({
                        open: true,
                        title: `Do you want to cancel this order: ${rowData.referenceNumber}?`,
                        onConfirm: () => handleCancelOrder(rowData),
                        confirmButtonText: 'Cancel Order',
                      });
                    }}
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </FlexColumn>
            )}
          </DataGridWrapCell>
        );
      },
    },
  ];

  const getToolbar = useCallback(() => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => {
                setSearchAutoFocus(true);
              }}
              onBlur={() => {
                setSearchAutoFocus(false);
              }}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={() =>
                exportCsvApi(
                  adminExportAllShipmentOrderLogs(tableQueryParams),
                  'All Shipment Order Logs'
                )
              }
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  }, []);

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.id}
          rowHeight={ROW_HEIGHT}
          headerHeight={60}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({
              pageSize: newPageSize,
            })
          }
          rowsPerPageOptions={[10, 20, 50]}
          rowCount={tableQueryParams.totalCount}
          loading={isFetching}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
          disableRowSelectionOnClick
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}

export default compose(
  withJsonInfoDisplayPopupHOC,
  withFtrDeletePopupHOC
)(ShipmentOrderLogs);

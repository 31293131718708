import { ceil, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { Badge, Button, IconButton, Tooltip } from '@material-ui/core';

import { Edit as EditIcon, FileCopy, Info, Publish } from '@material-ui/icons';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import EmailStatusIcon from '../../components/icons/EmailStatusIcon';
import InfoIcon from '../../components/icons/InfoIcon';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import DeliveryOptionsPanel from '../../components/panels/DeliveryOptionsPanel';
import AdminQuoteDetailsDisplayPopup from '../../components/popups/AdminQuoteDetailsDisplayPopup';
import DisabledReasonPopup from '../../components/popups/DisabledReasonPopup';
import PoAmountPopup from '../../components/popups/PoAmountPopup';
import RejectedReasonPopup from '../../components/popups/RejectedReasonPopup';
import YesNoPopup from '../../components/popups/YesNoPopup';
import Row from '../../components/Row';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import {
  getCheckoutPricingSummaryWithCache,
  getCombinedQuotationsFormUrl,
} from '../../apis/multiCheckoutApi';
import {
  adminExportQuotationsAsCsv,
  adminGetAllQuotations,
  adminUpdateQuotation,
  updateReasonQuotations,
} from '../../apis/quotationApi';
import { getUserAddresses, getUserInfo } from '../../apis/userApi';

import { displayEditOrderPage } from '../../actions';

import { addressInfoText, getDefaultAddresses } from '../../utils/addressUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
} from '../../utils/currencyUtils';
import {
  DATE_FORMAT,
  dateTzSingapore,
  formatDeliveryDate,
} from '../../utils/dateTimeUtils';
import { getBestShippingMode } from '../../utils/deliveryUtils';
import { downloadS3File, getPORevisedUploadUrl } from '../../utils/fileUtils';
import {
  getBackgroundColorByQuoteStatus,
  reasonsTextUtil,
} from '../../utils/quotationUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import { exportCsvApi } from '../../utils/csvExportUtils';

import { notifySuccess } from '../../services/notificationService';

import {
  materialWithColorCol,
  partIDColumnWithLink,
  projectIDColumn,
  surfaceFinishingWithColorCol,
  threeDInfillCol,
  threeDLayerThicknessCol,
  threeDTechnologyCol,
} from '../../constants/itemTableConstants';
import {
  dateOfConfirmationCol,
  markupPercentCol,
  originalQuoteColumn,
  platformFeeCol,
  poAcknowledgeColumn,
  purchaseOrderAdminCol,
  quoteValidTillCol,
  repeatOrderInfoCol,
  rocketQuoteInfoCol,
  supplierRemarksCol,
  totalPriceShownToCustomerCol,
} from '../../constants/quotationTableConstants';

import {
  CURRENCY,
  DEFAULT_FACTOREM_GST,
  ORDER_STATUS,
  ROLE_TYPES,
} from '../../constants';
import {
  FREE_DELIVERY_FEE_THRESHOLD_IN_SGD,
  SHIPPING_MODES,
} from '../../constants/checkoutConstants';
import { COUNTRY_NAMES } from '../../constants/countryConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { DEFAULT_SHIP_MODE_LIST } from '../../constants/itemConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  cardDisplay: {
    minWidth: 275,
    variant: 'elevated',
  },
  header: {
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      justifyContent: 'center !important',
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(0.8) translate(75%, 20%)',
    },
  },
  formControl: {
    marginTop: '10px',
    width: '150px',
    '& .MuiPopover-paper': {
      width: '330px !important',
    },
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '12px',
      fontWeight: '600',
    },
  },
  buttonEnterReason: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00000011',
    },
    backgroundColor: '#00000008',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '13px',
    whiteSpace: 'nowrap',
  },
  formRejected: {
    '& .MuiTypography-body1': {
      fontSize: '13px',
    },
    '& .PrivateSwitchBase-root-40': {
      padding: '5px',
    },
  },
  listReasons: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  otherInput: {
    display: 'flex',
    marginLeft: '35px',
  },
  buttonSubmitReasonRejected: {
    '& .makeStyles-submitBtn-44': {
      fontSize: '13px',
      padding: '10px 15px',
    },
  },
  uploadIcon: {
    color: 'gray',
    marginRight: '5px',
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
    fontSize: '16px',
  },
  tooltipBadge: {
    top: -10,
  },
}));

export const DOWNLOAD_PO_LINK = 'download-po-link';

export function AllQuotesDataGridPagination(props) {
  const classes = useStyles();

  const { openEditOrder, role } = props;

  const [showRejectedReasonPopup, setShowRejectedReasonPopup] = useState(false);
  const [showDisabledReasonPopup, setShowDisabledReasonPopup] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [openQuoteDetails, setOpenQuoteDetails] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [quoteParams, updateQuoteParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      quotations: [],
      currency: CURRENCY.SGD,
      exchangeRate: 1,
      shippingMode: SHIPPING_MODES.ONE_THREE_DAYS,
      addresses: null,
      showPopup: false,
    }
  );
  const [poAmountPopup, updatePoAmountPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      show: false,
      data: null,
      poFormURL: '',
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    adminGetAllQuotations(tableQueryParams)
      .then((data) => {
        setTableData(data?.rows || []);
        updateTableQueryParams({
          totalCount: data?.totalCount || 0,
          loading: false,
        });
      })
      .finally(() => {
        updateTableQueryParams({ loading: false });
      });
  };

  let timer;
  useEffect(() => {
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  const renderCellYes = () => {
    return <span style={{ color: 'green' }}>Yes</span>;
  };

  const renderCellNo = () => {
    return <span style={{ color: 'orange' }}>No</span>;
  };

  const renderCellPpeQuoteBadgeTooltip = (numOfPpeQuotes) => {
    const supplierText = numOfPpeQuotes > 1 ? 'Suppliers' : 'Supplier';
    return (
      <Tooltip
        style={{ lineHeight: 'normal' }}
        title={`${numOfPpeQuotes} ${supplierText} quoted`}
        arrow
        placement='top'
      >
        <Badge
          badgeContent={numOfPpeQuotes}
          color='error'
          classes={{
            badge: classes.tooltipBadge,
          }}
        >
          {renderCellYes()}
        </Badge>
      </Tooltip>
    );
  };

  const handlePORevisedUploadCallback = async (
    rowData,
    { files, poFormURL }
  ) => {
    updatePoAmountPopup({ data: rowData, files, poFormURL, show: true });
  };

  const handleSubmitPOAmount = async (poAmount) => {
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    try {
      const poRevisedUrl = await getPORevisedUploadUrl(
        poAmountPopup.files,
        poAmountPopup.poFormURL
      );
      await adminUpdateQuotation(poAmountPopup.data?.quotationID, {
        poAmount,
        combinedPOsForm: poRevisedUrl,
      });
      toast.update(toastId, {
        render: `Revised PO uploaded for all parts awarded to ${poAmountPopup.data?.supplierName} in Project ${poAmountPopup.data?.projectID}.`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      getTableData();
    } catch (err) {
      toast.update(toastId, {
        render: err?.message || 'Error uploading PO file!',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    }
  };

  const handleRejectedReason = async ({ rejectedReason, quotationIDs }) => {
    updateReasonQuotations({ rejectedReason, quotationIDs }).then(() => {
      getTableData();
      notifySuccess('Reason rejected has been updated successfully');
      setShowRejectedReasonPopup(false);
    });
  };

  const handleDisabledReason = async ({ disabledReason, quotationIDs }) => {
    updateReasonQuotations({ disabledReason, quotationIDs }).then(() => {
      getTableData();
      notifySuccess('Reason disabled has been updated successfully');
      setShowDisabledReasonPopup(false);
    });
  };

  const handleDownloadCsv = async () => {
    setIsLoadingCsv(true);
    await exportCsvApi(
      adminExportQuotationsAsCsv(tableQueryParams),
      'All Quotes'
    ).finally(() => {
      setIsLoadingCsv(false);
    });
  };

  const downloadFile = (body) => {
    getCombinedQuotationsFormUrl(body).then((data) => {
      if (data) {
        downloadS3File(data);
      }
    });
  };

  /**
   * Download Combined Quote
   * For shipping, it will follow client’s default shipping address.
   * If default shipping address is Singapore AND order value less than <S$1000, request admin clicking the download quotation button for shipping option to select.
   * If default shipping address is Singapore AND order value >S$1000, apply priority shipping by default, which should be free
   * If default shipping address is outside Singapore then apply priority shipping fee based on international shipping cost.
   * @param {*} rowData
   */
  const downloadCombinedQuotationsForm = async (rowData) => {
    const [userAddresses, user] = await Promise.all([
      getUserAddresses(rowData?.buyerID),
      getUserInfo(rowData?.buyerID),
    ]);
    const { defaultBilling, defaultShipping } =
      getDefaultAddresses(userAddresses);
    const _billing = defaultBilling || defaultShipping;
    let { totalPrice } = convertPriceWithQuantityToCurrency({
      totalPrice: rowData?.totalPrice,
      currency: CURRENCY_CODE.SGD,
    });
    const addresses = {
      shipping: {
        address: addressInfoText(defaultShipping),
        contactNumber: defaultShipping?.contactNumber || user?.contact,
        contactName: defaultShipping?.contactName || user?.name,
        country: defaultShipping?.country || user?.country,
      },
      billing: {
        address: addressInfoText(_billing),
        contactNumber: _billing?.contactNumber || user?.contact,
        contactName: _billing?.contactName || user?.name,
        country: _billing?.country || user?.country,
      },
    };
    const isSingaporeMore1000 =
      defaultShipping?.country === COUNTRY_NAMES.SINGAPORE &&
      totalPrice > FREE_DELIVERY_FEE_THRESHOLD_IN_SGD;
    const isOutsideSingapore =
      defaultShipping?.country !== COUNTRY_NAMES.SINGAPORE;
    const params = {
      quotations: [rowData?.quotationID],
      exchangeRate: 1,
      addresses,
    };
    if (isSingaporeMore1000 || isOutsideSingapore) {
      params.shippingMode = SHIPPING_MODES.SAME_DATE;
      downloadFile(params);
    } else {
      const body = {
        selectedQuotes: [rowData],
        currency: CURRENCY.SGD,
        exchangeRate: 1,
        shippingMode: SHIPPING_MODES.ONE_THREE_DAYS,
        country: addresses?.shipping?.country,
      };
      const multiCheckoutForm = await getCheckoutPricingSummaryWithCache(body);
      const deliveryDateModes = get(multiCheckoutForm, ['deliveryDateModes']);
      const deliveryModePrices = get(multiCheckoutForm, ['deliveryModePrices']);
      if (!isEmptyValue(deliveryDateModes)) {
        const shippingModeList = Object.values(DEFAULT_SHIP_MODE_LIST)
          .filter((mode) =>
            Object.keys(deliveryModePrices).includes(mode.value)
          )
          .map((mode) => {
            const dateStr = deliveryDateModes[mode.value];
            return {
              ...mode,
              date: formatDeliveryDate(dateStr),
              fee:
                Number(deliveryModePrices[mode.value]?.fee) === 0
                  ? 'Free'
                  : deliveryModePrices[mode.value]?.feeStr,
            };
          });
        const bestShippingMode = getBestShippingMode(deliveryModePrices, {
          country: addresses?.shipping?.country,
        });
        if (bestShippingMode && bestShippingMode !== quoteParams.shippingMode) {
          updateQuoteParams({ shippingMode: bestShippingMode });
        }
        updateQuoteParams({ shippingModeList, showPopup: true, ...params });
      }
    }
  };

  const renderEditButton = (rowData) => {
    if (
      (rowData.status === 'disabled' || rowData.status === 'rejected') &&
      role === 'reviewer'
    ) {
      return (
        <IconButton
          aria-label='edit'
          onClick={() => openEditOrder(rowData.quotationID)}
          disabled
        >
          <EditIcon />
        </IconButton>
      );
    } else {
      return (
        <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
          <IconButton
            aria-label='edit'
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) {
                window.open(`/order/edit/${rowData.quotationID}`);
                return;
              }
              openEditOrder(rowData.quotationID);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const actionsColum = {
    headerName: ' ',
    renderCell: ({ row: rowData }) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title='View quote details'>
            <IconButton
              edge='end'
              aria-label='delete'
              onClick={() => {
                setOpenQuoteDetails(true);
                setSelectedQuotation(rowData);
              }}
            >
              <Info color='primary' style={{ fontSize: '15pt' }} />
            </IconButton>
          </Tooltip>
          {renderEditButton(rowData)}
        </div>
      );
    },
    field: 'actions',
  };

  const columns = [
    actionsColum,
    {
      headerName: 'Quote ID',
      field: 'quotationID',
    },
    {
      ...projectIDColumn,
    },
    {
      ...partIDColumnWithLink,
    },
    { headerName: 'Name', field: 'name' },
    {
      headerName: 'Customer',
      field: 'buyerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.buyerName} (${rowData.buyerID})`}
        />
      ),
      width: 150,
    },
    {
      headerName: 'Date of Quote',
      field: 'dateOfOrder',
      renderCell: ({ row: rowData }) =>
        rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : '',
      minWidth: 150,
    },
    {
      headerName: 'Expiry date',
      field: 'dateOfExpiry',
      renderCell: ({ row: rowData }) =>
        rowData.dateOfExpiry
          ? dateTzSingapore(rowData.dateOfExpiry, DATE_FORMAT)
          : '',
      minWidth: 150,
    },
    { headerName: 'Quantity', field: 'quantity' },
    { headerName: 'Technology', field: 'technology', width: 150 },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    {
      ...originalQuoteColumn,
    },
    {
      headerName: 'Price from supplier (excl. GST) in SGD',
      field: 'priceFromSupplierExclGstInSGD',
      renderCell: ({ row: rowData }) => 'S$' + rowData.priceBidded,
      minWidth: 200,
    },
    {
      headerName: 'GST',
      field: 'gst',
      renderCell: ({ row: rowData }) =>
        'S$' + (rowData.priceBidded * (rowData.gst / 100)).toFixed(2),
    },
    {
      headerName: 'Delivery Fees',
      field: 'deliveryFees',
      renderCell: ({ row: rowData }) => 'S$' + rowData.deliveryFee,
    },
    {
      headerName: 'CMM Price',
      field: 'cmmPrice',
      renderCell: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price: get(rowData, 'metadata.cmmPrice', 0) || 0,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        });
      },
      minWidth: 120,
    },
    {
      headerName: 'CMM Price for Customer',
      field: 'cmmPriceForCustomer',
      renderCell: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price:
            get(rowData, 'metadata.cmmPriceCustomer') ||
            get(rowData, 'metadata.cmmPrice') ||
            0,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        });
      },
      width: 150,
    },
    {
      ...totalPriceShownToCustomerCol,
    },
    {
      ...platformFeeCol,
    },
    {
      headerName: 'Total Price (incl. GST)',
      field: 'totalPriceIncGst',
      exportData: ({ row: rowData }) => {
        return rowData.totalPrice
          ? convertPriceToCurrency(
              {
                price: rowData.totalPrice * (1 + DEFAULT_FACTOREM_GST),
                currency: CURRENCY_CODE.SGD,
                exchangeRate: 1,
              },
              ''
            )
          : '';
      },
      renderCell: function ({ row: rowData }) {
        return rowData.totalPrice ? (
          <div>
            {convertPriceToCurrency(
              {
                price: rowData.totalPrice * (1 + DEFAULT_FACTOREM_GST),
                currency: CURRENCY_CODE.SGD,
                exchangeRate: 1,
              },
              ''
            )}
          </div>
        ) : (
          <div></div>
        );
      },
      width: 130,
    },
    {
      headerName: 'PO Amount',
      field: 'poAmount',
      renderCell: ({ row: rowData }) => {
        return rowData.poAmount ?? 'N.A';
      },
      width: 120,
    },
    {
      headerName: 'Lead time (original + markup)',
      field: 'leadTimeOriginalMarkup',
      renderCell: ({ row: rowData }) =>
        `${rowData.leadTime} + ${rowData.markupLeadTime} working days`,
      minWidth: 150,
    },
    {
      headerName: 'Supplier name',
      field: 'supplierName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.supplierName} (${rowData.userID})`}
        />
      ),
      minWidth: 150,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        const bgColor = getBackgroundColorByQuoteStatus(rowData.status);
        const reasonRejectedWithSymbol = reasonsTextUtil(
          rowData.reasonRejected
        );
        const disabledReasonsWithSymbol = reasonsTextUtil(
          rowData.disabledReason
        );
        const checkPpeQuotes = rowData.ppeQuotes
          ? rowData.ppeQuotes.quotes.filter(
              (quote) => quote.status === ORDER_STATUS.REJECTED
            )
          : [];
        const conditionalEnterRejectedReason =
          checkPpeQuotes.length > 0 || rowData.status === ORDER_STATUS.REJECTED;
        return (
          <div>
            <div
              style={{
                backgroundColor: bgColor,
                display: 'flex',
                width: '150px',
                justifyContent: 'center',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
            >
              {rowData.status?.split('_').join(' ')}
            </div>
            {rowData.status === ORDER_STATUS.ACCEPTED && (
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '0.75rem',
                  whiteSpace: 'nowrap',
                  lineHeight: 'normal',
                }}
              >
                {rowData.dateOfConfirmation
                  ? dateTzSingapore(rowData.dateOfConfirmation)
                  : ''}
              </div>
            )}
            {conditionalEnterRejectedReason && (
              <>
                <Button
                  key='rejected-reason'
                  className={classes.buttonEnterReason}
                  onClick={(e) => {
                    setSelectedQuotation(rowData);
                    setShowRejectedReasonPopup(true);
                    e.stopPropagation();
                  }}
                >
                  <Publish className={classes.uploadIcon} />
                  Enter Reason
                </Button>
                {reasonRejectedWithSymbol && (
                  <div className={classes.listReasons}>
                    {reasonRejectedWithSymbol}
                  </div>
                )}
              </>
            )}
            {rowData.status === ORDER_STATUS.DISABLED && (
              <>
                <Row style={{ justifyContent: 'center' }}>
                  <Button
                    key='disabled-reason'
                    className={classes.buttonEnterReason}
                    onClick={(e) => {
                      setSelectedQuotation(rowData);
                      setShowDisabledReasonPopup(true);
                      e.stopPropagation();
                    }}
                  >
                    <Publish className={classes.uploadIcon} />
                    Enter Reason
                  </Button>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      lineHeight: 'normal',
                    }}
                  >
                    {rowData.disabledDate &&
                      rowData.disabledActionOwnerName &&
                      rowData.disabledActionOwnerID && (
                        <InfoIcon
                          toolTipText={`Disabled on ${dateTzSingapore(
                            rowData.disabledDate,
                            'YYYY-MM-DD [at] HH:mm'
                          )} by ${rowData.disabledActionOwnerName} (${
                            rowData.disabledActionOwnerID
                          })`}
                        />
                      )}
                  </div>
                </Row>
                {disabledReasonsWithSymbol && (
                  <div className={classes.listReasons}>
                    {disabledReasonsWithSymbol}
                  </div>
                )}
              </>
            )}
          </div>
        );
      },
      width: 150,
    },
    {
      headerName: 'Rejected Reason',
      field: 'reasonRejected',
      exportData: ({ row: rowData }) => {
        return reasonsTextUtil(rowData.reasonRejected);
      },
      hide: true,
      export: true,
    },
    {
      headerName: 'Disabled Reason',
      field: 'disabledReason',
      exportData: ({ row: rowData }) => {
        return reasonsTextUtil(rowData.disabledReason);
      },
      hide: true,
      export: true,
    },
    {
      ...dateOfConfirmationCol,
    },
    {
      headerName: 'Verification Date',
      field: 'verifiedDate',
      renderCell: ({ row: rowData }) =>
        rowData.verifiedDate ? rowData.verifiedDate.substring(0, 10) : '',
      width: 120,
    },
    {
      headerName: 'Email status',
      field: 'emailStatus',
      renderCell: ({ row: rowData }) => {
        return (
          <FlexRow>
            {rowData.brevoNewRfqProjectQuotations && (
              <EmailStatusIcon
                status={rowData.brevoNewRfqProjectQuotations?.lastStatus}
                emailType={rowData.brevoNewRfqProjectQuotations?.type}
                date={rowData.brevoNewRfqProjectQuotations?.updatedAt}
              />
            )}
          </FlexRow>
        );
      },
    },
    {
      headerName: 'Customer remarks',
      field: 'customerRemarks',
      maxWidth: 280,
      width: 130,
    },
    {
      headerName: 'Quotation Form',
      field: 'quotationForm',
      exportData: ({ row: rowData }) => rowData.quotationForm || 'N.A.',
      renderCell: ({ row: rowData }) =>
        rowData.quotationForm ? (
          <div
            style={{
              display: 'flex',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <Link
              onClick={(e) => {
                e.stopPropagation();
                downloadCombinedQuotationsForm(rowData);
              }}
            >
              Download Quotation
            </Link>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(rowData.quotationForm);
              }}
            >
              <FileCopy />
            </IconButton>
          </div>
        ) : (
          <div>N.A.</div>
        ),
      width: 135,
    },
    {
      ...purchaseOrderAdminCol({
        uploadCallback: handlePORevisedUploadCallback,
      }),
      hide: role === ROLE_TYPES.SALES_CONSULTANT,
    },
    {
      headerName: 'PPE Quote',
      field: 'ppeQuote',
      exportData: ({ row: rowData }) => {
        const isPpeQuote =
          rowData.rfqQuote == null ? !!rowData.price : !rowData.rfqQuote;
        if (!isPpeQuote) {
          return 'No';
        }
        return 'Yes';
      },
      renderCell: ({ row: rowData }) => {
        const isPpeQuote =
          rowData.rfqQuote == null ? !!rowData.price : !rowData.rfqQuote;
        const isRocketQuote = rowData.rocketQuote;
        if (!isPpeQuote) {
          return renderCellNo();
        }
        if (!isRocketQuote) {
          const { numOfPpeQuotes } = rowData;
          return numOfPpeQuotes
            ? renderCellPpeQuoteBadgeTooltip(numOfPpeQuotes)
            : renderCellYes();
        }
        return renderCellYes();
      },
      textAlign: 'center',
    },
    {
      ...rocketQuoteInfoCol,
    },
    {
      headerName: 'Admin Creator Email',
      field: 'adminCreatorEmail',
      exportData: ({ row: rowData }) => {
        return rowData.adminEmail || 'N.A.';
      },
      hide: true,
      export: true,
    },
    {
      headerName: 'Admin Creator ID',
      field: 'adminCreatorID',
      exportData: ({ row: rowData }) => {
        return rowData.adminID || 'N.A.';
      },
      hide: true,
      export: true,
    },
    {
      ...quoteValidTillCol,
    },
    {
      ...supplierRemarksCol,
    },
    {
      ...markupPercentCol,
      hide: role === ROLE_TYPES.SALES_CONSULTANT,
    },
    {
      ...threeDTechnologyCol,
    },
    {
      ...threeDInfillCol,
    },
    {
      ...threeDLayerThicknessCol,
    },
    {
      ...poAcknowledgeColumn,
    },
    {
      ...repeatOrderInfoCol,
    },
  ];

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
              loading={isLoadingCsv}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  const renderBodyDeliveryOptionsPopup = () => {
    return (
      <div style={{ paddingTop: '0.5rem' }}>
        <DeliveryOptionsPanel
          shippingModeList={quoteParams.shippingModeList}
          onChange={(value) => updateQuoteParams({ shippingMode: value })}
          shippingMode={quoteParams.shippingMode}
          disabled={false}
          status='success'
        />
      </div>
    );
  };

  return role === 'buyer' || role === 'supplier' ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.quotationID}
          rowHeight={100}
          headerHeight={80}
          components={{
            Toolbar: getCustomerToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          page={tableQueryParams.page}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          rowCount={tableQueryParams.totalCount}
          loading={tableQueryParams.loading}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
      {showRejectedReasonPopup && (
        <RejectedReasonPopup
          dialog={showRejectedReasonPopup}
          handleClose={() => setShowRejectedReasonPopup(false)}
          onOk={handleRejectedReason}
          rowData={selectedQuotation}
        />
      )}
      {showDisabledReasonPopup && (
        <DisabledReasonPopup
          dialog={showDisabledReasonPopup}
          handleClose={() => setShowDisabledReasonPopup(false)}
          onOk={handleDisabledReason}
          rowData={selectedQuotation}
        />
      )}
      {openQuoteDetails && (
        <AdminQuoteDetailsDisplayPopup
          open={openQuoteDetails}
          rowData={selectedQuotation}
          onClose={() => setOpenQuoteDetails(false)}
        />
      )}
      {quoteParams.showPopup && (
        <YesNoPopup
          open={true}
          title={`Download Quotation ${quoteParams.quotations[0]}`}
          handleNo={() => updateQuoteParams({ showPopup: false })}
          handleYes={() => {
            downloadFile({
              quotations: quoteParams.quotations,
              addresses: quoteParams.addresses,
              shippingMode: quoteParams.shippingMode,
              exchangeRate: 1,
            });
            updateQuoteParams({ showPopup: false });
          }}
          body={renderBodyDeliveryOptionsPopup()}
          noButtonLabel='Cancel'
          yesButtonLabel='Download'
        />
      )}
      {poAmountPopup.show && (
        <PoAmountPopup
          open={poAmountPopup.show}
          rowData={poAmountPopup.data}
          onClose={() => updatePoAmountPopup({ show: false })}
          onSubmit={handleSubmitPOAmount}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    openEditOrder: (id) => dispatch(displayEditOrderPage(id, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(AllQuotesDataGridPagination));

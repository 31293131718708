import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
const dayjs = require('dayjs');
import { get } from 'lodash';

import { getAllItems } from './items';

import formulas from '../formulas/formulas';

import { createNewQuoteApi } from '../apis/quotationApi';
import {
  dfmExtractDimensionsApi,
  getDfmExtractDefectsWithCache,
} from '../apis/dfmApi';
import {
  deleteItemApi,
  editItemApi,
  getItemDetailsApi,
  getItemDetailsForSupplierApi,
  updateItemCanvasDataApi,
} from '../apis/itemApi';
import { getFeatureFlags } from '../apis/configurationApi';
import { getQueueExecutorByTaskID } from '../apis/queueExecutorApi';

import { getExchangeRateSelector } from '../selectors/exchangeRatesSelector';
import { getUserCurrencySelector } from '../selectors/userSelector';

import { partUploadProxy } from '../proxies/partUploadProxy';
import { reduxStateProxy } from './reduxStateProxy';

import { updatePartsLibraryItemData } from './partsLibrary';

import { isEmptyValue, sleep } from '../utils/commonUtils';
import { extractTdeForItem } from './utils/tdeActionUtils';
import { getCurrentDateStr, getDateStr } from '../utils/dateTimeUtils';
import { getFileToGenerate2DImage } from '../utils/imageConverterUtils';
import {
  getItemMaterial,
  getTechnologyDisplayText,
  is3DPTechnology,
} from '../utils/itemUtils';
import {
  getPpePriceForItem,
  makePpeItemRfq,
  revertRfqToPpeItem,
} from '../utils/ppeUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isSourceUrlAndTechValid } from '../utils/dfmExtractionUtils';
import { isCustomTechnology } from '../utils/inputUtils';
import { toLowerCaseString } from '../utils/stringUtils';

import { convert2DImage } from '../services/convert2DImageService';
import {
  getCache,
  removeCache,
  setCache,
} from '../services/localStorageCacheService';
import { notifyError } from '../services/notificationService';
import { uploadCadFileToS3 } from '../services/s3Service';

import { techMapping } from '../constants/PPEConstants';

import { TDE_TYPE } from '../constants/userConstant';
import { FE_FEATURE_FLAGS_CONFIGURATION } from '../constants/configurations';
import { CURRENCY, ROLE_TYPES, TWO_D_IMAGE_URLS_KEY } from '../constants';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import { FEATURE_FLAG_HUBSPOT } from '../constants/featureFlagConstants';

// ----------------------------------------------------------------------------------------------------------------

export const GET_ITEM_LOADING = 'GET_ITEM_LOADING';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAILURE = 'GET_ITEM_FAILURE';
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_LOADING = 'CREATE_QUOTATION_LOADING';
export const CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILURE = 'EDIT_ITEM_FAILURE';
export const EDIT_ITEM_LOADING = 'EDIT_ITEM_LOADING';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const DELETE_ITEM_LOADING = 'DELETE_ITEM_LOADING';

export const SAVE_ORDERED_AGAIN_PARTS = 'SAVE_ORDERED_AGAIN_PARTS';
export const DELETE_ORDERED_AGAIN_PARTS = 'DELETE_ORDERED_AGAIN_PARTS';
export const SAVE_PART_SUMMARY_DETAILS = 'SAVE_PART_SUMMARY_DETAILS';
export const UPDATE_2D_IMAGE_URL = 'UPDATE_2D_IMAGE_URL';
export const UPDATE_UPLOAD_PROJECT_FILES_STEPS =
  'UPDATE_UPLOAD_PROJECT_FILES_STEPS';
export const RESET_PART_SUMMARY_DETAILS = 'RESET_PART_SUMMARY_DETAILS';
export const ADD_OR_UPDATE_CAD_PART = 'ADD_OR_UPDATE_CAD_PART';
export const UPDATE_CAD_PART_IF_EXISTS = 'UPDATE_CAD_PART_IF_EXISTS';
export const REMOVE_CAD_PART = 'REMOVE_CAD_PART';
export const RESTORE_PARTS = 'RESTORE_PARTS';

export const GET_PPE_PRICE = {
  YES: 'YES',
  NO: 'NO',
};

export const EXTRACT_TDE = {
  YES: 'YES',
  NO: 'NO',
};

export const deletePartDetails = () => {
  return {
    type: DELETE_ORDERED_AGAIN_PARTS,
  };
};

export const resetPartSummaryDetails = () => {
  return {
    type: RESET_PART_SUMMARY_DETAILS,
  };
};

export const savePartSummaryDetails = (formDataAvailable) => {
  return {
    type: SAVE_PART_SUMMARY_DETAILS,
    formDataAvailable,
  };
};

export const restoreParts = (cacheParts) => {
  return {
    type: RESTORE_PARTS,
    payload: cacheParts,
  };
};

const getItem = () => {
  return {
    type: GET_ITEM_LOADING,
  };
};

const getItemSuccess = (item) => {
  return {
    type: GET_ITEM_SUCCESS,
    item,
  };
};

const getItemFailure = () => {
  return {
    type: GET_ITEM_FAILURE,
  };
};

export const getItemDetails = (id) => async (dispatch) => {
  dispatch(getItem());
  return getItemDetailsApi(id)
    .then(function (item) {
      dispatch(getItemSuccess(item));
    })
    .catch(function () {
      dispatch(getItemFailure());
    });
};

export const getPPEPrice = (dataArray) => async (dispatch) => {
  const resultArray = await Promise.all(
    dataArray.map((item) => getPpePriceForItem(item))
  );
  dispatch(savePartSummaryDetails(resultArray));
};

export const getItemDetailsForSupplier = (id, userID) => async (dispatch) => {
  dispatch(getItem());
  return getItemDetailsForSupplierApi(id, userID)
    .then(function (item) {
      dispatch(getItemSuccess(item));
    })
    .catch(function () {
      dispatch(getItemFailure());
    });
};

const createQuotationFailure = () => {
  toast.error(
    'Unable to submit quotation. Please check your quotation again.',
    {
      position: toast.POSITION.TOP_RIGHT,
    }
  );
  return {
    type: CREATE_QUOTATION_FAILURE,
  };
};

const createQuotationSuccess = (props, isAdmin = false) => {
  if (!isAdmin) {
    props.history.push('/orders');
  }
  toast.success('Quotation(s) successfully submitted!', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: CREATE_QUOTATION_SUCCESS,
  };
};

const createQuotationLoading = () => {
  return {
    type: CREATE_QUOTATION_LOADING,
  };
};

const editPartSuccess = ({ regeneratePO }) => {
  const toastMessage = 'Item successfully updated!';
  toast.success(toastMessage, {
    position: toast.POSITION.TOP_RIGHT,
  });
  if (regeneratePO) {
    toast.success('PO successfully regenerated!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return {
    type: EDIT_ITEM_SUCCESS,
  };
};

const editPartLoading = () => {
  return {
    type: EDIT_ITEM_LOADING,
  };
};

const editPartFailure = (error) => {
  if (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Unable to edit part. Please try again later.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return {
    type: EDIT_ITEM_FAILURE,
  };
};

const deleteItemSuccess = (props) => {
  props.history.push(`/manage-items`);
  toast.success('Item deleted!', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: DELETE_ITEM_SUCCESS,
  };
};

const deleteItemLoading = () => {
  return {
    type: DELETE_ITEM_LOADING,
  };
};

const deleteItemFailure = (message) => {
  notifyError(message || 'Unable to delete part. Please try again later.');
  return {
    type: DELETE_ITEM_FAILURE,
  };
};

//Function which calls endpoint to edit item details
const editItem = (itemID, itemInformationToUpdate, isAdmin) => (dispatch) => {
  return editItemApi(itemID, itemInformationToUpdate)
    .then(() => {
      dispatch(editPartLoading());
      if (isAdmin) {
        dispatch(getAllItems());
        dispatch(getItemDetails(itemID));
      }

      const { regeneratePO } = itemInformationToUpdate;
      dispatch(editPartSuccess({ regeneratePO }));
    })
    .catch(function (err) {
      dispatch(editPartFailure(err.message));
    });
};

export const updateItemCanvasData = (itemID, canvasData) => (dispatch) => {
  return updateItemCanvasDataApi(itemID, canvasData)
    .then(() => {
      dispatch(editPartLoading());
      dispatch(getItemDetails(itemID));
    })
    .catch(function (err) {
      dispatch(editPartFailure(err.message));
    });
};

export const deletePart = (deletedPart, props) => (dispatch) => {
  return deleteItemApi(deletedPart)
    .then(function (response) {
      dispatch(deleteItemLoading());
      response.json().then((res) => {
        if (res.success) {
          dispatch(deleteItemSuccess(props));
        } else if (response.status === 401 || res.statusCode === 403) {
          dispatch(
            deleteItemFailure(
              res.message || response.statusText || 'Permission Denied'
            )
          );
        } else {
          dispatch(deleteItemFailure());
        }
      });
    })
    .catch(function () {
      dispatch(deleteItemFailure());
    });
};

//Buyer edit part
export const customerEditItem = (editedPart) => (dispatch, getState) => {
  const itemID = editedPart.itemID;
  let joinStrings = '';

  const arrayOfOriginalFiles = editedPart.cadPart;
  if (Array.isArray(arrayOfOriginalFiles.cadPart)) {
    joinStrings = arrayOfOriginalFiles.cadPart.join();
  } else {
    joinStrings = arrayOfOriginalFiles;
  }

  let toleranceSubmit = parseFloat(0);
  if (editedPart.tolerance) {
    toleranceSubmit = parseFloat(editedPart.tolerance);
  }

  const itemInformationToUpdate = {
    name: editedPart.name,
    status: editedPart.status,
    technology: editedPart.technology,
    datePosted: editedPart.datePosted.slice(0, 19).replace('T', ' '),
    quantity: editedPart.qty,
    description: editedPart.description,
    surfaceFinish: editedPart.surfaceFinish,
    deliveryPreference: editedPart.deliveryPref,
    partApplication: editedPart.partApplication,
    tolerance: toleranceSubmit,
    originalFiles: joinStrings,
    material: editedPart.material,
    userID:
      getState().auth.user.role === 'superadmin' ||
      getState().auth.user.role === 'admin' ||
      getState().auth.user.role === 'reviewer'
        ? editedPart.editUserID
        : getState().auth.user.userID,
    price: editedPart.price,
    color: editedPart.color,
    materialColor: editedPart.materialColor,
    twoDImageUrl: editedPart.twoDImageUrl,
    imageFile: editedPart.imageFile,
    unitType: editedPart.unitType,
  };
  if (editedPart.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
    itemInformationToUpdate.threeDTechnology = editedPart.threeDTechnology;
    itemInformationToUpdate.threeDInfill = editedPart.threeDInfill;
    itemInformationToUpdate.threeDLayerThickness =
      editedPart.threeDLayerThickness;
  }
  dispatch(editItem(itemID, itemInformationToUpdate, false));
};

//Admin edit part
export const adminEditItem = (editedPart) => (dispatch, getState) => {
  const itemID = editedPart.itemID
    ? editedPart.itemID
    : getState().item.item.itemID;
  let joinStringsOfFactoremCad = '';
  let joinStringsOfCustomerCad = '';
  const arrayOfUpdatedCadFiles = editedPart.editCadFile;
  if (Array.isArray(arrayOfUpdatedCadFiles.cadPart)) {
    joinStringsOfFactoremCad = arrayOfUpdatedCadFiles.cadPart.join();
  } else {
    joinStringsOfFactoremCad = arrayOfUpdatedCadFiles;
  }

  const arrayOfOriginalCadFiles = editedPart.editOriginalFiles;
  if (Array.isArray(arrayOfOriginalCadFiles.cadPart)) {
    joinStringsOfCustomerCad = arrayOfOriginalCadFiles.cadPart.join();
  } else {
    joinStringsOfCustomerCad = arrayOfOriginalCadFiles;
  }

  let toleranceSubmit = parseFloat(0);
  if (editedPart.editTolerance) {
    toleranceSubmit = parseFloat(editedPart.editTolerance);
  }

  let priceSubmit = editedPart.editPrice;
  if (
    !editedPart.editPrice ||
    editedPart.editPrice === 0 ||
    editedPart.editPrice === '0'
  ) {
    priceSubmit = null;
  }

  const technology = !isCustomTechnology(editedPart.editTech)
    ? editedPart.editTech
    : editedPart.otherTechnology;

  const itemInformationToUpdate = {
    ...editedPart,
    name: editedPart.editName,
    referenceName: editedPart.editReferenceName,
    status: editedPart.editStatus,
    paymentStatus: editedPart.editPaymentStatus,
    technology,
    datePosted: editedPart.editDatePosted.slice(0, 19).replace('T', ' '),
    quantity: editedPart.editQuantity,
    deadline: editedPart.editDeadline ? editedPart.editDeadline : '',
    description: editedPart.editDescription,
    deliveryPreference: editedPart.editDeliveryPreference,
    partApplication: editedPart.editPartApplication,
    tolerance: toleranceSubmit,
    cadFile: joinStringsOfFactoremCad,
    originalFiles: joinStringsOfCustomerCad,
    imageFile: editedPart.editImageFile,
    expectedPrice: editedPart.editExpectedPrice,
    userID:
      getState().auth.user.role === 'superadmin' ||
      getState().auth.user.role === 'admin' ||
      getState().auth.user.role === 'reviewer'
        ? editedPart.editUserID
        : getState().auth.user.userID,
    price: priceSubmit,
    ppeMarkdown: editedPart.editPpeMarkdown,
    verifiedDate: editedPart.verifiedDate || '',
    deliveryDate: editedPart.editDeliveryDate
      ? editedPart.editDeliveryDate
      : '',
    collectionDate: editedPart.editCollectionDate
      ? editedPart.editCollectionDate
      : '',
    actualDeliveryDate: editedPart.editActualDeliveryDate
      ? editedPart.editActualDeliveryDate
      : '',
    actualCollectionDate: editedPart.editActualCollectionDate
      ? editedPart.editActualCollectionDate
      : '',
    revisedDeliveryDate: editedPart.editRevisedDeliveryDate
      ? editedPart.editRevisedDeliveryDate
      : '',
    revisedCollectionDate: editedPart.editRevisedCollectionDate
      ? editedPart.editRevisedCollectionDate
      : '',
    reworkDeliveryDate: editedPart.editReworkDeliveryDate
      ? editedPart.editReworkDeliveryDate
      : '',
    reworkActualDeliveryDate: editedPart.editReworkActualDeliveryDate
      ? editedPart.editReworkActualDeliveryDate
      : '',
    actualDeliveryCost: editedPart.editActualDeliveryCost,
    regeneratePO: editedPart.regeneratePO,
    targetPriceSupplier: editedPart.editTargetPriceSupplier
      ? editedPart.editTargetPriceSupplier
      : '',
    lateReason: editedPart.editLateReason
      ? editedPart.editOtherLateReason || editedPart.editLateReason
      : '',
  };
  if (FEATURE_FLAG_HUBSPOT === 'true') {
    itemInformationToUpdate.hubspotDealID = editedPart.hubspotDealID;
  }
  dispatch(editItem(itemID, itemInformationToUpdate, true));
};

export const openPartSummaryPage =
  (formDataAvailable, props, from = '/') =>
  (dispatch) => {
    dispatch(savePartSummaryDetails(formDataAvailable));
    props.history.push('/summary', { from });
  };

/**
 * this will get needed properties only, to prevent getting incorrect properties from
 * the old items like paymentStatus, deliveryDate which will cause issue during ops processing
 *
 * @param {*} partDetails
 */
const getOrderAgainItemProperties = (partDetails) => {
  const VALID_PROPERTIES = [
    'qty',
    'technology',
    'otherTechnology',
    'surfaceFinish',
    'otherSurfaceFinish',
    'material',
    'otherMaterial',
    'materialColor',
    'cadPart',
    'cadPartObjectList',
    'userID',
    'tolerance',
    'unitType',
    'remarks',
    'checked',
    'partApplication',
    'name',
    'imageFile',
    'color',
    'expectedLeadTime',
    '2DImageUrls',
    'price',
    'markupPercent',
    'totalPrice',
    'originalPrice',
    'itemHashcodeCad',
    'itemHashcodePdf',
    'repeatOrder',
    'refPartID',
    'refQuoteID',
    'complexity',
    'leadTime',
    'markupLeadTime',
    'ppePriceLogId',
    'targetPrice',
    'boundingBoxX',
    'boundingBoxY',
    'boundingBoxZ',
    'weight',
  ];
  // Add 3D Printing properties, if the technology is 3D Printing
  if (is3DPTechnology(get(partDetails, 'technology'))) {
    const THREE_D_P_PROPERTIES = [
      'threeDTechnology',
      'threeDLayerThickness',
      'threeDInfill',
    ];
    VALID_PROPERTIES.push(...THREE_D_P_PROPERTIES);
  }
  return VALID_PROPERTIES.reduce((acc, property) => {
    acc[property] = get(partDetails, property);
    return acc;
  }, {});
};

const extractedPartDetails = (partDetails, index = 0) => {
  const { description: remarks, originalFiles, twoDImageUrl } = partDetails;
  const extractedPartDetails = {
    ...getOrderAgainItemProperties(partDetails),
    id: `${index}`,
    status: 1,
    cadPart: originalFiles.split(','),
    remarks,
    // checked: false,
    deleted: false,
    [TWO_D_IMAGE_URLS_KEY]: [twoDImageUrl],
  };
  return extractedPartDetails;
};

export const savePartDetailsAndAddNew =
  (oldPartDetails, props) => (dispatch) => {
    dispatch(savePartSummaryDetails([extractedPartDetails(oldPartDetails)]));
    props.history.push('/summary', { from: '/' });
  };

export const saveMultiplePartDetails =
  (oldPartDetailsList, props) => (dispatch) => {
    const partDetailsArray = [];
    for (const [index, oldPartDetails] of Object.entries(oldPartDetailsList)) {
      partDetailsArray.push(extractedPartDetails(oldPartDetails, index));
    }
    dispatch(savePartSummaryDetails(partDetailsArray));
    props.history.push('/summary', { from: '/' });
  };

/**
 * @deprecated
 * @param {*} quoteObject
 * @param {*} props
 * @returns
 */
export const sendNewQuote =
  (quoteObject, props) => async (dispatch, getState) => {
    const { remarks, currency, exchangeRate, rfqQuote } = quoteObject;

    // Remove unit quote from input field objects in array
    let receivedQuotes = quoteObject.inputFields;
    const priceBidded = receivedQuotes.map((element) => ({
      quantity: element.quantity,
      quote: element.quote,
      material: element.material,
      otherMaterial: element.otherMaterial,
      surfaceFinish: element.surfaceFinish,
      otherSurfaceFinish: element.otherSurfaceFinish,
      materialColor: element.materialColor,
      color: element.color,
      leadTime: element.leadTime,
      totalPrice: formulas.calculateTotalPrice(
        element.quote,
        10,
        7,
        0,
        currency,
        exchangeRate
      ), //markup, gst and deliveryFee
      threeDTechnology: element.threeDTechnology,
      threeDInfill: element.threeDInfill,
      threeDLayerThickness: element.threeDLayerThickness,
      cmmPrice: element.cmmPrice,
    }));

    const dateOfOrder = getCurrentDateStr();
    const dateOfExpiry = getDateStr(
      quoteObject.dateOfExpiry
        ? quoteObject.dateOfExpiry
        : new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
    );
    const status = 'verifying';
    const name = getState().item.item.referenceName;
    const userRole = getState().auth.user.role;
    const userID = [
      ROLE_TYPES.ADMIN,
      ROLE_TYPES.REVIEWER,
      ROLE_TYPES.SUPER_ADMIN,
    ].includes(userRole)
      ? quoteObject.userID
      : getState().auth.user.userID;
    const itemID = getState().item.item.itemID;

    const quotation = {
      name,
      status,
      dateOfOrder,
      dateOfExpiry,
      priceBidded,
      userID,
      itemID,
      remarks,
      currency,
      exchangeRate,
      rfqQuote,
    };

    const isAdmin = isAdminOrHigherRole(userRole);

    try {
      const response = await createNewQuoteApi(quotation);
      dispatch(createQuotationLoading());
      if (response.ok) {
        dispatch(createQuotationSuccess(props, isAdmin));
      } else {
        throw new Error('Creating quotation failed');
      }
    } catch (err) {
      dispatch(createQuotationFailure());
      throw err;
    }
  };

export const update2DImageUrl = ({ originalS3Key, twoDImageUrl }) => {
  return {
    type: UPDATE_2D_IMAGE_URL,
    payload: {
      originalS3Key,
      twoDImageUrl,
    },
  };
};

export const uploadProjectFilesSteps = (payload) => {
  return {
    type: UPDATE_UPLOAD_PROJECT_FILES_STEPS,
    payload,
  };
};

export const addOrUpdateCadPart = (payload) => {
  return {
    type: ADD_OR_UPDATE_CAD_PART,
    payload,
  };
};

export const updateCadPartIfExists = (payload) => {
  return {
    type: UPDATE_CAD_PART_IF_EXISTS,
    payload,
  };
};

export const removeCadPartAction = (payload) => {
  return {
    type: REMOVE_CAD_PART,
    payload,
  };
};

/**
 * Will need to assign every request an uuid so that the response
 * of the older request will not be updated
 *
 * @param {*} id
 * @returns
 */
const LAST_REQUEST_ID_MAPPING = {};
export const getPpePriceForCadPart = (id) => (dispatch, getState) => {
  const stateProxy = new Proxy(getState(), reduxStateProxy);
  const cadPart = stateProxy.getPartUploadByID(id);
  if (!cadPart) {
    return;
  }

  const item = { ...cadPart };
  const technology = getTechnologyDisplayText(item);
  const material = getItemMaterial(item);
  if (
    [
      TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
      TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
      TECHNOLOGY_OPTION_TYPE.SHEET_METAL_FABRICATION,
    ].includes(technology) &&
    isEmptyValue(material)
  ) {
    dispatch(
      updateCadPartIfExists({
        id,
        ppePricingStatus: 'failed',
      })
    );
    return;
  }

  dispatch(
    updateCadPartIfExists({
      id,
      ppePricingStatus: 'loading',
    })
  );

  const requestID = uuidv4();
  LAST_REQUEST_ID_MAPPING[id] = requestID;

  item.requestID = requestID;

  getPpePriceForItem(item)
    .then((response) => {
      if (LAST_REQUEST_ID_MAPPING[id] !== item.requestID) {
        // not update due to older request
        return;
      }

      dispatch(
        updateCadPartIfExists({
          id,
          ...response,
          ppePricingStatus: 'success',
        })
      );
    })
    .catch(() => {
      if (LAST_REQUEST_ID_MAPPING[id] !== item.requestID) {
        // not update due to older request
        return;
      }
      dispatch(
        updateCadPartIfExists({
          id,
          ppePricingStatus: 'failed',
        })
      );
    });
};

/**
 * Will need to assign every request an uuid so that the response
 * of the older request will not be updated
 *
 * @param {*} id
 * @returns
 */
const LAST_REQUEST_DEFECT_TASK_ID_MAPPING = {};
export const getDefectByTaskIDForCadPart = (id) => (dispatch, getState) => {
  const stateProxy = new Proxy(getState(), reduxStateProxy);
  const cadPart = stateProxy.getPartUploadByID(id);
  if (!cadPart) {
    return;
  }

  const item = { ...cadPart };
  const requestID = uuidv4();
  LAST_REQUEST_DEFECT_TASK_ID_MAPPING[id] = requestID;
  const taskID = item.defectTaskID;
  item.defectRequestTaskID = requestID;

  getQueueExecutorByTaskID(taskID).then((response) => {
    if (
      LAST_REQUEST_DEFECT_TASK_ID_MAPPING[id] !== item.defectRequestTaskID ||
      isEmptyValue(response?.doneAt)
    ) {
      // not update due to older request
      return;
    }

    let params = {
      defects: {},
      defectStatus: 'failed',
    };
    if (!isEmptyValue(response?.result?.defects)) {
      params.defects = response?.result?.defects;
      params.defectTrackingID = response?.result?.trackingID;
      params.defectStatus = 'success';
    }

    dispatch(
      updateCadPartIfExists({
        id,
        ...params,
      })
    );
  });
};

export const getDefectByTaskIDAction = (id) => async (dispatch, getState) => {
  // auto refetch defect api for getting latest status task ID
  const SLEEP_INTERVAL = 10; // 10 seconds
  for (let index = 0; index < (60 * 15) / SLEEP_INTERVAL; index++) {
    // total 15 minutes
    await sleep(SLEEP_INTERVAL);

    const stateProxy = new Proxy(getState(), reduxStateProxy);
    const part = stateProxy.getPartUploadByID(id);
    if (!part || part.defectStatus !== 'loading') {
      break;
    }

    // call defect api
    dispatch(getDefectByTaskIDForCadPart(id));
  }
};

/**
 * Will need to assign every request an uuid so that the response
 * of the older request will not be updated
 *
 * @param {*} id
 * @returns
 */
const LAST_REQUEST_DEFECT_ID_MAPPING = {};
export const getDefectForCadPart = (id) => (dispatch, getState) => {
  const stateProxy = new Proxy(getState(), reduxStateProxy);
  const cadPart = stateProxy.getPartUploadByID(id);
  if (!cadPart) {
    return;
  }

  const item = { ...cadPart };
  const technology = getTechnologyDisplayText(item);
  const requestID = uuidv4();
  LAST_REQUEST_DEFECT_ID_MAPPING[id] = requestID;

  item.defectRequestID = requestID;

  if (!isSourceUrlAndTechValid([item.s3ObjectUrl], technology)) {
    dispatch(
      updateCadPartIfExists({
        id,
        // change to default value
        defectStatus: null,
        defectTaskID: null,
        defectTrackingID: null,
        defects: null,
      })
    );
    return;
  }

  dispatch(
    updateCadPartIfExists({
      id,
      defectStatus: 'loading',
    })
  );

  getDfmExtractDefectsWithCache({
    file_url: item.s3ObjectUrl,
    process: techMapping[technology],
    useBackgroundProcess: true,
  })
    .then((response) => {
      if (LAST_REQUEST_DEFECT_ID_MAPPING[id] !== item.defectRequestID) {
        // not update due to older request
        return;
      }

      dispatch(
        updateCadPartIfExists({
          id,
          defects: response.data?.defects?.defects,
          defectTrackingID: response.data?.defects?.trackingID,
          defectTaskID: response.data?.taskID,
          defectStatus: response.data?.status,
        })
      );

      dispatch(getDefectByTaskIDAction(id));
    })
    .catch(() => {
      if (LAST_REQUEST_DEFECT_ID_MAPPING[id] !== item.requestID) {
        // not update due to older request
        return;
      }
      dispatch(
        updateCadPartIfExists({
          id,
          defectStatus: 'failed',
        })
      );
    });
};

export const convertingBetweenPpeAndRfqIfNeeded =
  (id) => (dispatch, getState) => {
    const currentState = getState();
    const formDataAvailable = currentState.item.formDataAvailable;
    const part = formDataAvailable.find((part) => part.id === id);
    if (!part) {
      return;
    }

    const { originalTargetPrice, expectedLeadTime, price } = part;
    if (
      (!isEmptyValue(originalTargetPrice) || !isEmptyValue(expectedLeadTime)) &&
      !isEmptyValue(price)
    ) {
      const updatedPart = makePpeItemRfq(part);
      dispatch(
        updateCadPartIfExists({
          ...updatedPart,
        })
      );
      return;
    }

    if (
      isEmptyValue(originalTargetPrice) &&
      isEmptyValue(expectedLeadTime) &&
      isEmptyValue(price) &&
      !isEmptyValue(part.initialPrice)
    ) {
      const updatedPart = revertRfqToPpeItem(part);
      dispatch(
        updateCadPartIfExists({
          ...updatedPart,
        })
      );
    }
  };

export const updateTargetUnitPriceForPart =
  ({ id, originalTargetPrice }) =>
  (dispatch, getState) => {
    const currentState = getState();
    const formDataAvailable = currentState.item.formDataAvailable;
    const part = formDataAvailable.find((part) => part.id === id);
    if (!part) {
      return;
    }

    const exchangeRate = getExchangeRateSelector(currentState);
    const currency = getUserCurrencySelector(currentState);
    const targetPrice = originalTargetPrice
      ? currency !== CURRENCY.SGD
        ? originalTargetPrice / exchangeRate
        : originalTargetPrice
      : '';

    dispatch(
      updateCadPartIfExists({
        id: part.id,
        originalTargetPrice,
        targetPrice,
      })
    );

    dispatch(convertingBetweenPpeAndRfqIfNeeded(part.id));
  };

export const updateExpectedLeadTimeForPart =
  ({ id, expectedLeadTime }) =>
  (dispatch, getState) => {
    const formDataAvailable = getState().item.formDataAvailable;
    const part = formDataAvailable.find((part) => part.id === id);
    if (!part) {
      return;
    }

    dispatch(
      updateCadPartIfExists({
        id: part.id,
        expectedLeadTime,
      })
    );

    dispatch(convertingBetweenPpeAndRfqIfNeeded(part.id));
  };

export const addTechnicalDrawing =
  (id, uploadedUrls) => async (dispatch, getState) => {
    const formDataAvailable = getState().item.formDataAvailable;
    const part = formDataAvailable.find((part) => part.id === id);
    if (!part) {
      return;
    }

    const { cadPart = [] } = part ?? {};

    let newCadPart = {
      id,
      cadPart: [...cadPart, ...uploadedUrls],
    };

    dispatch(updateCadPartIfExists(newCadPart));

    return newCadPart;
  };

export const addTechnicalDrawingPartsLibrary =
  (project, part, pdfUrl) => async (dispatch, getState) => {
    const user = getState().auth.user;
    const featureFlags = await getFeatureFlags();
    if (
      !featureFlags?.config?.[FE_FEATURE_FLAGS_CONFIGURATION.GLOBAL_TDE_SWITCH]
    ) {
      return;
    }
    if (user?.tdeType !== TDE_TYPE.HAS_TDE) {
      return;
    }
    dispatch(
      updatePartsLibraryItemData(
        project.projectLibraryID,
        {
          itemLibraryID: part.itemLibraryID,
          id: part.id,
          version: part.version,
        },
        { tdeStatus: 'loading' }
      )
    );
    const newCadPart = await extractTdeForItem({
      part,
      user,
      pdfUrl,
    });
    dispatch(
      updatePartsLibraryItemData(
        project.projectLibraryID,
        {
          itemLibraryID: part.itemLibraryID,
          id: part.id,
          version: part.version,
        },
        { tdeStatus: newCadPart.tdeStatus }
      )
    );
    // Dispatch actions
    return newCadPart;
  };

// this is used to check if cad file has changed then rerun the image generation
const GEN_IMAGE_MAPPING = {};
export const generateImageForItem = (id) => async (dispatch, getState) => {
  const stateProxy = new Proxy(getState(), reduxStateProxy);
  const part = stateProxy.getPartUploadByID(id);
  if (!part) {
    return;
  }

  const s3ObjectUrl = getFileToGenerate2DImage(part.cadPart);

  if (!s3ObjectUrl) {
    return; // when the s3ObjectUrl is null, we stop generating the image
  }

  let { imageStatus, genImageAt } = part;

  if (
    !isEmptyValue(genImageAt) &&
    imageStatus === 'loading' &&
    GEN_IMAGE_MAPPING[id] === s3ObjectUrl
  ) {
    // if the image is already being generated, we stop the generation
    return;
  }

  genImageAt = new Date();
  GEN_IMAGE_MAPPING[id] = s3ObjectUrl;

  dispatch(
    updateCadPartIfExists({
      id,
      imageStatus: 'loading',
      genImageAt,
    })
  );

  let cadPart;

  convert2DImage({ file_url: s3ObjectUrl }, { timeout: 60_000 }) // timeout 60 seconds
    .then((response) => {
      let twoDImageUrl;
      let errorMessage;
      if (isEmptyValue(response.error)) {
        twoDImageUrl = response['s3_file_url'];
      } else {
        errorMessage = response.error?.message;
      }
      cadPart = {
        id,
        [TWO_D_IMAGE_URLS_KEY]: [twoDImageUrl],
        imageError: errorMessage,
        imageStatus: 'success',
      };
      dispatch(updateCadPartIfExists(cadPart));
    })
    .catch(() => {
      cadPart = {
        id,
        [TWO_D_IMAGE_URLS_KEY]: null,
        imageError: 'Image converted failed',
        imageConvertingError: 'Image converted failed',
        imageStatus: 'failed',
      };
      dispatch(addOrUpdateCadPart(cadPart));
    });
};

export const generateImageAndGetPriceAction =
  (id) => async (dispatch, getState) => {
    let stateProxy = new Proxy(getState(), reduxStateProxy);
    let part = stateProxy.getPartUploadByID(id);
    if (!part) {
      return;
    }

    dispatch(generateImageForItem(id));

    stateProxy = new Proxy(getState(), reduxStateProxy);
    part = stateProxy.getPartUploadByID(id);

    /**
     * wait max for 5 seconds to get the 2D image
     * if it's taking longer to generate the image then
     * calling ppe price api without the image
     */
    const WAITING_THRESHOLD_MS = 5_000; // 5 seconds in milliseconds

    const { genImageAt } = part;
    const current = new Date().getTime();
    if (genImageAt.getTime() + WAITING_THRESHOLD_MS < current) {
      dispatch(getPpePriceForCadPart(id));
      return;
    }

    let stopWaiting = false;
    const timer = setTimeout(
      () => {
        dispatch(getPpePriceForCadPart(id));

        // stop waiting for the below code
        stopWaiting = true;
      },
      WAITING_THRESHOLD_MS - (current - genImageAt.getTime())
    );

    // wait for the image to be generated then calling ppe price
    const SLEEP_INTERVAL = 0.5; // 500 milliseconds
    for (let index = 0; index < 10 / SLEEP_INTERVAL; index++) {
      // total 10 seconds
      if (stopWaiting) {
        break;
      }

      await sleep(SLEEP_INTERVAL);

      const stateProxy = new Proxy(getState(), reduxStateProxy);
      const part = stateProxy.getPartUploadByID(id);
      if (!part) {
        break;
      }

      // if the image is still loading then continue waiting
      if (part.imageStatus === 'loading') {
        continue;
      }

      if (timer) {
        clearTimeout(timer);
      }

      dispatch(getPpePriceForCadPart(id));

      break;
    }
  };

export const extractTdeForItemAndGetPrice =
  (id, isGetPpePrice = GET_PPE_PRICE.YES) =>
  async (dispatch, getState) => {
    const user = getState().auth?.user;
    const formDataAvailable = getState().item.formDataAvailable;
    const part = formDataAvailable.find((part) => part.id === id);

    if (!part) {
      return;
    }

    const partProxy = new Proxy(part, partUploadProxy);

    if (partProxy.hasPdfUploaded()) {
      dispatch(
        updateCadPartIfExists({
          id,
          tdeStatus: 'loading',
        })
      );

      const newCadPart = await extractTdeForItem({
        part,
        user,
      });
      // Dispatch actions
      dispatch(
        updateCadPartIfExists({
          id,
          tdeStatus: 'success',
          ...newCadPart,
        })
      );
    }

    if (isGetPpePrice && !partProxy.isRfqConverted()) {
      // retrieve the new state because it's updated and change the existing item state
      const formDataAvailable = getState().item.formDataAvailable;
      dispatch(generateImageAndGetPriceAction(id));
      dispatch(savePartSummaryDetails(formDataAvailable));
    } else if (part.ppePricingStatus === 'loading') {
      dispatch(
        updateCadPartIfExists({
          id,
          ppePricingStatus: 'success',
        })
      );
    }
  };

export const extractTdeForItemWithoutGettingPrice =
  (id) => async (dispatch) => {
    dispatch(extractTdeForItemAndGetPrice(id, GET_PPE_PRICE.NO));
  };

/**
 * this will not call ppe price api
 *
 * @param {*} id
 * @param {*} uploadedUrls
 * @returns
 */
export const addTechnicalDrawingAndExtractTdeOnly =
  (id, uploadedUrls) => async (dispatch) => {
    dispatch(addTechnicalDrawing(id, uploadedUrls));
    dispatch(extractTdeForItemWithoutGettingPrice(id));
  };

export const addTechnicalDrawingAndGetPrice =
  (id, uploadedUrls) => async (dispatch) => {
    dispatch(addTechnicalDrawing(id, uploadedUrls));
    dispatch(extractTdeForItemAndGetPrice(id));
  };

export const removeTechnicalDrawing = (id, fileStr) => (dispatch, getState) => {
  const formDataAvailable = getState().item.formDataAvailable;
  const part = formDataAvailable.find((part) => part.id === id);

  if (!part) {
    return;
  }

  const hasSpecialThreadsPreviously = !isEmptyValue(part.specialThreads);
  const cadPartFiles = [...part.cadPart.filter((file) => file != fileStr)];
  dispatch(
    updateCadPartIfExists({
      id,
      cadPart: cadPartFiles,
      tdeStatus: null,
      generatedFields: [],
      specialThreads: null,
    })
  );

  const partProxy = new Proxy(part, partUploadProxy);
  if (partProxy.isPpeItem() || hasSpecialThreadsPreviously) {
    dispatch(generateImageAndGetPriceAction(id));
  }
};

export const clearErrorTdeStatus = () => async (dispatch, getState) => {
  const formDataAvailable = getState().item.formDataAvailable;
  const copyItemState = [...formDataAvailable];
  copyItemState.forEach((item) => {
    if (item.tdeStatus === 'error') {
      item.tdeStatus = null;
    }
  });
  dispatch(savePartSummaryDetails(copyItemState));
  return copyItemState;
};

export const generateDefectAction = (id) => async (dispatch, getState) => {
  // wait for the image and ppe price to be completed then calling dfm defects
  const SLEEP_INTERVAL = 0.5; // 500 milliseconds
  for (let index = 0; index < (60 * 5) / SLEEP_INTERVAL; index++) {
    // total 5 minutes
    const stateProxy = new Proxy(getState(), reduxStateProxy);
    const part = stateProxy.getPartUploadByID(id);
    if (!part) {
      break;
    }

    // if the image and ppe price are still loading then continue waiting
    if (part.imageStatus === 'loading' || part.ppePricingStatus === 'loading') {
      await sleep(SLEEP_INTERVAL);
      continue;
    }

    // call defect api
    dispatch(getDefectForCadPart(id));
    break;
  }
};

export const replaceCadFile = (id, file) => async (dispatch, getState) => {
  const formDataAvailable = getState().item.formDataAvailable;
  const part = formDataAvailable.find((part) => {
    return part.id === id;
  });

  if (!part) {
    return;
  }

  dispatch(
    updateCadPartIfExists({
      id,
      uploadStatus: 'loading',
      imageStatus: 'loading',
    })
  );

  const { s3ObjectUrl, fileSize } = await uploadCadFileToS3(file);

  const cadFileArr = part.cadPart.filter(
    (link) => !toLowerCaseString(link).endsWith('.pdf')
  );
  let remainFiles = !isEmptyValue(cadFileArr)
    ? part.cadPart.filter((link) => toLowerCaseString(link).endsWith('.pdf'))
    : part.cadPart.slice(1);

  const cadPartParams = {
    id,
    fileSize,
    s3ObjectUrl,
    cadPart: [s3ObjectUrl, ...remainFiles],
    itemLibraryID: null,
    isPartLibrary: false,
    uploadStatus: 'success',
    imageStatus: 'loading',
    ppePricingStatus: 'loading',
  };
  dispatch(updateCadPartIfExists(cadPartParams));
  dispatch(generateImageAndGetPriceAction(id));
  dispatch(generateDefectAction(id));
};

export const generateImageAndGetPriceExcludePartHasPdf =
  (id) => async (dispatch, getState) => {
    const stateProxy = new Proxy(getState(), reduxStateProxy);
    const part = stateProxy.getPartUploadByID(id);
    if (!part) {
      return;
    }

    const partProxy = new Proxy(part, partUploadProxy);
    if (partProxy.hasPdfUploaded()) {
      // only generate image
      dispatch(generateImageForItem(id));
      return;
    }

    dispatch(generateImageAndGetPriceAction(id));
  };

/**
 * @deprecated
 * @param {*} id
 * @returns
 */
// eslint-disable-next-line no-unused-vars
const generateImageAndGetPriceActionOld =
  (id) => async (dispatch, getState) => {
    const formDataAvailable = getState().item.formDataAvailable;
    const part = formDataAvailable.find((part) => part.id === id);
    if (!part) {
      return;
    }

    const body = {
      file_url: part.s3ObjectUrl,
    };
    dfmExtractDimensionsApi(body);

    let cadPart;
    const s3ObjectUrl = getFileToGenerate2DImage(part.cadPart);
    if (isEmptyValue(s3ObjectUrl)) {
      cadPart = {
        id,
        imageStatus: 'success',
      };
      dispatch(updateCadPartIfExists(cadPart));
      dispatch(getPpePriceForCadPart(id));
      return;
    }

    dispatch(
      updateCadPartIfExists({
        id,
        imageStatus: 'loading',
      })
    );

    /**
     * wait for 5 seconds to get the 2D image
     * if it's taking longer to generate the image then
     * calling ppe price api without the image
     */
    const WAITING_THRESHOLD_MS = 5000; // 5 seconds in milliseconds
    const startTimer = new Date();

    const timer = setTimeout(() => {
      dispatch(getPpePriceForCadPart(id));
    }, WAITING_THRESHOLD_MS);

    convert2DImage({ file_url: s3ObjectUrl })
      .then((response) => {
        let twoDImageUrl;
        let errorMessage;
        if (isEmptyValue(response.error)) {
          twoDImageUrl = response['s3_file_url'];
        } else {
          errorMessage = response.error?.message;
        }
        cadPart = {
          id,
          [TWO_D_IMAGE_URLS_KEY]: [twoDImageUrl],
          imageError: errorMessage,
          imageStatus: 'success',
        };
        dispatch(updateCadPartIfExists(cadPart));
        const endTimer = new Date();
        const diffInMilliseconds = dayjs(endTimer).diff(dayjs(startTimer));
        if (diffInMilliseconds <= WAITING_THRESHOLD_MS) {
          if (timer) {
            clearTimeout(timer);
          }
          dispatch(getPpePriceForCadPart(id));
        }
      })
      .catch(() => {
        cadPart = {
          id,
          [TWO_D_IMAGE_URLS_KEY]: null,
          imageError: 'Image converted failed',
          imageConvertingError: 'Image converted failed',
          imageStatus: 'failed',
        };
        dispatch(addOrUpdateCadPart(cadPart));
        const endTimer = new Date();
        const diffInMilliseconds = dayjs(endTimer).diff(dayjs(startTimer));
        if (diffInMilliseconds <= WAITING_THRESHOLD_MS) {
          if (timer) {
            clearTimeout(timer);
          }
          dispatch(getPpePriceForCadPart(id));
        }
      });
  };

export const CACHE_PARTS_UPLOAD_FORM_KEY = 'cachePartsUploadForm';
export const CACHE_PARTS_UPLOAD_TIME_KEY = 'cachePartsUploadFormTime';
export const MAX_CACHE_TIME = 604800000; // 1 week in ms

export const cachePartsUploadFormAction = () => async (dispatch, getState) => {
  const formDataAvailable = getState().item.formDataAvailable;
  if (!isEmptyValue(formDataAvailable)) {
    setCache(CACHE_PARTS_UPLOAD_FORM_KEY, JSON.stringify(formDataAvailable));
    setCache(CACHE_PARTS_UPLOAD_TIME_KEY, new Date().toISOString());
  }
};

export const restoreLastPartsAction = () => async (dispatch) => {
  let cachePartsUploadForm = JSON.parse(getCache(CACHE_PARTS_UPLOAD_FORM_KEY));
  cachePartsUploadForm = cachePartsUploadForm.filter(
    (p) => p.uploadStatus === 'success'
  );
  const ongoingImageGenerationParts = cachePartsUploadForm
    .filter((p) => p.imageStatus === 'loading')
    .map((p) => p.id);
  const ongoingPpePriceParts = cachePartsUploadForm
    .filter(
      (p) =>
        p.ppePricingStatus === 'loading' &&
        !ongoingImageGenerationParts.includes(p.id)
    )
    .map((p) => p.id);
  if (!isEmptyValue(cachePartsUploadForm)) {
    dispatch(restoreParts(cachePartsUploadForm));
    if (!isEmptyValue(ongoingImageGenerationParts)) {
      for (const id of ongoingImageGenerationParts) {
        dispatch(generateImageAndGetPriceAction(id));
      }
    }
    if (!isEmptyValue(ongoingPpePriceParts)) {
      for (const id of ongoingPpePriceParts) {
        // if there is any ongoing ppe price from the last cache then need to trigger api request again
        dispatch(getPpePriceForCadPart(id));
      }
    }
    removeCache(CACHE_PARTS_UPLOAD_FORM_KEY);
  }
};

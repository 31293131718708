import { CsvBuilder } from 'filefy';
import { isEmpty } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Tooltip } from '@material-ui/core';
import {
  ContactPhoneRounded as ClarifyingIcon,
  AssistantRounded as SubmittedRQIcon,
} from '@material-ui/icons';

import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import FilterBar from '../components/filters/FilterBar';
import { FtrTypography } from '../components/ftr-components';
import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import {
  NonPPEIcon,
  PPEIcon,
  RepeatOrderIcon,
  SimilarOrderIcon,
} from '../components/icons/ItemIcon';
import ImageWith3DViewer from '../components/images/ImageWith3DViewer';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import { renderFlag } from '../components/images/CountryFlag';

import withVerifyPartPopupHOC from '../hocs/withVerifyPartPopupHOC';

import { getVerifyingItems } from '../apis/itemApi';

import { generateCurrentCustomDateTimeString } from '../util';
import { isEmptyValue } from '../utils/commonUtils';
import { transformCsvExportDataGrid } from '../utils/csvExportUtils';
import { dateTzSingapore } from '../utils/dateTimeUtils';
import {
  getIsRepeatOrder,
  getIsSimilarOrder,
  getItemOwner,
  isCustomerLatestUpload,
  getRQAdvisoryMessages,
  isPpeItem,
} from '../utils/itemUtils';
import { truncateDecimals } from '../utils/numberUtils';
import { isSuperAdminRole } from '../utils/roleUtils';

import { ROLE_TYPES } from '../constants';
import { ITEM_VERIFY_STATUS_FILTER_ARRAY } from '../constants/itemStatus';

import { colors } from '../palette';
import WarningIcon from '../components/icons/WarningIcon';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '2.5rem',
    paddingTop: '1.5rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  complexityEasy: {
    color: colors.green030,
  },
  complexityMedium: {
    color: colors.warningYellow,
    fontWeight: 'bold',
  },
  complexityComplex: {
    color: colors.red030,
    fontWeight: 'bold',
  },
}));

function VerifyPartsDataGrid(props) {
  const classes = useStyles();

  const { role, openVerifyPartPopup } = props;

  const {
    status: loadingStatus,
    data: verifyingItems,
    refetch,
  } = useQuery('getVerifyingItems', getVerifyingItems);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
      status: 'all',
    }
  );

  const [filteredTableData, setFilteredTableData] = useState([]);

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });

    let filteredRows = verifyingItems;

    if (tableQueryParams.status === 'ppe') {
      filteredRows = filteredRows.filter(
        (row) => row.acceptQuoteType === 'ppe'
      );
    }

    if (tableQueryParams.status === 'non-ppe') {
      filteredRows = filteredRows.filter(
        (row) => row.acceptQuoteType !== 'ppe'
      );
    }

    if (!tableQueryParams.search) {
      setFilteredTableData(filteredRows);
      updateTableQueryParams({
        loading: false,
      });
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        filteredRows = filteredRows.filter((row) => {
          const searchFields = [
            row.itemID,
            row.projectID,
            row.name?.toLowerCase(),
            row.technology?.toLowerCase(),
            row.buyerName?.toLowerCase() + '(' + row.userID + ')',
          ];
          return searchFields
            .map((field) =>
              String(field).includes(tableQueryParams.search.toLowerCase())
            )
            .some(Boolean);
        });
        setFilteredTableData(filteredRows);
        updateTableQueryParams({
          loading: false,
          totalCount: filteredRows.length,
        });
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
    tableQueryParams.status,
    verifyingItems,
  ]);

  useEffect(() => {
    if (!verifyingItems) {
      return;
    }
    setFilteredTableData(verifyingItems);
    updateTableQueryParams({
      totalCount: verifyingItems.length,
    });
  }, [verifyingItems]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const complexityComparator = (c1, c2) => {
    // Convert 'N/A' to 0, otherwise use the original value
    const newC1 = c1 === 'N/A' ? 0 : +c1;
    const newC2 = c2 === 'N/A' ? 0 : +c2;

    return newC1 - newC2;
  };

  const columns = [
    {
      headerName: 'Item ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        const showWarningReUploadCustomer =
          rowData.customerCadFileUploadInfo?.some((info) =>
            isCustomerLatestUpload(rowData, info.s3ObjectUrl)
          );
        const customerActionRequired = !!rowData.clarifying;
        const submittedForRocketQuote = !isEmptyValue(
          rowData.submittedForRocketQuoteAt
        );

        return (
          <FlexColumn
            style={{
              gap: '0.2rem',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <div>{rowData.itemID}</div>
            <FlexRow style={{ gap: '0.2rem' }}>
              {showWarningReUploadCustomer && (
                <WarningIcon toolTipText='Customer has changed the design!' />
              )}
              {customerActionRequired && (
                <Tooltip
                  title='Customer action requested'
                  placement='top'
                  arrow
                >
                  <ClarifyingIcon
                    style={{
                      fontSize: '1.5rem',
                      color: colors.warningYellow,
                    }}
                  />
                </Tooltip>
              )}
              {submittedForRocketQuote && (
                <Tooltip
                  title='Submitted for rocket quote'
                  placement='top'
                  arrow
                >
                  <SubmittedRQIcon
                    style={{
                      fontSize: '1.5rem',
                      color: colors.warningYellow,
                    }}
                  />
                </Tooltip>
              )}
            </FlexRow>
          </FlexColumn>
        );
      },
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: (params) => {
        const rowData = params.row;
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <div key={url} id={rowData.itemID}>
            <ImageWith3DViewer
              key={url}
              itemID={rowData.itemID}
              twoDImageUrl={url}
              cadFile={rowData.cadFile || rowData.originalFiles}
              isGenerateItemImage
              width={80}
              height={80}
              borderRadius={0}
              noBorder={false}
              textRenderStyle={{ fontSize: 10 }}
            />
          </div>
        );
      },
      valueGetter: (params) => params.row.imageFile || params.row.twoDImageUrl,
      sortable: false,
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: (params) => (
        <Button
          className={classes.linkButton}
          onClick={(e) => e.stopPropagation()}
          href={`/manage-projects/${params.row.projectID}`}
        >
          {params.row.projectID}
        </Button>
      ),
      minWidth: 115,
    },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: (params) => <DataGridWrapTextCell text={params.row.name} />,
      flex: 1,
      minWidth: 170,
      sortable: false,
    },
    {
      headerName: 'Owner (ID)',
      field: 'OwnerID',
      minWidth: 160,
      renderCell: ({ row }) => {
        const { ownerID, ownerName, ownerEmail, ownerCompany, ownerCountry } =
          getItemOwner(row);

        return (
          <FlexColumn
            style={{
              gap: 0,
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <div>
              {ownerName} ({ownerEmail || ownerID})
            </div>
            <FtrTypography
              type='subHeading'
              fontSize='12'
              style={{ marginBottom: '4px' }}
            >
              {ownerCompany}
            </FtrTypography>
            {renderFlag(ownerCountry)}
          </FlexColumn>
        );
      },
      valueGetter: (params) => {
        const {
          buyerName,
          userID: ownerID,
          companyName,
          cCompanyName,
          owners,
        } = params.row;

        let ownerName, ownerCompany, ownerEmail;

        if (isEmpty(owners)) {
          ownerName = buyerName;
          ownerCompany = cCompanyName || companyName;
        } else {
          const owner = owners[0];
          ownerName = owner.ownerName;
          ownerEmail = owner.ownerEmail;
        }

        return `${ownerName} (${ownerEmail || ownerID}${
          ownerCompany ? ` | ${ownerCompany}` : ''
        })`;
      },
    },
    {
      headerName: 'Complexity',
      field: 'complexity',
      renderCell: ({ row: rowData }) => {
        const complexity = rowData?.instantQuotesLogMeta?.complexity;
        const complexityClass = (complexity) => {
          if (isEmptyValue(complexity)) return null;

          if (complexity >= 3.5) return classes.complexityComplex;
          if (complexity >= 2.5) return classes.complexityMedium;

          return classes.complexityEasy;
        };

        return (
          <div className={complexityClass(complexity)}>
            {complexity ? `${truncateDecimals(complexity, 2)} / 5.00` : 'N/A'}
          </div>
        );
      },
      width: 150,
      valueGetter: (params) =>
        params.row?.meta?.complexity
          ? truncateDecimals(params.row.meta?.complexity, 2)
          : 'N/A',
      sortComparator: complexityComparator,
    },
    {
      headerName: 'PPE',
      field: 'ppePart',
      valueGetter: (params) =>
        params.row.acceptQuoteType === 'ppe' ? 'Yes' : 'No',
      hide: true,
    },
    {
      title: 'Item Type',
      headerName: 'Item Type',
      field: 'itemType',
      renderCell: (params) => {
        const rowData = params.row;
        const advisoryText = () => {
          const messages = getRQAdvisoryMessages(rowData);

          if (isEmptyValue(messages)) {
            return '';
          }

          return (
            <ul style={{ paddingLeft: '1rem' }}>
              {messages.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          );
        };
        return (
          <FlexRow style={{ gap: '0.2rem' }}>
            {isPpeItem(rowData) || rowData.rocketQuote === 1 ? (
              <PPEIcon />
            ) : (
              <NonPPEIcon tooltipText={advisoryText()} />
            )}
            {getIsRepeatOrder(rowData.instantQuotesLogMeta) && (
              <RepeatOrderIcon />
            )}
            {getIsSimilarOrder(rowData.instantQuotesLogMeta) && (
              <SimilarOrderIcon />
            )}
          </FlexRow>
        );
      },
      width: 145,
      sortable: false,
    },
    {
      headerName: 'Technology',
      field: 'technology',
      minWidth: 150,
      sortable: false,
    },
    {
      headerName: 'Posted at',
      field: 'datePosted',
      minWidth: 150,
      valueGetter: (params) => dateTzSingapore(params.row.datePosted),
    },
  ];

  const handleRefetch = () => {
    setTimeout(() => refetch(), 1000);
  };

  const handleRowClick = (rowData) => {
    openVerifyPartPopup({
      itemID: rowData.id,
      refetch: handleRefetch,
    });
  };

  const handleDownloadCsv = () => {
    const fileName = `Verify Items ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredTableData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  const handleFilterChange = (event) => {
    const { value: status } = event.target;

    updateTableQueryParams({
      status,
    });
  };

  return [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER].includes(role) ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <h1>Verify Items</h1>
      <div style={{ marginBottom: '1rem' }}>
        <FilterBar
          defaultValue={ITEM_VERIFY_STATUS_FILTER_ARRAY[0].value}
          optionList={ITEM_VERIFY_STATUS_FILTER_ARRAY}
          onFilterChange={handleFilterChange}
        />
      </div>
      <DataGrid
        autoHeight
        rowHeight={105}
        headerHeight={90}
        columns={columns}
        rows={isEmpty(filteredTableData) ? [] : filteredTableData}
        getRowId={(row) => row.itemID}
        onRowClick={handleRowClick}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading || loadingStatus !== 'success'}
        disableSelectionOnClick
        disableRowSelectionOnClick
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => {
            return (
              <GridDataPagination
                pageCount={Math.ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            );
          },
        }}
        localeText={{
          toolbarExport: 'Export CSV',
        }}
        disableColumnMenu
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default compose(
  withConnect,
  withVerifyPartPopupHOC
)(VerifyPartsDataGrid);

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { get, isEmpty, uniq } from 'lodash';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Info,
  Send as SendIcon,
} from '@material-ui/icons';

import { DatePicker } from '@material-ui/pickers';

import * as formActions from '../actions/projectBulkQuotationsForm';

import ConfirmJobTag from '../components/labels/ConfirmJobTag';
import DownloadAllFilesButton from '../components/buttons/DownloadAllFilesButton';
import ErrorLabel from '../components/labels/ErrorLabel';
import FilesUploadButton from '../components/FilesUploadButton';
import GreenButton from '../components/buttons/GreenButton';
import InfoIcon from '../components/icons/InfoIcon';
import InfoTitle from '../components/labels/InfoTitle';
import ItalicNote from '../components/texts/italicNote';
import KeyValueInfoDisplay from '../components/labels/KeyValueInfoDisplay';
import PpeQuoteNoticeLabel from '../components/labels/PpeQuoteNoticeLabel';
import ProjectBulkQuotationsForm from '../components/forms/ProjectBulkQuotationsForm';
import ProjectImageCollection from '../components/images/ProjectImageCollection';
import QcReportsDisplay from '../components/info/QcReportsDisplay';
import SupplierLeadTimeFeedback from '../components/SupplierLeadTimeFeedback';
import SupplierSubmitQuotationPopup from '../components/popups/SupplierSubmitQuotationPopup';
import SuppliersByTechDropDown from '../components/dropdowns/SuppliersByTechDropDown';
import ViewAsSupplierPopupButton from '../components/ViewAsSupplierPopupButton';
import YesNoPopup from '../components/popups/YesNoPopup';
import {
  FlexColumn,
  FlexRow,
  FlexRowCenter,
  FlexRowSpaceBetween,
} from '../components/layouts/FlexLayouts';
import { FtrBanner, FtrTypography } from '../components/ftr-components';
import { NotInterestedJob } from '../components/NotInterestedJob';
import { PageTitle } from '../components/PageTitle';
import { WatchingJob } from '../components/WatchingJob';

import withViewAsSupplierHOC from '../hocs/withViewAsSupplierHOC';

import { getReadyForQuoteItemsGroupByProjectID } from '../apis/itemApi';
import {
  deleteProjectBomFile,
  getProjectBomFiles,
  updateProjectBomFiles,
} from '../apis/projectApi';
import { getAllSuppliersGroupByTech } from '../apis/userApi';

import { convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import { downloadS3File } from '../utils/fileUtils';
import {
  formatDeliveryDate,
  getDefaultExpiredDate,
} from '../utils/dateTimeUtils';
import { getLatestCollectionDateStr, getMaxLeadTime } from '../utils/itemUtils';
import { hasReviewPermission } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { validateBulkQuotations } from '../utils/validators/formValidators';

import { useQueryParams } from '../hooks/useQueryParamsHook';

import { getProjectBulkQuotationsFormSelector } from '../selectors/projectBulkQuotationsFormSelector';

import { notifyError, notifySuccess } from '../services/notificationService';
import { getBomS3Key, uploadFileToS3 } from '../services/s3Service';

import { ROLE_TYPES } from '../constants';
import {
  ITEM_QUOTE_TYPE,
  MAX_BOM_FILES_UPLOAD,
  PROJECT_TYPE,
  QC_NO_QC_REPORT,
} from '../constants/projectConstants';
import { WATCHING_JOB_STATUS } from '../constants/watchingJob';

import { colors } from '../palette';

import ProjectQuoteForSupplierContext from '../context/ProjectQuoteForSupplierContext';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '1.6rem',
    paddingTop: '1.2rem',
  },
  quoteSubmitLabelWrapper: {
    margin: 20,
  },
  quoteNumberBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'white',
      color: 'green',
      border: '1px solid green',
    },
  },
}));

function ProjectQuoteForSupplier(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();

  const formData = useSelector(getProjectBulkQuotationsFormSelector);
  const projectBulkQuotationsForm = useSelector(
    getProjectBulkQuotationsFormSelector
  );

  const { user, match, currency, exchangeRate } = props;

  const { projectID } = match.params;
  const { projectType } = formData;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { data: allSuppliers } = useQuery(['allSuppliers', user.role], () => {
    if (!hasReviewPermission(user.role)) {
      return [];
    }
    return getAllSuppliersGroupByTech();
  });

  const [dataLoading, setDataLoading] = useState(false);
  const [technology, setTechnology] = useState(queryParams.get('technology'));
  const [viewQuote] = useState(queryParams.get('viewQuote'));
  const [curatedForYou] = useState(queryParams.get('curatedForYou'));
  const [project, setProject] = useState(null);
  const [leadTime, setLeadTime] = useState(null);
  const [collectionDate, setCollectionDate] = useState(null);
  const [leadTimeError, setLeadTimeError] = useState(null);
  const [dateOfExpiry, setDateOfExpiry] = useState(getDefaultExpiredDate());
  const [partsTotal, setPartsTotal] = useState(
    formData.partsTotal || 'S$ 0.00'
  );
  const [items, setItems] = useState([]);
  const [fileUrlList, setFileUrlList] = useState([]);
  const [acknowledge, setAcknowledgement] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [bomFiles, setBomFiles] = useState([]);
  const [openDeleteBomFilePopup, setOpenDeleteBomFilePopup] = useState(false);
  const [deletingBomFile, setDeletingBoomFile] = useState(null);
  const [filesUploadRemains, setFilesUploadRemains] = useState(
    MAX_BOM_FILES_UPLOAD - bomFiles.length
  );
  const [showFileUploadButton, setShowFileUploadButton] = useState(
    filesUploadRemains > 0
  );
  const [qcReports, setQcReports] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [allItemsQuoted, setAllItemsQuoted] = useState(false);
  const [acceptJobDialog, setAcceptJobDialog] = useState(false);
  const [showSummaryPanel, setShowSummaryPanel] = useState(false);
  const [quoteSelectedItems, setQuoteSelectedItems] = useState([]);
  const [isWatching, setIsWatching] = useState(false);
  const [isLoadingSubmitted, setIsLoadingSubmitted] = useState(false);

  const summaryPanelRef = useRef(null);
  const itemIDs = items.map((item) => item.itemID);

  const selectedQuotationIDs = useMemo(() => {
    if (isEmptyValue(collectionDate)) {
      return null;
    }

    const selectedItemsForQuote = quoteSelectedItems.filter(
      (item) => item.ignoreItem !== true
    );

    if (isEmptyValue(selectedItemsForQuote)) {
      return null;
    }

    if (
      selectedItemsForQuote.some(
        (item) => item.itemQuoteType !== ITEM_QUOTE_TYPE.PPE
      )
    ) {
      return null;
    }

    const quotationIDs = selectedItemsForQuote.map(
      (item) => item.itemQuotationID
    );
    return quotationIDs;
  }, [quoteSelectedItems, collectionDate]);

  const loadData = () => {
    const params = {
      technology,
      viewQuote,
      supplierID: user.userID,
      curatedForYou,
    };
    setDataLoading(true);
    getReadyForQuoteItemsGroupByProjectID({ projectID, params })
      .then((projectResponse) => {
        const { project } = projectResponse;
        const techs = project.items.reduce((acc, item) => {
          acc.add(item.technology);
          return acc;
        }, new Set());
        // if project has more than one technology, redirect to browse page
        if (techs.size > 1) {
          history.push('/');
          return;
        }
        setProject(project);
        setItems(project.items);
        if (handleSummaryView().hasPPE) {
          const latestCollectionDate = getLatestCollectionDateStr(
            project.items
          );
          setCollectionDate(latestCollectionDate);
          const minLeadtime = getMaxLeadTime(project.items);
          setLeadTime(minLeadtime);
        }
        const isAllItemsQuoted = project.items.every(
          (item) => item.supplierQuoted
        );
        setAllItemsQuoted(isAllItemsQuoted);
        dispatch(formActions.initFormState(project, currency, exchangeRate));
      })
      .catch(() => {
        history.push('/');
        return;
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    const items = projectBulkQuotationsForm.items || [];
    const watchingJobs = uniq(items.map((item) => item.watchingJobStatus));
    setIsWatching(
      watchingJobs.length === 1 &&
        watchingJobs[0] === WATCHING_JOB_STATUS.ACTIVE
    );
  }, [projectBulkQuotationsForm]);

  useEffect(() => {
    setTechnology(queryParams.get('technology'));
  }, [queryParams]);

  useEffect(() => {
    dispatch(formActions.updateCurrency());
  }, [currency]);

  useEffect(() => {
    const fileRemains = MAX_BOM_FILES_UPLOAD - bomFiles.length;
    setFilesUploadRemains(fileRemains);
    setShowFileUploadButton(fileRemains > 0);
  }, [bomFiles]);

  useEffect(() => {
    if (!isEmpty(project)) {
      setQcReports(project.qcReports);
    }
  }, [project]);

  useEffect(() => {
    loadData();
  }, [projectID, user.userID, technology]);

  useEffect(() => {
    if (formData && project) {
      setPartsTotal(formData.partsTotal);
      const { items } = formData;
      if (handleSummaryView().hasPPE) {
        const latestCollectionDate = getLatestCollectionDateStr(
          items.filter(
            (item) => item.ignoreItem !== true && !item.supplierQuoted
          )
        );
        setCollectionDate(latestCollectionDate);
        const minLeadtime = getMaxLeadTime(
          items.filter(
            (item) => item.ignoreItem !== true && !item.supplierQuoted
          )
        );
        setLeadTime(minLeadtime);
      }
    }
  }, [formData, project, quoteSelectedItems]);

  useEffect(() => {
    if (projectID) {
      getProjectBomFiles({ projectID }).then(setBomFiles);
    }
  }, [projectID]);

  // update list all project files download when items or bom files changed
  useEffect(() => {
    let allProjectDownloadFileUrlsList = [];
    if (!isEmpty(items)) {
      const itemFilesList = items.reduce((acc, item) => {
        const urlList = item.cadFile.split(',').map((url) => ({
          url,
          subFolder: `part-${item.itemID}`,
        }));
        return [...acc, ...urlList];
      }, []);
      allProjectDownloadFileUrlsList = itemFilesList;
    }
    if (!isEmpty(bomFiles)) {
      const bomFilesList = bomFiles.reduce((acc, bomFile) => {
        acc.push({
          url: bomFile.s3ObjectUrl,
          subFolder: `boms`,
          fileName: bomFile.fileName,
        });
        return acc;
      }, []);
      allProjectDownloadFileUrlsList =
        allProjectDownloadFileUrlsList.concat(bomFilesList);
    }
    setFileUrlList(allProjectDownloadFileUrlsList);
  }, [items, bomFiles]);

  useEffect(() => {
    if (
      ![ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.REVIEWER].includes(
        user.role
      )
    ) {
      return;
    }
    if (!isEmpty(allSuppliers)) {
      const _supplierList = allSuppliers.map((category) => {
        return {
          ...category,
          value: category.value
            .filter(
              (supplier) =>
                !['Factorem Instant Quote', 'Factorem Rocket Quote'].includes(
                  supplier.name
                )
            )
            .map((supplier) => ({
              key: `${supplier.userID}`,
              text: `${supplier.name} <${supplier.email}>`,
            })),
        };
      });
      setSupplierList([
        {
          key: user.role,
          value: [
            {
              key: user.userID,
              text: user.name,
            },
          ],
        },
        ..._supplierList,
      ]);
      setAssignedTo(user.userID);
    }
  }, [allSuppliers]);

  useEffect(() => {
    const quoteSelectedItems = projectBulkQuotationsForm.items
      ? projectBulkQuotationsForm.items.filter(
          (item) => item.ignoreItem === false && !item.supplierQuoted
        )
      : [];
    setQuoteSelectedItems(quoteSelectedItems);
  }, [projectBulkQuotationsForm]);

  const handleBomFilesUpload = (files) => {
    if (files.length > filesUploadRemains) {
      notifyError(`You can only upload ${filesUploadRemains} files.`);
      return;
    }
    setFileUploading(true);
    Promise.all(
      Array.from(files).map(async (file) => {
        const data = await uploadFileToS3(file, getBomS3Key(file, projectID));
        const s3ObjectUrl = data.Location;
        return {
          fileName: file.name,
          s3ObjectUrl,
        };
      })
    )
      .then(async (boms) => {
        await updateProjectBomFiles({ projectID, boms });
        getProjectBomFiles({ projectID }).then(setBomFiles);
      })
      .then(() => {
        notifySuccess('BOM files uploaded successfully');
      })
      .catch(() => {
        notifyError('Error uploading BOM files');
      })
      .finally(() => setFileUploading(false));
  };

  const handleDeleteBom = () => {
    deleteProjectBomFile({ projectID, bom: deletingBomFile })
      .then(() => {
        notifySuccess(`File is deleted successfully`);
        getProjectBomFiles({ projectID }).then(setBomFiles);
      })
      .catch(() => notifyError('Error deleting BOM file'));
  };

  const handleAssignQuoteToChange = (value) => {
    setAssignedTo(value);
  };

  const onSubmitQuote = () => {
    const { items } = formData;
    const valid = validateBulkQuotations(items, qcReports);
    if (!isEmpty(valid)) {
      dispatch(formActions.formError(valid));
      notifyError(valid?.message || 'Missing required field(s)');
      return;
    }
    if (
      (isEmptyValue(leadTime) || Number(leadTime) <= 0) &&
      !handleSummaryView().hasPPE
    ) {
      setLeadTimeError(`Required`);
      notifyError('Lead time is empty');
      return;
    }
    setIsLoadingSubmitted(true);
    const toastId = toast('Submitting quotations...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    const supplierID = [
      ROLE_TYPES.ADMIN,
      ROLE_TYPES.SUPER_ADMIN,
      ROLE_TYPES.REVIEWER,
    ].includes(user.role)
      ? assignedTo
      : user.userID;
    const payload = {
      leadTime,
      dateOfExpiry,
      technology,
      supplierID,
    };
    // Change status item unwatch to watch
    dispatch(formActions.submitQuotes(payload))
      .then(() => {
        toast.update(toastId, {
          render: `Quotations are submitted successfully`,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        setLeadTime(null);
        setAcknowledgement(false);
        setDateOfExpiry(getDefaultExpiredDate());
        dispatch(formActions.initFormState(project, currency, exchangeRate));
        setAssignedTo(user.userID);
        loadData();
        setAssignedTo(user.userID);
        setAcceptJobDialog(false);
      })
      .catch((error) => {
        toast.update(toastId, {
          render: error.message,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setIsLoadingSubmitted(false);
      });
  };

  const handleUpdateAllWatchingStatus = () => {
    dispatch(
      formActions.updateAllWatchingJob({
        value: isWatching
          ? WATCHING_JOB_STATUS.INACTIVE
          : WATCHING_JOB_STATUS.ACTIVE,
      })
    );
  };

  /**
   * @typedef {Object} HandleSummaryView
   * @property {boolean} hasPPE
   * @property {boolean} isConfirm
   */
  /**
   * To Handle Summary View for lead time editable and Confirm Button.
   * Algorithm:
   * - Filter projectBulkQuotationsForm.items to quoteSelectedItems
   * - If it has hasPPE true
   * - If it has is hasManualItem isConfirm false
   * @return {HandleSummaryView}
   */
  function handleSummaryView() {
    const hasManualItem = quoteSelectedItems.some(
      (item) => item.itemQuoteType !== ITEM_QUOTE_TYPE.PPE
    );
    const hasPPE = quoteSelectedItems.some(
      (item) => item.itemQuoteType === ITEM_QUOTE_TYPE.PPE
    );

    return {
      hasPPE: hasPPE,
      isConfirm: !hasManualItem,
    };
  }

  if (isEmptyValue(items) && !dataLoading) {
    return (
      <div>
        <PageTitle title={`Project ${projectID}`} />
        <div style={{ margin: '1rem' }}>
          <FtrBanner type='info'>
            <FlexRow>
              <Info style={{ fill: colors.blue050 }} />
              <FtrTypography type='body' fontSize='14'>
                Project Not Available. Please explore other jobs via{' '}
                <Link to={{ pathname: `/` }}>Browse Parts.</Link>
              </FtrTypography>
            </FlexRow>
          </FtrBanner>
        </div>
      </div>
    );
  }

  const renderBomFilesList = () => {
    return (
      <div>
        {bomFiles.map((item) => (
          <FlexRowSpaceBetween
            style={{
              borderBottom: `1px solid ${colors.expansionBackgroundColor}`,
            }}
            key={item.fileName}
          >
            <FlexRow>
              <AttachFileIcon color='action' fontSize='small' />
              <Typography variant='body2'>
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadS3File(item.s3ObjectUrl);
                  }}
                >
                  {item.fileName}
                </Link>
              </Typography>
            </FlexRow>
            {[ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(user.role) && (
              <IconButton
                aria-label='delete'
                onClick={() => {
                  setOpenDeleteBomFilePopup(true);
                  setDeletingBoomFile(item);
                }}
                size='small'
              >
                <DeleteIcon color='primary' style={{ fontSize: '1.2rem' }} />
              </IconButton>
            )}
          </FlexRowSpaceBetween>
        ))}
      </div>
    );
  };

  const renderQcReports = () => {
    return (
      <FlexColumn
        style={{
          width: '100%',
          marginBottom: '0.3rem',
        }}
      >
        <Typography style={{ fontWeight: 'bold' }} variant='body1'>
          QC Requirement(s)
        </Typography>
        <FlexColumn
          style={{
            width: '100%',
            paddingLeft: '0.3rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderOverallProjectBudget = () => {
    const isAllSupplierTargetPriceFilled = project.items.every((item) => {
      return (
        !isEmptyValue(item.targetPriceSupplier) && item.targetPriceSupplier > 0
      );
    });
    if (handleSummaryView().hasPPE || !isAllSupplierTargetPriceFilled) {
      return null;
    }
    const overallProjectBudgetPrice = project.items.reduce((acc, item) => {
      return acc + item.targetPriceSupplier * item.quantity;
    }, 0);

    const { totalPriceStr: overallProjectBudgetPriceStr } =
      convertPriceWithQuantityToCurrency({
        totalPrice: overallProjectBudgetPrice,
        currency,
        exchangeRate,
      });
    return (
      <Grid item xs={6}>
        <InfoTitle title='Overall Project Budget' />
        <Typography variant='body2'>{overallProjectBudgetPriceStr}</Typography>
      </Grid>
    );
  };

  const renderProjectInfo = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div>
          <ProjectImageCollection items={items} />
        </div>
        <div
          style={{
            marginLeft: '0.5rem',
            flex: 1,
          }}
        >
          <FlexRow style={{ flexWrap: 'wrap' }}>
            <Typography variant='h5'>
              Project {projectID} ({items.length}{' '}
              {items.length > 1 ? 'Components' : 'Component'})
            </Typography>
            {projectType === PROJECT_TYPE.PPE && <ConfirmJobTag />}
            <ViewAsSupplierPopupButton
              showResetButton={!dataLoading}
              itemIDs={itemIDs}
            />
          </FlexRow>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <KeyValueInfoDisplay
                name='Technology'
                value={items[0] ? items[0].technology : null}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoTitle title='Design/CAD files' />
              <DownloadAllFilesButton
                buttonText='Download All Project Files'
                urlList={fileUrlList}
                folderName={`Factorem_Project_${projectID}`}
                trackingInfo={{
                  projectID,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FlexRow>
                <InfoTitle title='BOM Files' />
                &nbsp;
                {fileUploading && (
                  <CircularProgress
                    style={{
                      width: '1.2rem',
                      height: '1.2rem',
                    }}
                    color='primary'
                  />
                )}
              </FlexRow>
              <FlexColumn style={{ paddingRight: '0.5rem' }}>
                {bomFiles.length > 0 ? (
                  renderBomFilesList()
                ) : (
                  <Typography variant='body2'>No BOM files uploaded</Typography>
                )}
              </FlexColumn>
            </Grid>
            {showFileUploadButton &&
              [ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(
                user.role
              ) && (
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  item
                  xs={3}
                >
                  <FilesUploadButton
                    buttonText='Upload BOM Files'
                    handleUploadFiles={handleBomFilesUpload}
                  />
                  <ItalicNote
                    text={`${filesUploadRemains} ${
                      filesUploadRemains > 1 ? 'files' : 'file'
                    } remains`}
                  />
                </Grid>
              )}
            {!isEmpty(qcReports) &&
              qcReports &&
              qcReports.main !== QC_NO_QC_REPORT && (
                <Grid item xs={6}>
                  {renderQcReports()}
                </Grid>
              )}
            {renderOverallProjectBudget()}
            {project && project.expectedProjectDeadline && (
              <Grid item xs={6}>
                <InfoTitle title='Expected Project Deadline' />
                <Typography variant='body2'>
                  {formatDeliveryDate(project.expectedProjectDeadline)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <WatchingJob
                withText
                isWatching={isWatching}
                setIsWatching={setIsWatching}
                itemIDs={itemIDs}
                projectID={projectID}
                callbackSuccess={handleUpdateAllWatchingStatus}
              />
              <NotInterestedJob
                itemIDs={itemIDs}
                projectID={projectID}
                isWatching={isWatching}
                callbackSuccess={() => history.push('/')}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const renderPpeCollectionDate = () => {
    return (
      <FlexRowSpaceBetween>
        <Typography>Delivery Date</Typography>
        <FlexRow>
          <Typography style={{ marginRight: '5px' }}>
            {collectionDate}
          </Typography>
          <InfoIcon toolTipText='The project is required to be completed and delivered by this specified date.' />
        </FlexRow>
      </FlexRowSpaceBetween>
    );
  };

  const renderLeadTimeInput = () => {
    return (
      <Tooltip
        title={
          user.country === 'Singapore'
            ? 'State lead time required upon order acceptance (not including shipping)'
            : 'State lead time required upon order acceptance (including shipping)'
        }
        placement='bottom'
        arrow
      >
        <TextField
          variant='outlined'
          required
          fullWidth
          margin='dense'
          label='Lead Time'
          name='leadTime'
          type='number'
          placeholder='E.g. 7'
          value={leadTime}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => setLeadTime(event.target.value)}
          onFocus={() => setLeadTimeError(null)}
          error={leadTimeError}
          helperText={leadTimeError}
          InputProps={{
            inputProps: {
              min: 0,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            },
            endAdornment: (
              <InputAdornment position='end'>working day(s)</InputAdornment>
            ),
          }}
        />
      </Tooltip>
    );
  };

  const renderPpeLeadTime = () => {
    return (
      <FlexRowSpaceBetween>
        <Typography>Lead Time</Typography>
        <Typography>{leadTime} working day(s)</Typography>
      </FlexRowSpaceBetween>
    );
  };

  const renderLeadTimeOrCollectionDate = () => {
    return handleSummaryView().hasPPE
      ? isEmptyValue(collectionDate)
        ? renderPpeLeadTime()
        : renderPpeCollectionDate()
      : renderLeadTimeInput();
  };

  const renderQuoteValid = () => {
    return (
      <FlexRow>
        <Tooltip
          title='Quotation will automatically be removed from system beyond selected date'
          placement='top'
          arrow
        >
          <DatePicker
            label='Quote valid till'
            value={dateOfExpiry}
            onChange={(newDate) => setDateOfExpiry(newDate)}
            animateYearScrolling
            inputVariant='outlined'
            margin='dense'
            fullWidth
            clearable
            clearLabel='No Preference'
            minDate={new Date()}
          />
        </Tooltip>
      </FlexRow>
    );
  };

  const renderSubmitQuoteButton = () => {
    return (
      <Button
        variant='contained'
        color='primary'
        fullWidth
        onClick={(e) => onSubmitQuote(e)}
        disabled={!acknowledge || isLoadingSubmitted}
        data-cy='submit-quote-btn'
      >
        Submit Quote
      </Button>
    );
  };

  const renderAssignQuoteTo = () => {
    if (
      ![ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN, ROLE_TYPES.REVIEWER].includes(
        user.role
      )
    ) {
      return null;
    }

    return (
      <div style={{ marginTop: '0.5rem' }}>
        <SuppliersByTechDropDown
          label='Assign Quote to'
          supplierList={supplierList}
          handleChange={handleAssignQuoteToChange}
          selected={assignedTo}
          searchable
        />
      </div>
    );
  };

  const renderQuoteSummary = () => {
    return (
      <Grid item md={12} lg={3} ref={summaryPanelRef}>
        <FlexColumn
          style={{
            position: 'sticky',
            top: '2rem',
            border: `1px solid ${colors.lightGray}`,
            borderRadius: '5px',
          }}
        >
          <Paper
            style={{
              padding: '1rem',
              marginBottom: '1rem',
              boxShadow: 'none',
            }}
          >
            <Tooltip title='Total for default quantity' placement='left'>
              <FlexRowSpaceBetween style={{ marginBottom: '0.5rem' }}>
                <Typography>Parts Total</Typography>
                <Typography>
                  <Box
                    style={{
                      color: colors.blue050,
                      fontSize: '1.5rem',
                    }}
                  >
                    <b>{partsTotal}</b>
                  </Box>
                </Typography>
              </FlexRowSpaceBetween>
            </Tooltip>
            {renderLeadTimeOrCollectionDate()}
            {renderSupplierLeadTimeFeedback()}
            {projectType !== PROJECT_TYPE.PPE && renderQuoteValid()}
            {renderAssignQuoteTo()}
            <Paper
              style={{
                marginTop: '0.5rem',
                backgroundColor: colors.expansionBackgroundColor,
              }}
              variant='outlined'
            >
              <FlexRow style={{ justifyContent: 'start', padding: '0.5rem' }}>
                <Checkbox
                  style={{
                    height: 20,
                  }}
                  size='small'
                  checked={acknowledge}
                  onChange={() => setAcknowledgement(!acknowledge)}
                  data-cy='checkbox-summary-panel'
                />
                <Typography
                  style={{
                    textAlign: 'justify',
                    textJustify: 'inter-word',
                    padding: '0.3rem',
                  }}
                  variant='caption'
                >
                  By submitting this quote, I acknowledge that all parts
                  specifications and lead time can be met and that I will
                  fabricate the order with the best of my capability ONLY IF and
                  WHEN Factorem issues a PO to proceed with this order.
                </Typography>
              </FlexRow>
            </Paper>
          </Paper>
          <div style={{ padding: '0 1rem 1rem' }}>
            {handleSummaryView().isConfirm ? (
              <GreenButton
                tooltipTitle='Factorem System will issue PO within 24 hours if job is awarded.'
                id='accept-job-btn'
                text={'ACCEPT JOB'}
                onClick={() => setAcceptJobDialog(true)}
                disabled={!acknowledge}
              />
            ) : (
              renderSubmitQuoteButton()
            )}
            <Button
              style={{
                marginTop: '0.5rem',
              }}
              color='primary'
              fullWidth
              onClick={history.goBack}
            >
              Cancel and Go back
            </Button>
          </div>
        </FlexColumn>
      </Grid>
    );
  };

  const renderDeleteBomFilePopup = () => {
    return (
      <YesNoPopup
        open={openDeleteBomFilePopup}
        handleNo={() => setOpenDeleteBomFilePopup(false)}
        handleYes={() => {
          handleDeleteBom();
          setOpenDeleteBomFilePopup(false);
        }}
        body={`${deletingBomFile.fileName}`}
      />
    );
  };

  const renderSummarySlide = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 990,
          overflowY: 'visible',
          overflowX: 'scroll',
          height: '100%',
          paddingLeft: 25,
        }}
      >
        <Slide
          direction='left'
          in={showSummaryPanel}
          mountOnEnter
          unmountOnExit
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '0.5rem',
              paddingTop: '1rem',
              position: 'relative',
            }}
          >
            <Fab
              style={{
                position: 'absolute',
                top: 0,
                left: -15,
                zIndex: 1999,
              }}
              aria-label='close'
              onClick={() => setShowSummaryPanel(false)}
              size='small'
            >
              <CloseIcon />
            </Fab>
            <div style={{ width: '300px' }}>{renderQuoteSummary()}</div>
          </div>
        </Slide>
      </div>
    );
  };

  const renderCheckoutFabButton = () => {
    return (
      <ButtonBase
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          borderRadius: '5rem',
          backgroundColor: colors.successGreen,
          margin: 'auto',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isMobile) {
            summaryPanelRef.current.scrollIntoView({ behavior: 'smooth' });
          } else {
            setShowSummaryPanel(!showSummaryPanel);
          }
        }}
      >
        <Fab
          size={isMobile ? 'small' : 'medium'}
          color='primary'
          aria-label='summary'
          data-cy='checkout-btn'
        >
          <Badge
            className={classes.quoteNumberBadge}
            badgeContent={quoteSelectedItems.length}
          >
            <SendIcon />
          </Badge>
        </Fab>
        <Typography
          style={{
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          {partsTotal}
        </Typography>
      </ButtonBase>
    );
  };

  const renderSimpleSummaryInfoRow = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 998,
          padding: '0.5rem',
          marginBottom: '0.75rem',
        }}
      >
        <FlexRowCenter
          style={{
            width: 'fit-content',
            borderRadius: '5rem',
            backgroundColor: colors.successGreen,
            margin: 'auto',
          }}
        >
          {renderCheckoutFabButton()}
        </FlexRowCenter>
      </Grid>
    );
  };

  return (
    <ProjectQuoteForSupplierContext.Provider
      value={{
        reloadQuoteData: loadData,
      }}
    >
      <div className={classes.body}>
        <Grid container spacing={2}>
          {dataLoading && <CircularProgress />}
          {!dataLoading && (
            <Grid container>
              <Grid item xs={allItemsQuoted || isTablet ? 12 : 9}>
                {renderProjectInfo()}
                {projectType === PROJECT_TYPE.PPE && <PpeQuoteNoticeLabel />}
                {get(formData, 'formError.form') && (
                  <ErrorLabel message={get(formData, 'formError.form')} />
                )}
                <ProjectBulkQuotationsForm />
              </Grid>
              {!allItemsQuoted && isTablet && renderSimpleSummaryInfoRow()}
              {!allItemsQuoted && !isTablet && renderQuoteSummary()}
            </Grid>
          )}
        </Grid>
        {!allItemsQuoted && isTablet && !isMobile && renderSummarySlide()}
        {!allItemsQuoted && isMobile && renderQuoteSummary()}
        {openDeleteBomFilePopup && renderDeleteBomFilePopup()}
        <SupplierSubmitQuotationPopup
          dialog={acceptJobDialog}
          onClose={() => setAcceptJobDialog(false)}
          label="By clicking the 'Accept job' button, I acknowledge that my submission will be subject to a thorough review process by the Factorem Partner Ranking system. I will commence the job only upon award of an official PO issued by the system."
          onConfirmAcceptQuote={onSubmitQuote}
        />
      </div>
    </ProjectQuoteForSupplierContext.Provider>
  );

  function renderSupplierLeadTimeFeedback() {
    if (isEmptyValue(collectionDate)) {
      return null;
    }

    return (
      <SupplierLeadTimeFeedback
        selectedQuotationIDs={selectedQuotationIDs}
        deliveryDate={collectionDate}
      />
    );
  }
}

export default withViewAsSupplierHOC(ProjectQuoteForSupplier);

import React, { useReducer } from 'react';

import YesNoPopup from '../components/popups/YesNoPopup';

import { createOrderJnt } from '../apis/shipmentApi';

import { notifyError } from '../services/notificationService';

function withCreateOrderJntPopupHoC(WrappedComponent) {
  return function WrappedComponentWithCreateOrderJntPopupHoC(props) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        loading: false,
        dataOrder: null,
        title: 'Yes/No Popup!',
        updateMainState: () => {},
      }
    );

    const handleCreateOrderJnt = () => {
      updateLocalState({ loading: true });
      createOrderJnt(localState.dataOrder)
        .then((data) => {
          localState.updateMainState({
            isSuccessCreateOrderJnt: true,
            reference_number: data.reference_number,
            sorting_code: data.sorting_code,
            tracking_id: data.tracking_id,
            waybill_url: data.waybillURL,
          });
        })
        .catch((err) => {
          notifyError(err?.message || 'Error on creating order!');
        })
        .finally(() => {
          localState.updateMainState({ isCreateOrderJntProcessing: false });
          updateLocalState({ loading: false, open: false });
        });
    };

    return (
      <>
        <WrappedComponent
          updateCreateOrderJntPopupHoCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <YesNoPopup
            open={localState.open}
            title='This action will create a live order with J&T. Confirm Proceed?'
            handleNo={() => updateLocalState({ open: false })}
            handleYes={handleCreateOrderJnt}
            loading={localState.loading}
            noButtonLabel='Cancel'
            yesButtonLabel='Proceed'
          />
        )}
      </>
    );
  };
}

export default withCreateOrderJntPopupHoC;

import { v4 as uuidv4 } from 'uuid';
import { Cookies } from 'react-cookie';
import { isEmptyValue } from './commonUtils';

import {
  cache,
  deleteCacheForKeyStartsWith,
  getCacheValue,
} from '../services/apiResponseCacheService';

import { BE_API_KEY } from '../constants';
import { ERROR_MESSAGE } from '../constants/errorMessageConstants';

// -------------------------------------------------------------------------------------------------

const hash = require('object-hash');

const cookies = new Cookies();

export const getAuthorizedHeader = () => {
  const token = cookies.get('token');
  const headers = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  } else {
    // only add BE API key if it does not have token to prevent leaking the key as much as possible
    headers['be_api_key'] = BE_API_KEY;
  }
  return headers;
};

export const fetchWithErrorHandling = async (...args) => {
  return fetch(...args)
    .then(async (response) => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          throw Error('Permission Denied');
        }

        const json = await response.json();

        throw Error(json.message || response.statusText);
      }

      const res = await response.json();
      return res.data;
    })
    .catch((err) => {
      if (err.name === 'AbortError') {
        // abort error is expected when the request is aborted
        return;
      }

      if (err.message === 'Failed to fetch') {
        throw new Error(ERROR_MESSAGE.INTERNET_CONNECTION);
      }

      throw err;
    });
};

export const fetchWithTimeout = async (resource, options = {}) => {
  const { timeout = 8000 } = options;

  const controller = new window.AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

const TWO_MINS_IN_SECS = 2 * 60;
export const witchCacheResponse = (
  func,
  ttlInSecs = TWO_MINS_IN_SECS * 1000,
  functionName
) => {
  // generate a unique name for the function because it will be empty after build
  const funcName = functionName || uuidv4();

  return async function (...args) {
    const hashValue = hash({
      ...args,
    });
    const key = `${func.name || funcName}-${hashValue}`;
    let result = getCacheValue(key);
    if (result) {
      return result;
    }
    result = await func(...args);
    if (!isEmptyValue(result)) {
      cache(key, result, ttlInSecs * 1000);
    }
    return result;
  };
};

export const clearApiCacheResponse = (functionName) => {
  deleteCacheForKeyStartsWith(functionName);
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';

import {
  addOrUpdateCadPart,
  getPpePriceForCadPart,
  resetPartSummaryDetails,
} from '../actions';

import { prepareOrderAgainItem } from '../components/util/orderItemAgainUtils';

import { useItemInputConfig } from './useItemInputConfig';
import { partUploadProxy } from '../proxies/partUploadProxy';

// -------------------------------------------------------------------------------------------------

export const useOrderItemsAgainHookV2 = () => {
  const dispatch = useDispatch();

  const [
    { technologyOptions },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
    },
  ] = useItemInputConfig({});

  useEffect(() => {
    loadTechnologyOptions(false);
  }, []);

  const handleOrderItemsAgain = async (selectedPartDetailsList) => {
    dispatch(resetPartSummaryDetails());
    for (const partDetails of selectedPartDetailsList) {
      const item = await prepareOrderAgainItem({
        existingItem: partDetails,
        technologyOptions,
        loadMaterialCategoryOptions,
        loadThreeDMaterialOptions,
        loadSurfaceFinishOptions,
      });

      const partProxy = new Proxy(item, partUploadProxy);
      if (partProxy.hasPdfUploaded()) {
        item.tdeTolerance = item.tolerance;
        item.repeatPdf = true;
        item.sameRepeatOwner = true;
      }

      const id = nanoid();
      item.id = id;
      dispatch(addOrUpdateCadPart(item));
      dispatch(getPpePriceForCadPart(id));
    }
  };

  return [
    {
      handleOrderItemsAgain,
    },
  ];
};

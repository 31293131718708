import { filter, get, isInteger } from 'lodash';

import {
  adminAllowedDeniedSuppliersForItem,
  getAllAvailableViewAsAccounts,
} from '../apis/itemApi';

import {
  compareStringsEqualsIgnoreCase,
  capitalizeString,
} from './stringUtils';
import { isEmptyValue } from './commonUtils';
import { reverseMapObject } from './objectUtils';
import {
  getItemHashcodeCad,
  getItemHashcodePdf,
  getReverseMaterialFromPpeMaterialCode,
} from './ppeUtils';
import {
  getMaxDate,
  compareDate,
  formatDeliveryDateWithCheck,
} from './dateTimeUtils';
import {
  isAnodizingSurfaceFinish,
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from './inputUtils';
import {
  getFileNameFromUrl,
  getFileNameWithoutExtensionFromUrl,
} from './fileUtils';

import { itemSurfaceFinishProxy } from '../proxies/itemSurfaceFinishProxy';

import { surfaceFinishMapping, techMapping } from '../constants/PPEConstants';

import { FACTOREM_PARTNER } from '../constants/userConstant';
import {
  DEFAULT_LEAD_TIME,
  ITEM_STATUS_MAPPING,
  PPE_DEFAULT_LEAD_TIME,
  ROCKET_QUOTE_ACCEPT_TYPE,
} from '../constants';
import {
  DEFAULT_SHIP_MODE_LIST,
  ITEM_CANCELLATION_REASON,
  QUALITY_ITEM_LABELS,
} from '../constants/itemConstants';
import { PLACEHOLDER_IMAGE_URL } from '../constants/imageConstants';
import {
  ITEM_FILTER_STATUS_ARRAY,
  ITEM_STATUS_TEXT_MAPPING,
  PPE_ITEM_STAGE_STATUS,
  RFQ_ITEM_STATUS,
} from '../constants/itemStatus';
import {
  DEFAULT_SUPPORT_FILES,
  SUPPORT_FILES_TEXT_MAPPING,
  surfaceFinishOptions,
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_TECH_ABBRE_MAPPING,
  THREE_D_SURFACE_OPTIONS,
} from '../constants/NewPartConstants';

// ------------------------------------------------------------------------------------------------

export const filterReadyForQuoteItems = (items) => {
  const validItems = filter(items, (item) => {
    if (item.cadFile == null) {
      return false;
    }
    return (
      (item.status === ITEM_STATUS_MAPPING.SOURCING && item.price == null) ||
      (item.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE &&
        (item.price == null || item.rocketQuote)) ||
      (item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
        !item.rocketQuote &&
        !item.ppeQuoted &&
        item.price !== null &&
        new Date(item.datePosted) > new Date('2021-12-19')) ||
      (item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
        item.rocketQuote &&
        item.price &&
        !item.ppeVerified &&
        item.acceptQuoteType === ROCKET_QUOTE_ACCEPT_TYPE.PPE)
    );
  });
  if (isEmptyValue(validItems)) {
    return [];
  }
  return validItems.sort((a, b) => {
    if (
      a.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
      b.status !== ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
    ) {
      return -1;
    } else if (
      a.status !== ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
      b.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
    ) {
      return 1;
    } else {
      return compareDate(b.verifiedDate, a.verifiedDate);
    }
  });
};

export const getItemImageUrl = (item) => {
  return item.imageFile && item.imageFile.startsWith('http')
    ? item.imageFile
    : item.twoDImageUrl && item.twoDImageUrl.startsWith('http')
      ? item.twoDImageUrl
      : PLACEHOLDER_IMAGE_URL;
};

export const getItemNameFromCadFile = getFileNameWithoutExtensionFromUrl;

export const getFileNameFromCadFile = getFileNameFromUrl;

export const getPpeItemStageStatus = (status, ppeVerified) => {
  const stageStatus =
    status === 3
      ? 0
      : status === 1
        ? 1
        : status === 4 && ppeVerified === 0
          ? 1
          : status - 2;
  return stageStatus;
};

export const isPpeItem = (item) => {
  const isPpeItem = !isEmptyValue(item.isPPE) ? item.isPPE : !!item.price;
  return isPpeItem;
};

export const getItemStageStatusText = (item) => {
  const statusText = isPpeItem(item)
    ? PPE_ITEM_STAGE_STATUS[
        getPpeItemStageStatus(item.status, item.ppeVerified)
      ]
    : RFQ_ITEM_STATUS[item.status];
  return statusText;
};

export const getProjectItemStatusFilterOrder = (status) => {
  let order = 0;
  for (; order < ITEM_FILTER_STATUS_ARRAY.length; order++) {
    if (ITEM_FILTER_STATUS_ARRAY[order].name === status) {
      return order;
    }
  }
  return order;
};

export const getDeliveryDateStr = (item) => {
  let estimated = true;
  let deliveryDate;
  if (item.actualDeliveryDate) {
    deliveryDate = item.actualDeliveryDate;
    estimated = false;
  } else if (item.deliveryDate) {
    deliveryDate = item.deliveryDate;
  }
  if (!deliveryDate) {
    return '';
  }
  const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
  const deliveryDateStr = new Date(deliveryDate).toLocaleDateString(
    'en-GB',
    dateFormat
  );
  return estimated ? `${deliveryDateStr} (est.)` : deliveryDateStr;
};

const RFQ_ITEM_STATUS_PO_UPLOAD = [
  'Order In Progress',
  'Order Ready',
  'Delivered',
];

const PPE_ITEM_STAGE_STATUS_PO_UPLOAD = [
  'Processing',
  'Order In Progress',
  'Order Ready',
  'Delivered',
];

export const checkUploadPoItemStatusSupport = (item) => {
  const isSupport = isPpeItem(item)
    ? PPE_ITEM_STAGE_STATUS_PO_UPLOAD.includes(getItemStageStatusText(item))
    : RFQ_ITEM_STATUS_PO_UPLOAD.includes(getItemStageStatusText(item));
  return isSupport;
};

export const getSurfaceOptionsByTech = (tech) => {
  switch (tech) {
    case TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING:
      return THREE_D_SURFACE_OPTIONS;
    default:
      return surfaceFinishOptions;
  }
};

export const getTechnologyDisplayText = (item) => {
  return isCustomTechnology(item.technology)
    ? item.otherTechnology
    : item.technology;
};

export const getMaterialWithColorText = (item) => {
  const material = isCustomMaterial(item.material)
    ? item.otherMaterial || item.customMaterial || item.material
    : item.material || 'No material';
  return item.materialColor
    ? item.materialColor !== 'Custom Color'
      ? `${item.materialColor} ${material}`
      : `${item.customMaterialColor} ${material}`
    : material;
};

export const getSurfaceFinishWithCustomizationsText = (itemOrQuote) => {
  if (isEmptyValue(itemOrQuote)) {
    return '';
  }

  const itemProxy = new Proxy(itemOrQuote, itemSurfaceFinishProxy);

  const surfaceFinish = itemProxy.getSurfaceFinishText();

  let anodizingTypeStr;

  if (isAnodizingSurfaceFinish(surfaceFinish)) {
    anodizingTypeStr = isEmptyValue(itemOrQuote?.metadata)
      ? itemOrQuote?.anodizingType
      : itemOrQuote.metadata['anodizingType'];
  }

  const anodizingType = isAnodizingSurfaceFinish(surfaceFinish)
    ? capitalizeString(anodizingTypeStr || '')
    : '';

  const color = itemOrQuote.color;
  const customColor = itemOrQuote.customColor;

  if (!isEmptyValue(color) && color !== 'Does not matter') {
    const colorText = color === 'Custom Color' ? customColor : color;
    return isEmptyValue(anodizingType)
      ? `${colorText} ${surfaceFinish}`
      : `${anodizingType} ${colorText} ${surfaceFinish}`;
  }

  return surfaceFinish;
};

export const is3DPTechnology = (technology) => {
  return technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING;
};

/**
 *
 * @param {*} item
 * @returns the actual material
 */
export const getItemMaterial = (item) => {
  return isCustomMaterial(item.material)
    ? item.otherMaterial || item.customMaterial
    : item.material;
};

/**
 *
 * @param {*} item
 * @returns the actual surface finish
 */
export const getItemSurfaceFinish = (item) => {
  return isCustomSurfaceFinish(item.surfaceFinish)
    ? item.otherSurfaceFinish || item.customSurfaceFinish
    : item.surfaceFinish || 'NIL';
};

export const getSupportFileTypes = (technology) => {
  const supportFilesObj =
    SUPPORT_FILES_TEXT_MAPPING[technology] || DEFAULT_SUPPORT_FILES;
  return supportFilesObj;
};

export const shouldShowInvoice = (item) => {
  if (isEmptyValue(item)) {
    return false;
  }

  const itemStatusText = getItemStageStatusText(item);
  return ![
    ITEM_STATUS_TEXT_MAPPING.CANCELLED,
    ITEM_STATUS_TEXT_MAPPING.VERIFYING,
  ].includes(itemStatusText);
};

export const getPpeUpdateHashcodeStatusText = (item) => {
  const { ppeUpdateHashcodeStatus } = item;
  if (isEmptyValue(ppeUpdateHashcodeStatus)) {
    return 'N.A.';
  }
  if (ppeUpdateHashcodeStatus.status === 200) {
    return 'Success';
  }
  return 'Fail';
};

export const getTdeCoordinateUpdateStatusText = (item) => {
  const { tdeCoordinateUpdateStatus } = item;
  if (isEmptyValue(tdeCoordinateUpdateStatus)) {
    return 'N.A.';
  }
  if (tdeCoordinateUpdateStatus === 'success') {
    return 'Success';
  }
  return 'Fail';
};

export const displayLeadTime = (item) => {
  return isInteger(item.leadTime) && isInteger(item.markupLeadTime)
    ? `${Number(item.leadTime) + Number(item.markupLeadTime)} working days`
    : PPE_DEFAULT_LEAD_TIME;
};

export const getMinLeadTime = (itemList) => {
  let minLeadTime = DEFAULT_LEAD_TIME;
  for (const item of itemList) {
    const { leadTime } = item;
    if (Number.isInteger(Number(leadTime)) && Number(leadTime) < minLeadTime) {
      minLeadTime = Number(leadTime);
    }
  }
  return minLeadTime;
};

export const getMaxLeadTime = (itemList) => {
  let maxLeadTime = undefined;
  for (const item of itemList) {
    const { leadTime } = item;
    if (
      Number.isInteger(Number(leadTime)) &&
      (typeof maxLeadTime === 'undefined' || Number(leadTime) > maxLeadTime)
    ) {
      maxLeadTime = Number(leadTime);
    }
  }
  return maxLeadTime;
};

/**
 * Finds the latest date from a list of items by checking specified date properties.
 * Iterates through each item, checking each provided property in order of priority,
 * and returns the most recent valid date found. If no valid date is found, returns null.
 *
 * For example, if `dateProps` is `['revisedCollectionDate', 'collectionDate']`, the function
 * will prioritize `revisedCollectionDate` over `collectionDate`, checking `revisedCollectionDate` first for each item.
 *
 * @param {Object[]} itemList - Array of items to check for date properties.
 * @param {String[]} dateProps - List of date property names to check on each item, in order of priority.
 * @returns {Date|null} - The latest date found, or null if no valid date exists.
 */
const getLatestDateFromItems = (itemList, dateProps) => {
  let latestDate = undefined;
  for (const item of itemList) {
    const datesToConsider = dateProps
      .map((prop) => item[prop])
      .filter((date) => !isEmptyValue(date));
    const currentDate = datesToConsider[0];
    if (
      currentDate &&
      (typeof latestDate === 'undefined' ||
        compareDate(currentDate, latestDate) > 0)
    ) {
      latestDate = currentDate;
    }
  }
  return isEmptyValue(latestDate) ? null : latestDate;
};

/**
 * Returns the latest delivery and revised delivery dates from a list of items.
 * If no valid date is found for either, returns null for that field.
 *
 * @param {Object[]} itemList - Array of items, each with optional 'deliveryDate' and 'revisedDeliveryDate'.
 * @returns {Object} - Object with 'deliveryDate' and 'revisedDeliveryDate' as the latest dates or null if none found.
 */
export const getDeliveryDatesFromItemList = (itemList) => ({
  deliveryDate: getLatestDateFromItems(itemList, ['deliveryDate']),
  revisedDeliveryDate: getLatestDateFromItems(itemList, [
    'revisedDeliveryDate',
  ]),
  actualDeliveryDate: getLatestDateFromItems(itemList, ['actualDeliveryDate']),
});

/**
 * Similar to the function above, but for collection date
 */
export const getCollectionDatesFromItemList = (itemList) => ({
  collectionDate: getLatestDateFromItems(itemList, ['collectionDate']),
  revisedCollectionDate: getLatestDateFromItems(itemList, [
    'revisedCollectionDate',
  ]),
  actualCollectionDate: getLatestDateFromItems(itemList, [
    'actualCollectionDate',
  ]),
});

export const getLatestCollectionDateForItem = ({
  actualCollectionDate,
  revisedCollectionDate,
  collectionDate,
}) => {
  return actualCollectionDate ?? revisedCollectionDate ?? collectionDate;
};

/**
 * Returns the most updated collection date from a list of items, checking both 'collectionDate' and 'revisedCollectionDate'.
 * If no valid date is found, returns null.
 *
 * @param {Object[]} itemList - Array of items, each with optional 'collectionDate' and 'revisedCollectionDate'.
 * @returns {Date|null} - The most updated collection date or null if none is found.
 */
export const getLatestCollectionDate = (itemList) => {
  const collectionDates = getCollectionDatesFromItemList(itemList);
  return getLatestCollectionDateForItem(collectionDates);
};

export const getLatestCollectionDateStr = (itemList) => {
  return formatDeliveryDateWithCheck(getLatestCollectionDate(itemList));
};

export const getLatestDeliveryDateForItem = ({
  actualDeliveryDate,
  revisedDeliveryDate,
  deliveryDate,
}) => {
  return actualDeliveryDate ?? revisedDeliveryDate ?? deliveryDate;
};

export const getLatestDeliveryDate = (itemList) => {
  const deliveryDates = getDeliveryDatesFromItemList(itemList);
  return getLatestDeliveryDateForItem(deliveryDates);
};

export const getLatestDeliveryDateStr = (itemList) => {
  return formatDeliveryDateWithCheck(getLatestDeliveryDate(itemList));
};

export const getLatestDeliveryDateForMct = (itemList) => {
  return getLatestDateFromItems(itemList, [
    'actualDeliveryDate',
    'deliveryDate',
  ]);
};

export const getBulkPricingDiscount = (initialPrice, bulkPrice) => {
  return ((initialPrice - bulkPrice) / initialPrice) * 100;
};

export const getIsRepeatOrder = (instantQuotesLogMeta) => {
  const matchType =
    get(instantQuotesLogMeta, ['matched', 'match_type']) ||
    get(instantQuotesLogMeta, 'match_type') ||
    '';
  if (compareStringsEqualsIgnoreCase(matchType, 'ROM')) {
    return true;
  }

  return (
    get(instantQuotesLogMeta, 'rom_order') ||
    get(instantQuotesLogMeta, 'bool_repeat_order', false) ||
    false
  );
};

export const getMatchedItemID = (instantQuotesLogMeta) => {
  return get(instantQuotesLogMeta, ['matched', 'part_id']);
};

export const getMatchedQuotationID = (instantQuotesLogMeta) => {
  return get(instantQuotesLogMeta, ['matched', 'quote_id']);
};

export const getIsSimilarOrder = (instantQuotesLogMeta) => {
  const matchType =
    get(instantQuotesLogMeta, ['matched', 'match_type']) ||
    get(instantQuotesLogMeta, 'match_type') ||
    '';
  if (compareStringsEqualsIgnoreCase(matchType, 'CSM')) {
    return true;
  }

  return get(instantQuotesLogMeta, 'csm_order', false) || false;
};

export const getDeliveryOptionTooltipText = (deliveryOption) => {
  return get(DEFAULT_SHIP_MODE_LIST, `${deliveryOption}.toolTipText`, '');
};

export const itemProperties = (item) => {
  const is3DPrinting =
    item?.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING;
  const technology = item?.technology;
  const material = item?.customMaterial || item?.material;
  const surfaceFinish =
    item?.surfaceFinish !== 'NIL'
      ? item?.customSurfaceFinish || item?.surfaceFinish
      : '';
  const quantity = `Qty: ${item?.quantity || item?.itemQuantity}`;
  const threeDPrintingTechnology = is3DPrinting
    ? THREE_D_P_TECH_ABBRE_MAPPING[item?.metadata?.threeDTechnology]
    : '';
  const str = [
    technology,
    material,
    surfaceFinish,
    threeDPrintingTechnology,
    quantity,
  ]
    .filter(Boolean)
    .join(', ');
  return str;
};

export const getCancelledItemInfo = (item, initialWords = '') => {
  if (
    item?.cancelledByName === FACTOREM_PARTNER &&
    item?.cancellationReason === ITEM_CANCELLATION_REASON.EXPIRED
  ) {
    return `Part expired`;
  }
  return `${initialWords}${item.cancelledByName} (${item.cancelledBy})`;
};

/**
 * To get ppeInfo if success and error
 * @param {*} item
 * @param {string} key
 * @returns Object of PpeInfo
 */
export const getPpeInfo = (item, key = 'instantQuotesLogMeta') => {
  if (!item) {
    return {};
  }
  let ppeInfo = item[key]?.response;
  // if there is no error, use additionalParameters
  if (!ppeInfo?.error) {
    ppeInfo = ppeInfo?.additionalParameters;
  }
  return ppeInfo || {};
};

/**
 *
 * @param {Object} item
 * @param {Object} properties
 * @param {Boolean} allowAll
 * @returns
 */
export const getQualityProperties = (item, properties, allowAll) => {
  if (!item) {
    return {};
  }
  const defaultProperties = properties ?? Object.keys(QUALITY_ITEM_LABELS);
  const qualityProperties = {};
  defaultProperties?.forEach((property) => {
    const isSFQuality = property.includes('SFQuality');
    const hasValidSurfaceFinish = !['NIL', '-', null, undefined].includes(
      item?.surfaceFinish
    );
    if (!isSFQuality || (isSFQuality && hasValidSurfaceFinish) || allowAll) {
      qualityProperties[property] = item[property];
    }
  });
  return qualityProperties;
};

/**
 * If the property owner is customer, it will return property for customer and supplier
 * If the property owner is partner, it will return property only partner
 * @param {String} name
 * @param {Boolean} checked
 * @returns
 */
export const getQualityPropertiesToUpdate = (name, checked) => {
  const properties = {
    [name]: checked ? 1 : 0,
  };
  // update partner properties
  if (name?.includes('customer')) {
    const partnerProp = name?.replace('customer', 'partner');
    properties[partnerProp] = checked ? 1 : 0;
  }
  return properties;
};

const getAllowedDeniedSupplierForItemID = async (itemID) =>
  adminAllowedDeniedSuppliersForItem(itemID);

/**
 * Given a list of itemIDs, returns a list of suppliers who can view any of the itemIDs
 * @param {*[]} itemIDs
 */
export const getAllowedSuppliersByItemIDs = async (itemIDs) => {
  if (isEmptyValue(itemIDs)) {
    return [];
  }
  const allowedSuppliersMap = new Map();
  const allowedDeniedSuppliersList = await Promise.all(
    itemIDs.map(getAllowedDeniedSupplierForItemID)
  );
  const allowedSuppliersList = allowedDeniedSuppliersList.map(
    (allowedDeniedSuppliers) => allowedDeniedSuppliers.allowedSuppliers
  );
  for (const allowedSuppliersByItemID of allowedSuppliersList) {
    const hasAllSuppliersPool = allowedSuppliersByItemID.some(
      (allowedSupplier) => allowedSupplier === 'All suppliers pool'
    );
    if (hasAllSuppliersPool) {
      return getAllAvailableViewAsAccounts();
    }
    allowedSuppliersByItemID.forEach((allowedSupplierForItem) => {
      allowedSuppliersMap.set(
        allowedSupplierForItem.userID,
        allowedSupplierForItem
      );
    });
  }
  return Array.from(allowedSuppliersMap.values());
};

/**
 *
 * @param {{ userID: number }[]}} watchingList
 * @returns
 */
export const getTotalWatchingJobsByUserID = (watchingList) => {
  if (isEmptyValue(watchingList)) {
    return 0;
  }
  return [...new Set(watchingList?.map((watch) => watch.userID))].length;
};

export const isItemEditable = (item) =>
  item.status === 5 ||
  item.status === 6 ||
  (item.status === 1 && item.price === null);

/**
 * this fn will return the different owner information based on
 * the information of the api response
 * @see BE: /code/services/itemService#getItemWithAdditionalData
 * @param {Object} item
 * @returns {Object}
 */
export const getItemOwner = (item) => {
  let ownerID,
    ownerName,
    ownerCompany,
    ownerEmail,
    ownerCountry = '';
  if (isEmptyValue(item)) {
    return {
      ownerID,
      ownerName,
      ownerEmail,
      ownerCompany,
      ownerCountry,
    };
  }

  ownerID = item.userID;
  ownerCompany = item.cCompanyName || item.companyName || '';

  if (isEmptyValue(item.owners)) {
    ownerName = item.buyerName;
  } else {
    const owner = item.owners[0];
    ownerName = owner.ownerName;
    ownerEmail = owner.ownerEmail;
  }

  ownerCountry = item.buyerCountry;

  return {
    ownerID,
    ownerName,
    ownerEmail,
    ownerCompany,
    ownerCountry,
  };
};

export const buildItemFromPpeRequest = (ppeRequest, ppeResponse) => {
  const { process, parameters } = ppeRequest || {};

  const { additionalParameters } = ppeResponse || {};

  const techMappingReverse = reverseMapObject(techMapping);
  const technology = techMappingReverse[process] || process;

  const surfaceFinishMappingReverse = reverseMapObject(surfaceFinishMapping);

  const imageFile = get(parameters, 'customer_image_file_location');

  return {
    technology,
    material: getReverseMaterialFromPpeMaterialCode({
      technology,
      material: parameters.material,
    }),
    tolerance: parameters.tolerance,
    quantity: parameters.quantity,
    surfaceFinish:
      surfaceFinishMappingReverse[parameters.finish] || parameters.finish,
    itemHashcodeCad: getItemHashcodeCad(additionalParameters),
    itemHashcodePdf: getItemHashcodePdf(additionalParameters),
    imageFile,
    quotation: {
      totalPrice: ppeResponse?.customerPrice,
    },
  };
};

/**
 * Check if the given customer upload info is the latest compared to the
 * uploads from the admin side.
 * @param {Object} item - The item object from the database.
 * @param {Object} uploadInfo - The customer upload info.
 * @returns {boolean} True if the customer upload is the latest, false otherwise.
 */
export const isCustomerLatestUpload = (item, link) => {
  if (isEmptyValue(item)) {
    return false;
  }
  const uploadInfo = item.customerCadFileUploadInfo?.find(
    (info) => info.s3ObjectUrl === link
  );
  if (
    isEmptyValue(uploadInfo) ||
    isEmptyValue(item.factoremCadFileUploadInfo)
  ) {
    return false;
  }
  const adminUploadedDates = item.factoremCadFileUploadInfo.map(
    (i) => i.uploadedAt
  );
  const adminMaxUpload = getMaxDate(adminUploadedDates);
  return compareDate(uploadInfo.uploadedAt, adminMaxUpload) > 0;
};

/**
 * Check for any RQ advisory message based on an item
 * checks for these criteria in order
 * if any of these criteria are met, the function stops there
 * 1. Customer submits target price / lead time
 * 2. Any platform errors that occured
 * 3. Any PPE errors that occured
 * 4. If it's converted to manual RFQ by admin
 * @param {Object} item - The item object from the database.
 * @returns {Array | undefined} - The list of errors per category.
 */
export const getRQAdvisoryMessages = ({
  instantQuotesLogMeta: iqLog,
  ppePriceFeedback,
  ppeLeadTimeFeedback,
  ppeToRfqDate,
  ppeToRfqActionOwnerName,
}) => {
  if (!isEmptyValue(ppePriceFeedback) || !isEmptyValue(ppeLeadTimeFeedback)) {
    let messages = [];

    if (ppePriceFeedback === 'bad' && ppeLeadTimeFeedback === 'bad') {
      messages = [...messages, 'Customer submitted target price and lead time'];
    } else if (ppeLeadTimeFeedback === 'bad') {
      messages = [...messages, 'Customer submitted target lead time only'];
    } else {
      messages = [...messages, 'Customer submitted target price only'];
    }

    return messages;
  }

  if (!isEmptyValue(iqLog?.fallOutReasons)) {
    return iqLog.fallOutReasons;
  }

  if (!isEmptyValue(iqLog?.ppeError)) {
    if (typeof iqLog.ppeError === 'string') {
      return [iqLog.ppeError];
    } else {
      const filteredErrors = Object.values(iqLog.ppeError).filter(
        (error) => error !== 'None'
      );

      if (!isEmptyValue(filteredErrors)) {
        return filteredErrors;
      }
    }
  }

  if (!isEmptyValue(ppeToRfqDate)) {
    return [`Converted into RFQ by ${ppeToRfqActionOwnerName}`];
  }

  return [];
};

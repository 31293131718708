// Import settings
import React, { Fragment, useState } from 'react';

// Import images
import AboutFactoremBlueIcon from '../../assets/img/about-factorem-blue.png';
import AboutFactoremGreyIcon from '../../assets/img/about-factorem-grey.png';
import DeliveryBlueIcon from '../../assets/img/delivery-blue.png';
import DeliveryGreyIcon from '../../assets/img/delivery-grey.png';
import QuoteGreyIcon from '../../assets/img/quote-grey.png';
import QuoteBlueIcon from '../../assets/img/quote-blue.png';
import ServicesGreyIcon from '../../assets/img/services-grey.png';
import ServicesBlueIcon from '../../assets/img/services-blue.png';
import SupplierFAQ3Img from '../../assets/img/supplier-faq3.png';
import SupplierFAQ14Img from '../../assets/img/supplier-faq14.png';
import SupplierFAQ15_1Img from '../../assets/img/supplier-faq15-1.png';
import SupplierFAQ15_2Img from '../../assets/img/supplier-faq15-2.png';
import SupplierFAQ17_1Img from '../../assets/img/supplier-faq17-1.png';
import SupplierFAQ19_1Img from '../../assets/img/supplier-faq19-1.png';
import SupplierFAQ19_2Img from '../../assets/img/supplier-faq19-2.png';
import SupplierFAQ21_1Img from '../../assets/img/supplier-faq21-1.png';

// Import apis
import { generatePresignedUrl } from '../../apis/s3Api';

// Import customised components
import FAQTitleButton from '../../components/FAQTitle';
import { FAQDescription } from '../../components/FAQTexts';
import FAQAccordion from '../../components/FAQAccordion';

// Import constants
import { SHIPPING_GUIDELINES } from '../../constants/orderReadyConstants';

// Import utils
import { ONE_MONTH_IN_SECONDS } from '../../utils/dateTimeUtils';
import { openInNewTab } from '../../utils/navigationUtils';

// Import material UI components
import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

// Import color palette
import { colors } from '../../palette';

function SupplierFAQPage() {
  const [currentPage, setCurrentPage] = useState('Quotation');
  const [expanded, setExpanded] = useState('faq1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [aboutFactoremImage, setAboutFactoremImage] = useState(
    AboutFactoremGreyIcon
  );
  const [servicesImage, setServicesImage] = useState(ServicesGreyIcon);
  const [deliveryImage, setDeliveryImage] = useState(DeliveryGreyIcon);
  const [quoteImage, setQuoteImage] = useState(QuoteGreyIcon);

  const quotationFaqs = [
    {
      faqQn: 'What does each of the respective status under “My Quotes” mean?',
      faqAns: isMobile ? (
        <>
          <span
            style={{
              backgroundColor: '#FFE661',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;&nbsp;SUBMITTED&nbsp;&nbsp;
          </span>{' '}
          - Quote has been submitted and goes straight to the Customer for
          review. There will be no edits after your first price quoted. <br />
          <br />
          <span
            style={{
              backgroundColor: '#A1E21D',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;ACCEPTED&nbsp;&nbsp;&nbsp;
          </span>{' '}
          - Customer approved your quotation and fabrication may proceed once PO
          is sent. <br />
          <br />
          <span
            style={{
              backgroundColor: '#FFA37C',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;REJECTED&nbsp;&nbsp;&nbsp;&nbsp;
          </span>{' '}
          - Customer did not approve your quotation. <br />
          <br />
          <span
            style={{
              backgroundColor: '#CECECE',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;DISABLED&nbsp;&nbsp;&nbsp;&nbsp;
          </span>{' '}
          - The quotation has been disabled for reasons such as Customer
          cancelling the order or there is an error in the quote. <br />
          <br />
          <span
            style={{
              backgroundColor: '#CECECE',
              fontWeight: 'bold',
            }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EXPIRED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>{' '}
          - Quote has expired and is no longer accessible.
        </>
      ) : (
        <>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span
              style={{
                backgroundColor: '#FFE661',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;SUBMITTED&nbsp;&nbsp;
            </span>
            &nbsp;-&nbsp;
            <div>
              Quote has been submitted and goes straight to the Customer for
              review. There will be no edits after your first price quoted.
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span
              style={{
                backgroundColor: '#A1E21D',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;ACCEPTED&nbsp;&nbsp;&nbsp;
            </span>
            &nbsp;-&nbsp;
            <div>
              Customer approved your quotation and fabrication may proceed once
              PO is sent.
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span
              style={{
                backgroundColor: '#FFA37C',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;REJECTED&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            &nbsp;-&nbsp;
            <div>Customer did not approve your quotation.</div>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span
              style={{
                backgroundColor: '#CECECE',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;DISABLED&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            &nbsp;-&nbsp;
            <div>
              The quotation has been disabled for reasons such as Customer
              cancelling the order or there is an error in the quote.
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span
              style={{
                backgroundColor: '#CECECE',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EXPIRED&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            &nbsp;-&nbsp;
            <div>Quote has expired and is no longer accessible.</div>
          </div>
        </>
      ),
    },
    {
      faqQn: 'What kind of QC Reports am I supposed to provide?',
      faqAns: (
        <>
          As part of the Factorem network, it is important that we uphold high
          quality of parts. <br />
          <br />
          Considering the quality requirements requested by the clients when
          quoting. There are 3 QC requirements on the platform. Below are the{' '}
          <u>minimum</u> standards needed for each requirement. <br />
          <p>
            1. <strong>No QC Report</strong> - Although no QC inspection is
            required, parts must still be manufactured according to given CAD
            models, 2D Technical Drawings and any other accompanying
            documentation. <br />
            <br />
            2. <strong>Standard QC Report</strong> - Inspection of dimensions
            using tools such as Vernier Calipers, Micrometers, GO/NO GO gage,
            Vision Inspection System and Profile Projectors. <br />
            <br />
            3. <strong>Formal/CMM Report</strong> - High precision and accuracy
            measurement systems such as CMM and 3D scanning systems must be used
            to measure parts. <br />
            <br />
          </p>
          In addition, there could be complementary certificates required by
          clients. Below are the 2 most commonly requested. <br />
          <p>
            1. <strong>Material Certificate</strong> - This is to show
            conformance to material specifications and traceability to material
            lot. <br />
            <br />
            2. <strong>Coating (Finishing) Certificate</strong> - This is to
            show conformance to any coating or finishing specifications on the
            drawing.
          </p>
        </>
      ),
    },
    {
      faqQn: 'What information do I need to provide in my quotes?',
      faqAns: (
        <>
          There are 6 inputs required for each standard quotation <br />
          <p>
            1. <strong>Quantity</strong> - Please enter quantity as per
            requested. <br />
            <br />
            2. <strong>Unit Price</strong> - The price is the final quote per
            unit, inclusive of any charges for the material, manufacturing,
            surface finishing etc. <br />
            <br />
            3. <strong>Material</strong> - Reflect the material that you wish to
            quote for. <br />
            <br />
            4. <strong>Surface Finishing</strong> - Any post-processing for
            consideration with your quote. <br />
            <br />
            5. <strong>Lead Time</strong> - How much time, in working days, you
            need to complete fabrication.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <strong>For Local Partners (Singapore)</strong>: Exclude shipping
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <strong>For Overseas Partners</strong>: Include shipping <br />
            <br />
            6. <strong>Remarks/Clarification</strong> - In the event that you
            require more information, fill in your questions in the remarks
            section. <br />
          </p>
          <strong>Note</strong>: Overseas partners to{' '}
          <strong>include shipping cost</strong> to Factorem in their quotes as
          well <br />
          <br />
          You may choose to add more variations to your quotes by clicking “ADD
          MORE QUOTES”. This is useful when you wish to recommend a different
          material/process/change to the part to be manufactured for reasons
          such as reduced lead time, lower cost etc. <br />
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={SupplierFAQ3Img}
              alt="'Add' button to add more quotes"
              style={{ width: '900px', height: 'auto' }}
            />
          </div>
        </>
      ),
    },
    {
      faqQn:
        'What is the difference between a confirmed project job and an unconfirmed project job.',
      faqAns: (
        <>
          A <strong>confirmed</strong> project job has already been priced and
          accepted by Customer. It requires no further input besides any
          remarks/clarifications, kindly accept the job if you are able to
          fulfill all requirements. For more info on how to accept a confirmed
          job, click{' '}
          <a
            href='https://scribehow.com/shared/How_to_Accept_a_Confirmed_Job__9TlNPkE3SZWdA3mSyZ4QUA'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .<br />
          <br />A <strong>unconfirmed</strong> project job requires a standard
          quotation, kindly input all relevant information necessary. For a
          tutorial on how to submit a quote click{' '}
          <a
            href='https://scribehow.com/shared/How_to_Submit_a_Quote_on_Factorem_Platform_Onboarding__l8_rf5eKRiS5xpEVQbHZ9g'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      faqQn: 'What happens if my quotation is rejected?',
      faqAns: (
        <>
          In the case where a quotation is rejected, it&apos;s likely the
          Customer found a better offer elsewhere or decided to postpone
          fabrication. Your quote status will change from &quot;Pending&quot; to
          &quot;Rejected,&quot; and you&apos;ll be informed of the reason on why
          your quote was rejected.
        </>
      ),
    },
    {
      faqQn: 'What happens if my quotation is accepted?',
      faqAns: (
        <>
          Upon quotation acceptance, you&apos;ll receive a Purchase Order (PO)
          via Factorem Platform and email. To proceed with fabrication, kindly
          acknowledge the PO on the platform by clicking
          &quot;acknowledge&quot;. For an in-depth tutorial on how to
          acknowledge POs, click{' '}
          <a
            href='https://scribehow.com/shared/How_to_View_and_Acknowledge_POs__M6-bW98uS1ePM1clx2DV5Q'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      faqQn: 'Can I still quote for a part if the deadline is over?',
      faqAns: (
        <>
          Yes. Parts that you see under Browse Parts remain as active orders for
          which Factorem Customers are still sourcing quotations for. Feel free
          to quote for anything that you see that meets your fabrication
          capabilities!
        </>
      ),
    },
    {
      faqQn: 'I’ve made a mistake in my quotation, how can I requote?',
      faqAns: (
        <>
          You can delete the unwanted quote in the my quotes page, the delete
          quote button can be found by scrolling to the right of the page, you
          may then submit a new quote accordingly. <br />
          <br />
          However, if you are unable to delete your quote, kindly contact
          operations team via WhatsApp/email (
          <a
            href='https://api.whatsapp.com/send?phone=6588391796&text='
            target='_blank'
            rel='noreferrer'
          >
            +65 8839 1796
          </a>
          /
          <a href='mailto:partner@factorem.co' target='_blank' rel='noreferrer'>
            partner@factorem.co
          </a>
          ) and we will amend the quote on your behalf.
        </>
      ),
    },
    {
      faqQn: 'What if I need to make clarifications before quoting?',
      faqAns: (
        <>
          In the case where you are unable to make a quote and need to make
          clarifications, please do the following: <br />
          <p>
            1. Reply directly to the RFQ email, and reference to{' '}
            <strong>Part ID &lt;4 digit&gt;</strong> <br />
            <br />
            2. Please allow 1 working day for our engineering team to get back
            to you. The part description may be edited to provide you better
            understanding of the fabrication requirements.
          </p>
        </>
      ),
    },
  ];
  const quotationFaqsStartIndex = 1;
  const quotationFaqsEndIndex = quotationFaqsStartIndex + quotationFaqs.length;

  const fabricationFaqs = [
    {
      faqQn: 'When can I start fabrication?',
      faqAns: (
        <>
          Once the Purchase Order (PO) is acknowledged, you may start
          fabrication according to the specified delivery date.
        </>
      ),
    },
    {
      faqQn: 'Why do some drawings have missing dimensions?',
      faqAns: (
        <>
          Usually, Customers only label key dimensions in their drawings.
          Further dimensions should be taken from the CAD model following
          Factorem&apos;s{' '}
          <a
            href='https://factorem.medium.com/factorems-iso-2768-machining-guidelines-74a44cc75e5b'
            target='_blank'
            rel='noreferrer'
          >
            ISO 2768 Medium standard
          </a>
          . If there are any further queries please reach out to{' '}
          <a href='mailto:partner@factorem.co' target='_blank' rel='noreferrer'>
            partner@factorem.co
          </a>
          , and reference to <strong>Part ID &lt;4 digit&gt;</strong>
        </>
      ),
    },
    {
      faqQn: 'What are the standard manufacturing tolerances I should follow?',
      faqAns: (
        <>
          Any dimensions not indicated on design files will be kept to
          Factorem&apos;s{' '}
          <a
            href='https://factorem.medium.com/factorems-iso-2768-machining-guidelines-74a44cc75e5b'
            target='_blank'
            rel='noreferrer'
          >
            ISO 2768 Standard
          </a>
          .
        </>
      ),
    },
    {
      faqQn: 'How long do I have to fabricate a requested part?',
      faqAns: (
        <>
          Factorem Customers usually have an expected deadline by which they
          wish to receive the requested parts. <br />
          <br />
          Please have a look at the expected deadline on the respective product
          page before proceeding to submit a quotation.
        </>
      ),
    },
    {
      faqQn: 'What happens after I am done with fabrication?',
      faqAns: (
        <>
          Once the part is fabricated, you can start the Order Ready process on
          the Factorem Platform and submit the part images and QC Report (if
          required) for review and verification by our team. You will be
          notified if updates are required. Once it is verified, download your
          shipping documents and{' '}
          <strong>
            ship your items out according to Factorem&apos;s delivery process.
          </strong>{' '}
          <br />
          <br />
          Upload your invoice for the specified Purchase Order (PO){' '}
          <strong>after shipping your order</strong> and clicking “ORDER
          SHIPPED” on Order Ready tab. <br />
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={SupplierFAQ14Img}
              alt="'Order Shipped' button"
              style={{ width: '700px', height: 'auto' }}
            />
          </div>
          <br />
          You will be notified if updates are required. For an In-depth tutorial
          of click{' '}
          <a
            href='https://scribehow.com/shared/Guide_1_How_to_Submit_Order_Completion_Details_once_your_Order_is_Ready__8xKD5aPRRMqf9X0Ue5Qh3Q'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      faqQn: 'How do I retrieve shipping documents?',
      faqAns: (
        <>
          You may download your shipping documents from the Deliver tab under
          “Order Ready Status” in the Accepted Quotes page. For an in-depth
          guide click{' '}
          <a
            href='https://bit.ly/get-shipping-documents'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={SupplierFAQ15_1Img}
              alt="'Shipped' status"
              style={{ width: '250px', height: 'auto' }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img
              src={SupplierFAQ15_2Img}
              alt="'Deliver' tab"
              style={{ width: '550px', height: 'auto' }}
            />
          </div>
        </>
      ),
    },
  ];
  const fabricationFaqsStartIndex = quotationFaqsEndIndex + 1;
  const fabricationFaqsEndIndex =
    fabricationFaqsStartIndex + fabricationFaqs.length;

  const postFabricationFaqs = [
    {
      faqQn: 'What is the shipment procedure?',
      faqAns: (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={SupplierFAQ17_1Img}
              alt='Shipping Guidelines Image'
              style={{ width: '700px', height: 'auto' }}
            />
          </div>
          <br />
          <br />
          Refer also to the question titled: What is the delivery process like?
        </>
      ),
    },
    {
      faqQn: 'How can I ensure my parts are delivered safely?',
      faqAns: (
        <>
          Simply follow{' '}
          <a
            href={SHIPPING_GUIDELINES}
            target='_blank'
            onClick={async (event) => {
              event.stopPropagation();
              event.preventDefault();
              await generatePresignedUrl(
                SHIPPING_GUIDELINES,
                ONE_MONTH_IN_SECONDS,
                true
              ) // ~1 month expiration
                .then(openInNewTab);
            }}
            rel='noreferrer'
          >
            these guidelines!
          </a>
        </>
      ),
    },
    {
      faqQn: 'What happens if I am unable to deliver on time?',
      faqAns: (
        <>
          At Factorem we pride ourselves and our Partner Network for getting
          quality part done on-time, every time. <br />
          <br />
          In the unfortunate event where there is a lapse, please reach out to
          our Partner team via WhatsApp (
          <a
            href='https://api.whatsapp.com/send?phone=6588391796&text='
            target='_blank'
            rel='noreferrer'
          >
            +65 8839 1796
          </a>
          ) or email{' '}
          <a href='mailto:partner@factorem.co' target='_blank' rel='noreferrer'>
            partner@factorem.co
          </a>{' '}
          to inform us of the situation as soon as you can. <br />
          <br />A penalty might also be imposed depending on the urgency of the
          Customer&apos;s order.
        </>
      ),
    },
    {
      faqQn: 'Why is my part pending review?',
      faqAns: (
        <>
          The submitted part images and QC report (if required) are currently
          being reviewed and verified by our team. Your patience is appreciated
          as we ensure that the part meets its specifications.
        </>
      ),
    },
    {
      faqQn: 'When do I receive the payment for parts I fabricate?',
      faqAns: (
        <>
          Factorem disburses payment to the Partner once the requested part is
          successfully delivered. Payment is made within 30 days after delivery,
          as specified in the Partnership agreement. <br />
          <br />
          To receive prompt payment, kindly ensure your invoice is{' '}
          <strong>UPLOADED onto the platform</strong> after your item is shipped
          out. We do not accept any invoices sent over email. For any questions
          related to finance matters, please contact Ms. Wenda at{' '}
          <a href='mailto:finance@factorem.co' target='_blank' rel='noreferrer'>
            finance@factorem.co
          </a>
          . <br />
          <br />
          For an in-depth guide on uploading your invoice, click{' '}
          <a
            href='https://scribehow.com/shared/How_to_upload_your_invoice_upon_Order_Completion__DfhMMHoHQnmlOlQzfrb-QA'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      faqQn: 'What is the delivery process like?',
      faqAns: (
        <>
          <strong>For Local Partners</strong>: Factorem’s delivery partners will
          collect the order from your doorstep, unless otherwise stated. You
          will be informed of when the delivery partner will reach your facility
          for item collection. <br />
          <br />
          <strong>For Overseas Partners</strong>: Please use your preferred
          courier service and input the shipment provider and tracking number on
          the Order Ready tab <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={SupplierFAQ19_1Img}
              alt="'Shipment Provider' and 'Shipment Tracking Number' fields"
              style={{ width: '700px', height: 'auto' }}
            />
          </div>
          <br />
          <strong>Important</strong>: Prepare for collection (local)/ shipping
          (overseas) by printing and attaching a Factorem Delivery Order (DO)
          and Waybill (if stated) that can be downloaded from the deliver tab to
          the order. <br />
          <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={SupplierFAQ19_2Img}
              alt='Delivery Order and Waybill'
              style={{ width: '700px', height: 'auto' }}
            />
          </div>
          <br />
          <br />
          Kindly{' '}
          <strong>
            refrain from labeling boxes and parts with your company
            information/logos
          </strong>{' '}
          and <strong>ensure that all parts are securely packaged</strong> to
          reduce any damage during the shipping process. For more info on how to
          ship your order click{' '}
          <a
            href='https://scribehow.com/shared/Guide_2_How_to_get_shipment_documents_and_ship_order_upon_Order_Verification__wEmcjrRST76kZatZ0ysY1Q'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      faqQn:
        "What are Factorem's charges for shipment in case the order I completed requires rework?",
      faqAns: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={SupplierFAQ21_1Img}
            alt='Shipping Costs Guidelines'
            style={{ width: '700px', height: 'auto' }}
          />
        </div>
      ),
    },
    {
      faqQn: 'How do I pay for costs incurred due to rework?',
      faqAns: (
        <> Factorem will deduct the exact amount from your next payout.</>
      ),
    },
  ];
  const postFabricationFaqsStartIndex = fabricationFaqsEndIndex + 1;
  const postFabricationFaqsEndIndex =
    postFabricationFaqsStartIndex + postFabricationFaqs.length;

  const miscellaneousFaqs = [
    {
      faqQn: 'Where can I find the Partner Onboarding guide? ',
      faqAns: (
        <>
          Click{' '}
          <a
            href='https://docs.google.com/document/d/1D6o7ekzELP54dTgir6ThjaJb5G3ah5I0FWiIOjS04gg/edit?usp=sharing'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>{' '}
          to view the Factorem Partner Onboarding guide.
        </>
      ),
    },
    {
      faqQn: 'Additional contact information',
      faqAns: (
        <>
          <strong>Phone Number</strong>: WhatsApp (
          <a
            href='https://api.whatsapp.com/send?phone=6588391796&text='
            target='_blank'
            rel='noreferrer'
          >
            +65 8839 1796
          </a>
          ) <br />
          <br />
          <strong>Email</strong> <br />
          <strong>Partner inquiries</strong> :{' '}
          <a href='mailto:partner@factorem.co' target='_blank' rel='noreferrer'>
            partner@factorem.co
          </a>{' '}
          <br />
          <strong>Onboarding</strong> :{' '}
          <a
            href='mailto:onboarding@factorem.co'
            target='_blank'
            rel='noreferrer'
          >
            onboarding@factorem.co
          </a>{' '}
          <br />
          <strong>Finance</strong> :{' '}
          <a href='mailto:finance@factorem.co' target='_blank' rel='noreferrer'>
            finance@factorem.co
          </a>
        </>
      ),
    },
    {
      faqQn: 'Unsure of how much to quote for?',
      faqAns: (
        <>
          We have designed the Factorem Price Predictor with in-house machine
          learning algorithms to attain prices close to that of the industrial
          standards. <br />
          <br />
          Simply upload your 3D CAD file to the price predictor and select the
          appropriate machining features to see the estimated cost.
        </>
      ),
    },
    {
      faqQn: 'How can I refer a new partner to join Factorem’s network?',
      faqAns: (
        <>
          Direct them to our onboarding page!{' '}
          <a
            href='http://onboarding.factorem.co/'
            target='_blank'
            rel='noreferrer'
          >
            http://onboarding.factorem.co
          </a>
        </>
      ),
    },
  ];
  const miscellaneousFaqsStartIndex = postFabricationFaqsEndIndex + 1;
  // eslint-disable-next-line no-unused-vars
  const miscellaneousFaqsEndIndex =
    miscellaneousFaqsStartIndex + miscellaneousFaqs.length;

  return (
    <div>
      <Grid container direction='column' alignItems='center'>
        <Container
          style={{
            padding: '30px',
            display: 'flex',
            justifyContent: 'spaceBetween',
          }}
        >
          <FAQTitleButton
            faqImage={quoteImage}
            changeImage={() =>
              setQuoteImage(
                currentPage === 'Quotation' ? QuoteBlueIcon : QuoteGreyIcon
              )
            }
            faqText='Quotation'
            isSelected={currentPage === 'Quotation'}
            onClick={() => setCurrentPage('Quotation')}
          />
          <FAQTitleButton
            faqImage={servicesImage}
            changeImage={() =>
              setServicesImage(
                currentPage === 'Fabrication'
                  ? ServicesBlueIcon
                  : ServicesGreyIcon
              )
            }
            faqText='Fabrication'
            isSelected={currentPage === 'Fabrication'}
            onClick={() => setCurrentPage('Fabrication')}
          />
          <FAQTitleButton
            faqImage={deliveryImage}
            changeImage={() =>
              setDeliveryImage(
                currentPage === 'PostFabrication'
                  ? DeliveryBlueIcon
                  : DeliveryGreyIcon
              )
            }
            faqText='Post-Fabrication'
            isSelected={currentPage === 'PostFabrication'}
            onClick={() => setCurrentPage('PostFabrication')}
          />
          <FAQTitleButton
            faqImage={aboutFactoremImage}
            changeImage={() =>
              setAboutFactoremImage(
                currentPage === 'Miscellaneous'
                  ? AboutFactoremBlueIcon
                  : AboutFactoremGreyIcon
              )
            }
            faqText='Miscellaneous'
            isSelected={currentPage === 'Miscellaneous'}
            onClick={() => setCurrentPage('Miscellaneous')}
          />
        </Container>
        <Grid item>
          {currentPage === 'Quotation' ? (
            <FAQDescription headingText='QUOTATION' />
          ) : currentPage === 'Fabrication' ? (
            <FAQDescription headingText='FABRICATION' />
          ) : currentPage === 'PostFabrication' ? (
            <FAQDescription headingText='POST-FABRICATION' />
          ) : (
            <FAQDescription headingText='MISCELLANEOUS' />
          )}
        </Grid>
        <div
          style={{
            width: isMobile ? '80vw' : '80%',
            height: '55vh',
            overflow: 'scroll',
            marginBottom: isMobile ? '100px' : 0,
          }}
        >
          <Grid item zeroMinWidth style={{ height: '80%' }}>
            {currentPage === 'Quotation' ? (
              <Fragment>
                {quotationFaqs.map((faq, idx) => {
                  const faqIndex = `faq${idx + quotationFaqsStartIndex}`;
                  return (
                    <FAQAccordion
                      key={idx}
                      {...faq}
                      expanded={expanded === faqIndex}
                      onChange={handleChange(faqIndex)}
                    />
                  );
                })}
              </Fragment>
            ) : currentPage === 'Fabrication' ? (
              <Fragment>
                {fabricationFaqs.map((faq, idx) => {
                  const faqIndex = `faq${idx + fabricationFaqsStartIndex}`;
                  return (
                    <FAQAccordion
                      key={idx}
                      {...faq}
                      expanded={expanded === faqIndex}
                      onChange={handleChange(faqIndex)}
                    />
                  );
                })}
              </Fragment>
            ) : currentPage === 'PostFabrication' ? (
              <Fragment>
                {postFabricationFaqs.map((faq, idx) => {
                  const faqIndex = `faq${idx + postFabricationFaqsStartIndex}`;
                  return (
                    <FAQAccordion
                      key={idx}
                      {...faq}
                      expanded={expanded === faqIndex}
                      onChange={handleChange(faqIndex)}
                    />
                  );
                })}
              </Fragment>
            ) : (
              <Fragment>
                {miscellaneousFaqs.map((faq, idx) => {
                  const faqIndex = `faq${idx + miscellaneousFaqsStartIndex}`;
                  return (
                    <FAQAccordion
                      key={idx}
                      {...faq}
                      expanded={expanded === faqIndex}
                      onChange={handleChange(faqIndex)}
                    />
                  );
                })}
              </Fragment>
            )}
          </Grid>
        </div>
        <Typography
          style={{
            backgroundColor: isMobile ? '' : '#f9f9f9',
            position: 'fixed',
            bottom: 0,
            left: isMobile ? 0 : 200,
            right: isMobile ? 40 : 0,
            padding: '20px',
            textAlign: 'center',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
            letterSpacing: '0.15em',
          }}
        >
          Have questions that are not addressed? Contact&nbsp;
          <a
            href='mailto:hello@factorem.co'
            target='_blank'
            rel='noreferrer'
            style={{ color: `${colors.blue060}` }}
          >
            hello@factorem.co
          </a>
        </Typography>
      </Grid>
    </div>
  );
}

export default SupplierFAQPage;

import { useState, useRef, useCallback, useEffect } from 'react';
import * as fabric from 'fabric';
import { domToPng } from 'modern-screenshot';

import jsPDF from 'jspdf';

import { isEmptyValue } from '../utils/commonUtils';
import { pdfFlatten } from '../apis/pdfApi';
import { notifySuccess, notifyError } from '../services/notificationService';
import { asyncMap } from '../utils/arrayUtils';

function useModifyPdfCanvas() {
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrPage] = useState(1);
  const [canvas, setCanvas] = useState(null);
  const [processingPdf, setProcessingPdf] = useState({
    state: false,
    process: 'Uploading',
  });
  const canvasData = useRef({});
  const canvasObjectDimensions = useRef({});
  const pdfObject = useRef({});

  const initCanvas = (node) => {
    const option = {
      isDrawingMode: false,
      backgroundColor: 'rgba(0,0,0,0)',
    };

    const newCanvas = new fabric.Canvas(node, option);
    newCanvas?.requestRenderAll();
    setCanvas(newCanvas);
  };

  // all the canvas and page refs
  const pdfCanvasRef = useRef(null);
  const printableRef = useRef(null);
  const canvasWrapperRef = useRef(null);
  const overlayCanvasRef = useCallback(
    (node) => {
      if (!isEmptyValue(node)) {
        initCanvas(node);
      }
    },
    [open]
  );

  const clearCanvas = () => {
    canvas.clear();
  };

  // all the canvas modification fns
  useEffect(() => {
    if (canvas) {
      clearCanvas();
      if (canvasData.current[currentPage]) {
        canvas.loadFromJSON(canvasData.current[currentPage]);
      }
      canvas.requestRenderAll();
    }
  }, [currentPage]);

  const addRect = (dimension = {}) => {
    if (isEmptyValue(canvas)) {
      console.error('Canvas not initialized!');
      return;
    }

    let option = {
      top: dimension.top || 0,
      left: dimension.left || 0,
      height: dimension.height || 180,
      width: dimension.width || 200,
      fill: '#fff',
      editable: true,
      strokeWidth: 0,
    };

    const rect = new fabric.Rect(option);
    canvas.add(rect);
    canvas.isDrawingMode = false;
    canvas.requestRenderAll();
  };

  const addTitleblock = async ({ content, dimension = {} }) => {
    const titleBlockImg = await domToPng(content);
    const canvasImage = await fabric.FabricImage.fromURL(titleBlockImg);

    if (!isEmptyValue(dimension)) {
      const { top, left, width } = dimension;
      canvasImage.scaleToWidth(width);
      canvasImage.set({
        top,
        left,
      });
    } else {
      canvasImage.scaleToWidth(canvas.getWidth() * 0.5);
    }
    canvas.add(canvasImage);
    canvas.requestRenderAll();
  };

  const deleteSelected = () => {
    const activeObject = canvas.getActiveObject();
    if (!isEmptyValue(activeObject)) {
      canvas.remove(activeObject);
    }
  };

  const setCanvasDimension = (pdf) => {
    const { width, height, rotate } = pdf;
    const rotated = rotate !== 0;
    const [w, h] = rotated ? [height, width] : [width, height];

    canvas.setDimensions({
      width: w,
      height: h,
    });
  };

  const storeCurrentPageCanvasState = (prevPage) => {
    const prevPageCanvas = canvas.toObject();
    const prevPageObjects = canvas.getObjects().map((obj) => {
      return {
        top: obj.aCoords.tl.y,
        left: obj.aCoords.tl.x,
        width: obj.aCoords.tr.x - obj.aCoords.tl.x,
        height: obj.aCoords.bl.y - obj.aCoords.tl.y,
        isTitleblock: obj.type === 'image',
      };
    });

    canvasData.current = {
      ...canvasData.current,
      [prevPage]: prevPageCanvas,
    };

    canvasObjectDimensions.current = {
      ...canvasObjectDimensions.current,
      [prevPage]: prevPageObjects,
    };
  };

  const changePage = (newPage) => {
    setCurrPage((prevPage) => {
      storeCurrentPageCanvasState(prevPage);
      return newPage;
    });
  };

  const drawCanvasObjects = async (data, titleblock) => {
    await asyncMap(data, async (obj) => {
      const { top, left, width, height, isTitleblock } = obj;
      if (isTitleblock) {
        await addTitleblock({
          content: titleblock,
          dimension: {
            top,
            left,
            width,
            height,
          },
        });
      } else {
        await addRect({ top, left, width, height });
      }
    });
  };

  const processCanvasObj = async (obj, portraitContent, landscapeContent) => {
    const titleblockObj = obj.find((obj) => obj.isTitleblock);
    let titleblock;

    if (!isEmptyValue(titleblockObj)) {
      titleblock =
        titleblockObj.width > titleblockObj.height
          ? landscapeContent
          : portraitContent;
    }

    await drawCanvasObjects(obj, titleblock);
  };

  const addPreviousCanvas = async ({
    canvasData,
    portraitContent,
    landscapeContent,
  }) => {
    setProcessingPdf({
      state: true,
      process: 'Processing',
    });

    const [firstObj, ...otherPagesObj] = Object.values(canvasData);
    await processCanvasObj(firstObj, portraitContent, landscapeContent);

    await asyncMap(otherPagesObj, async (obj, index) => {
      const page = index + 2; // because first page has been eagerly drawn
      await changePage(+page);

      return new Promise((resolve) =>
        setTimeout(async () => {
          await processCanvasObj(obj, portraitContent, landscapeContent);
          resolve();
        }, pdfObject.current.processingDelay)
      );
    });

    setProcessingPdf((prevState) => {
      return {
        process: prevState.process,
        state: false,
      };
    });
  };

  // pdf processor handler

  const preparePdf = () => {
    const width = canvasWrapperRef.current.clientWidth;
    const height = canvasWrapperRef.current.clientHeight;

    const pdfOption = {
      orientation: width > height ? 'landscape' : 'portrait',
      unit: 'px',
      hotfixes: ['px_scaling'],
      format: [width, height],
      compress: true, // Ensure compression
    };
    const pdf = new jsPDF(pdfOption);
    pdfObject.current = {
      doc: pdf,
      dimension: {
        width,
        height,
      },
    };
  };

  const saveImagesToPdf = async () => {
    canvasWrapperRef.current.style.visibility = 'hidden'; // hide the wrapper from the dom so it's not copied
    const pdfImage = await domToPng(printableRef.current);
    const { doc, dimension } = pdfObject.current;
    const { width, height } = dimension;

    const canvasImage = canvas.toDataURL({
      format: 'jpg',
      multiplier: 3, // Higher multiplier for better quality
    });

    doc.addImage(pdfImage, 'JPG', 0, 0, width, height, undefined, 'FAST');
    doc.addImage(canvasImage, 'JPG', 0, 0, width, height, undefined, 'FAST');
  };

  const processPdfPages = async () => {
    try {
      const pdfImage = await domToPng(printableRef.current);
      // console.log('pdf image size', pdfImage.length); // -- debugging page size

      for (let i = 1; i <= numPages; i++) {
        await changePage(i);
        // Wait for the page to render before capturing
        pdfObject.current = {
          ...pdfObject.current,
          processingDelay: pdfImage.length >= 150000 ? 7000 : 3000,
        };

        // console.log('delay', pdfObject.current.processingDelay) //-- debugging processing delay
        await new Promise((resolve) =>
          setTimeout(resolve, pdfObject.current.processingDelay)
        ); // Adjust delay if needed
        await saveImagesToPdf();

        if (i < numPages) {
          pdfObject.current.doc.addPage();
          pdfObject.current.doc.setPage(i + 1);
        }
      }
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  };

  const createPdf = async ({
    previewPdf = false,
    itemID,
    editCadFile,
    setEditCadFile = () => {},
    handleClose = () => {},
    revision,
    setCanvasData = () => {},
  } = {}) => {
    let stringOfLinks = [];
    let modificationSuccess = true;

    if (editCadFile) {
      stringOfLinks = [...editCadFile];
    }

    setProcessingPdf({
      state: true,
      process: previewPdf ? 'Previewing' : 'Uploading',
    });
    preparePdf();

    try {
      await processPdfPages();
      if (previewPdf) {
        const blob = pdfObject.current.doc.output('blob');
        window.open(URL.createObjectURL(blob), '_blank');
      } else {
        const blob = pdfObject.current.doc.output('blob');
        const formData = new FormData();
        const file = new File([blob], { type: 'application/pdf' });
        formData.append('filename', `${itemID}${revision}.pdf`);
        formData.append('file', file);
        formData.append('flattenPdf', false);
        const { data } = await pdfFlatten(formData);
        stringOfLinks.push(data.split(' ').join('%20'));
      }
    } catch (error) {
      console.error('Error creating PDF:', error);
      modificationSuccess = false;
    } finally {
      setEditCadFile(stringOfLinks);

      setProcessingPdf((prevState) => {
        return {
          process: prevState.process,
          state: false,
        };
      });

      canvasWrapperRef.current.style.visibility = 'visible';

      if (modificationSuccess) {
        if (editCadFile) {
          notifySuccess(
            'Modified file is uploaded successfully! Please verify the part to complete the process.'
          );
          setCanvasData({
            canvasData: canvasObjectDimensions.current,
          });
        }

        handleClose();
      } else {
        notifyError('File modification failed!');
      }
    }
  };

  const onDocumentLoadSuccess = async (numPages) => {
    setNumPages(numPages);
  };

  return {
    canvas,
    setCanvas,
    addRect,
    deleteSelected,
    clearCanvas,
    setCanvasDimension,
    addTitleblock,
    numPages,
    currentPage,
    changePage,
    pdfCanvasRef,
    printableRef,
    overlayCanvasRef,
    canvasWrapperRef,
    processingPdf,
    createPdf,
    onDocumentLoadSuccess,
    addPreviousCanvas,
  };
}

export default useModifyPdfCanvas;

import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Box } from '@material-ui/core';

import CustomerProjectsSearchResult from './CustomerProjectsSearchResult';
import EmptyQuote from './EmptyQuote';
import FtrTabs from '../../components/ftr-components/FtrTabs';
import ManageProjectsQuotationTabPage from './ManageProjectsQuotationTabPage';
import ManageProjectsOrdersInProgressTabPage from './ManageProjectsOrdersInProgressTabPage';
import ManageProjectsOrdersCompleteTabPage from './ManageProjectsOrdersCompleteTabPage';
import FtrSearchBar from '../../components/ftr-components/FtrSearchBar';
import FtrCheckboxDropdown from '../../components/ftr-components/FtrCheckboxDropdown';
import {
  FlexColumn,
  FlexRow,
  FlexRowSpaceBetween,
} from '../../components/layouts/FlexLayouts';
import { FtrH7 } from '../../components/ftr-components';
import ShipmentCalendarIcon from '../../components/icons/ShipmentCalendarIcon';

import withManageProjectsRevampBannerHOC from '../../hocs/withManageProjectsRevampBannerHOC';

import { getAllProjectsByUser } from '../../apis/projectApi';
import { getAllOrderByCustomerApi } from '../../apis/multiCheckoutApi';

import { getUserIDSelector } from '../../selectors/userSelector';

import { transformArrayToMapObject } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getLatestDeliveryDateForMct, isPpeItem } from '../../utils/itemUtils';

import {
  DATE_CREATED_FILTER_LIST,
  DEFAULT_ITEM_STATUS_FILTER_LIST,
} from '../../constants/filterConstants';
import { ITEM_STATUS_MAPPING } from '../../constants';

import { colors } from '../../palette';

import AppContext from '../../context/AppContext';

// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  body: {
    height: '100vh',
    padding: '2rem',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TABS = ['Quotation', 'In-Progress', 'Completed'];

function ManageProjectsV2() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const userID = useSelector(getUserIDSelector);

  const queryClient = useQueryClient();

  const { manageProjectsSelectedTab, setManageProjectsSelectedTab } =
    useContext(AppContext);

  const [searchStr, setSearchStr] = useState('');
  const [statusFilterList, setStatusFilterList] = useState([]);
  const [createdDateFilter, setCreatedDateFilter] = useState(null);

  const { data: allProjects = [], status: projectsLoadingStatus } = useQuery(
    ['getAllProjectsByUser', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getAllProjectsByUser(userID);
    }
  );

  const allProjectMapping = useMemo(() => {
    if (isEmptyValue(allProjects)) {
      return {};
    }

    return transformArrayToMapObject(allProjects, 'projectID');
  }, [allProjects]);

  const allItemsMapping = useMemo(() => {
    if (isEmptyValue(allProjects)) {
      return {};
    }

    const allItems = allProjects.map((project) => project.items).flat();

    return transformArrayToMapObject(allItems, 'itemID');
  }, [allProjects]);

  const quotationProjects = allProjects.filter((project) => {
    return project.items.some((item) => {
      return (
        [
          ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
          ITEM_STATUS_MAPPING.SOURCING,
        ].includes(item.status) ||
        (ITEM_STATUS_MAPPING.VERIFYING === item.status && !isPpeItem(item))
      );
    });
  });

  const { status: ordersLoadingStatus, data: allOrders = [] } = useQuery(
    ['allOrdersByCustomer', userID],
    () => getAllOrderByCustomerApi(userID)
  );

  const allOrdersInProgressWithProjectInfo = useMemo(() => {
    if (isEmptyValue(allOrders)) {
      return [];
    }

    return allOrders
      .filter((order) => {
        const project = allProjectMapping[order.projectID];
        if (isEmptyValue(project)) {
          return false;
        }
        return true;
      })
      .map((order) => {
        const project = allProjectMapping[order.projectID];
        const projectItems = project?.items || [];
        const mctItems = order.acceptedItems.map((ai) => {
          const itemID = ai.itemID;
          const item = projectItems.find((i) => i.itemID === itemID) || {};
          return {
            itemID,
            ...item,
          };
        });

        return {
          ...order,
          ...project,
          items: mctItems,
          twoDImageUrls: order.acceptedItems.map((ai) => {
            const itemID = ai.itemID;
            const item = projectItems.find((i) => i.itemID === itemID);
            return item?.imageUrl;
          }),
        };
      })
      .filter((order) => !checkOrderDelivered(order));
  }, [allOrders, allProjectMapping]);

  const allOrdersCompletedWithProjectInfo = useMemo(() => {
    if (isEmptyValue(allOrders)) {
      return [];
    }

    return allOrders
      .filter((order) => {
        const project = allProjectMapping[order.projectID];
        if (isEmptyValue(project)) {
          return false;
        }
        return true;
      })
      .map((order) => {
        const project = allProjectMapping[order.projectID];
        const projectItems = project?.items || [];
        const mctItems = order.acceptedItems.map((ai) => {
          const itemID = ai.itemID;
          const item = projectItems.find((i) => i.itemID === itemID) || {};
          return {
            itemID,
            ...item,
          };
        });

        const latestDeliveryDate = getLatestDeliveryDateForMct(mctItems);

        return {
          ...order,
          ...project,
          items: mctItems,
          twoDImageUrls: order.acceptedItems.map((ai) => {
            const itemID = ai.itemID;
            const item = projectItems.find((i) => i.itemID === itemID);
            return item?.imageUrl;
          }),
          latestDeliveryDate,
        };
      })
      .filter(checkOrderDelivered);
  }, [allOrders, allProjectMapping]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries('allOpenCompletedProjects');
    };
  }, []);

  function checkOrderDelivered(order) {
    return order.items.every(
      (item) => item.status === ITEM_STATUS_MAPPING.DELIVERED
    );
  }

  const renderFilterRow = () => {
    return (
      <FlexRow style={{ marginBottom: '1.5rem' }}>
        <FtrCheckboxDropdown
          id='project-status-created-date-dropdown'
          label='Date Created'
          items={DATE_CREATED_FILTER_LIST}
          handleChange={(value) => {
            const createdDateFilter = value
              .filter((o) => o.checked)
              .map((o) => o.label);
            setCreatedDateFilter(createdDateFilter[0]);
          }}
          showCheckbox={false}
        />
        <FtrCheckboxDropdown
          id='project-status-filter-dropdown'
          label='Project Status'
          items={DEFAULT_ITEM_STATUS_FILTER_LIST}
          handleChange={(value) => {
            const statusFilterList = value
              .filter((o) => o.checked)
              .map((o) => o.label);
            setStatusFilterList(statusFilterList);
          }}
        />
        <ShipmentCalendarIcon />
      </FlexRow>
    );
  };

  const renderQuotation = () => {
    if (isEmptyValue(quotationProjects)) {
      return <EmptyQuote />;
    }

    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        {(!isEmptyValue(allProjects) || !isEmptyValue(quotationProjects)) &&
          renderFilterRow()}
        <FlexColumn
          style={{
            flex: '1 1 auto',
            justifyContent: isEmptyValue(quotationProjects)
              ? 'center'
              : 'start',
          }}
        >
          <ManageProjectsQuotationTabPage
            loading={projectsLoadingStatus === 'loading'}
            projects={quotationProjects}
            searchStr={searchStr}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
          />
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderOrdersInProgress = () => {
    if (isEmptyValue(allOrdersInProgressWithProjectInfo)) {
      return (
        <EmptyQuote text='Ongoing orders will appear here once the purchase is made.' />
      );
    }

    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        {(!isEmptyValue(allProjects) ||
          !isEmptyValue(allOrdersInProgressWithProjectInfo)) &&
          renderFilterRow()}
        <FlexColumn
          style={{
            flex: '1 1 auto',
            justifyContent: isEmptyValue(allOrdersInProgressWithProjectInfo)
              ? 'center'
              : 'start',
          }}
        >
          <ManageProjectsOrdersInProgressTabPage
            loading={ordersLoadingStatus === 'loading'}
            projects={allOrdersInProgressWithProjectInfo}
            searchStr={searchStr}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
            allItemsMapping={allItemsMapping}
            isMobile={isMobile}
          />
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderOrdersCompleted = () => {
    if (isEmptyValue(allOrdersCompletedWithProjectInfo)) {
      return (
        <EmptyQuote text='Completed orders will appear here upon delivered.' />
      );
    }

    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        {(!isEmptyValue(allProjects) ||
          !isEmptyValue(allOrdersCompletedWithProjectInfo)) &&
          renderFilterRow()}
        <FlexColumn
          style={{
            flex: '1 1 auto',
            justifyContent: isEmptyValue(allOrdersCompletedWithProjectInfo)
              ? 'center'
              : 'start',
          }}
        >
          <ManageProjectsOrdersCompleteTabPage
            loading={ordersLoadingStatus === 'loading'}
            projects={allOrdersCompletedWithProjectInfo}
            searchStr={searchStr}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
            allItemsMapping={allItemsMapping}
            isMobile={isMobile}
          />
        </FlexColumn>
      </FlexColumn>
    );
  };

  const renderTab = () => {
    return (
      <>
        {manageProjectsSelectedTab === 0 && renderQuotation()}
        {manageProjectsSelectedTab === 1 && renderOrdersInProgress()}
        {manageProjectsSelectedTab === 2 && renderOrdersCompleted()}
      </>
    );
  };

  const renderCustomerProjectsSearchResult = () => {
    return (
      <FlexColumn style={{ flex: '1 1 auto' }}>
        <CustomerProjectsSearchResult
          searchStr={searchStr}
          statusFilterList={statusFilterList}
          createdDateFilter={createdDateFilter}
          quotationProjects={quotationProjects}
          orderInProgressProjects={allOrdersInProgressWithProjectInfo}
          orderCompletedProjects={allOrdersCompletedWithProjectInfo}
        />
      </FlexColumn>
    );
  };

  return (
    <div className={classes.body}>
      <FtrH7>Manage Projects</FtrH7>
      <FlexRowSpaceBetween
        style={{
          position: 'relative',
          marginTop: isMobile ? '1rem' : '2rem',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'start' : 'space-between',
        }}
      >
        <div style={{ width: '100%', overflow: 'hidden' }}>
          <FtrTabs.FtrDivider style={{ bottom: 8 }} />
          <FtrTabs
            itemList={TABS.map((tab) => {
              return {
                key: tab,
                text: tab,
              };
            })}
            value={manageProjectsSelectedTab}
            onChange={(newValue) => {
              setStatusFilterList([]);
              setSearchStr('');
              setManageProjectsSelectedTab(newValue);
            }}
            tabStyle={{
              fontSize: isMobile ? 18 : 20,
              marginRight: isMobile ? '0.5rem' : '2rem',
              paddingBottom: 16,
              marginTop: isMobile ? 8 : 0,
            }}
          />
        </div>
        <FtrSearchBar
          autoFocus
          onSearch={setSearchStr}
          placeholder='Search parts, IDs and project'
          useDebounceSearch={true}
          searchTerm={searchStr}
          style={{
            margin: 0,
            width: isMobile && '100%',
            order: isMobile ? -1 : 0,
            marginTop: isMobile ? 0 : -12,
          }}
        />
      </FlexRowSpaceBetween>
      <FlexColumn
        style={{
          justifyContent: 'center',
          flex: 1,
          marginTop: isMobile ? '1rem' : '2rem',
          paddingBottom: '1rem',
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {isEmptyValue(searchStr) && renderTab()}
        {!isEmptyValue(searchStr) && renderCustomerProjectsSearchResult()}
      </FlexColumn>
      <Box style={{ height: '1.5rem', minHeight: '1.5rem', width: '100%' }} />
    </div>
  );
}

export default withRouter(withManageProjectsRevampBannerHOC(ManageProjectsV2));

import { useQuery } from 'react-query';
import _, { first } from 'lodash';

import { Chip, CircularProgress } from '@material-ui/core';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';

import CheckIcon from '../icons/CheckIcon';
import WarningIcon from '../icons/WarningIcon';

import { FlexRow } from '../layouts/FlexLayouts';

import { checkSuppliersDims } from '../../apis/rfqEmailSchedulesApi';
import { isEmptyValue } from '../../utils/commonUtils';

function SupplierRfqChip(props) {
  const {
    classes = {},
    disabled = false,
    selectedText = '',
    selectedKeys = [],
    handlePrepareDelete = () => {},
    flatItems = [],
    suggestedSuppliers = [],
    selectedParts = [],
    subTechs = [],
  } = props;

  const supplierId = parseInt(first(selectedKeys));
  const supplierDimsQueryEnabled = !(
    [selectedParts, supplierId].some(isEmptyValue) ||
    suggestedSuppliers.includes(supplierId.current)
  );

  const {
    data: supplierCapability,
    isFetched: hasFetchedSupplierCapabilityCheck,
    isLoading: isLoadingSupplierCapabilityCheck,
    isError: hasErrorFetchingSupplierCapabilityCheck,
  } = useQuery(
    [supplierId, selectedParts, subTechs],
    () =>
      checkSuppliersDims({
        itemIdList: selectedParts,
        subTechs: subTechs,
        supplierId: supplierId,
      }),
    {
      enabled: supplierDimsQueryEnabled,
    }
  );

  const renderSupplierChipSelected = (args) => {
    const { key = '', label = selectedText } = args ?? {};
    return (
      <Chip
        classes={{
          deleteIcon: classes.chipDeleteIcon,
          deletable: classes.chipDeletable,
        }}
        className={classes.chip}
        key={key}
        label={label}
        disabled={disabled}
        onMouseDown={(event) => event.stopPropagation()}
        deleteIcon={<HighlightOffIcon />}
        onDelete={handlePrepareDelete(first(selectedKeys))}
      />
    );
  };

  const renderSupplierCapabilityIcon = () => {
    // Render fallback if query should not be fired or has been fired and there is an error or the fetched data is invalid
    const renderFallback =
      !supplierDimsQueryEnabled ||
      hasErrorFetchingSupplierCapabilityCheck ||
      (hasFetchedSupplierCapabilityCheck && !supplierCapability);
    if (renderFallback) {
      return (
        <WarningIcon toolTipText='Supplier capability NOT checked for selected parts. Please verify before proceeding.' />
      );
    }

    if (isLoadingSupplierCapabilityCheck) {
      return <CircularProgress size='1.5rem' />;
    }
    if (supplierCapability?.capability?.capability_check) {
      return <CheckIcon toolTipText='Supplier capability match.' />;
    }
    return (
      <WarningIcon toolTipText='Supplier capability mismatch. Part dimensions exceeded.' />
    );
  };

  return (
    <div className={classes.chips}>
      <FlexRow>
        <div>
          {isEmptyValue(selectedText)
            ? selectedKeys?.map((key) => {
                const item = flatItems.find((i) => i.key === key);
                const label = item ? item.text : '';
                if (isEmptyValue(label)) {
                  return null;
                }
                return renderSupplierChipSelected({ key: key, label: label });
              })
            : renderSupplierChipSelected()}
        </div>
        <div>
          {suggestedSuppliers.includes(supplierId.current) ? (
            <CheckIcon toolTipText='Supplier capability match.' />
          ) : (
            renderSupplierCapabilityIcon()
          )}
        </div>
      </FlexRow>
    </div>
  );
}

export default SupplierRfqChip;

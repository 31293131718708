// Import settings
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles/index';

// Import material UI components
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

// Import color palette
import { colors } from '../palette';

// Import actions
import { resetAccount } from '../actions/index';
import ResetPasswordNotif from './ResetPasswordNotif';
import { validateEmail } from '../utils/validators/emailValidator';
import Background from '../components/backdrops/Background';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'grid',
    alignContent: 'center',
    justifyItems: 'center',
  },
  title: {
    marginTop: 10,
    marginBottom: 15,
    fontSize: '22pt',
    fontWeight: 'bold',
    letterSpacing: '-1pt',
  },
  subtitle: {
    fontSize: '14pt',
  },
  paper: {
    borderRadius: 5,
    display: 'flex',
    padding: '3.5rem 3rem',
    flexDirection: 'column',
    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('sm')]: {
      padding: '1.75rem 1.25rem',
    },
  },
  reset: {
    fontWeight: 600,
    fontSize: '14pt',
    marginBottom: '5px',
    marginTop: '1.875rem',
    textTransform: 'none',
    color: colors.fontWhite,
    borderRadius: '0.375rem',
    backgroundColor: colors.buttonBlue,
    '&:hover': {
      backgroundColor: colors.blue050,
    },
  },
  login: {
    fontWeight: 600,
    fontSize: '12pt',
    marginTop: '5px',
    color: colors.bgGrey,
    textTransform: 'none',
    '&:hover': {
      background: colors.fontWhite,
      color: colors.fontBlackGrey,
    },
  },
  inputField: {
    width: '100%',
    marginBottom: '0.8rem',
  },
  inputLabel: {
    width: '100%',
    fontWeight: 600,
    fontSize: '13pt',
    textAlign: 'left',
    color: colors.fontBlack,
  },
}));

function ResetPassword(props) {
  const classes = useStyles();

  const { isAuthenticated, resetAccount } = props;

  const [email, setEmail] = useState('');
  const [showResetNotifPage, setShowResetNotifPage] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      return toast.error('Incorrect email address. Please try again!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    resetAccount(email);
    setShowResetNotifPage(true);
  };

  const renderResetPasswordForm = () => {
    return (
      <Paper className={classes.paper}>
        <Background />
        <div className={classes.title}>Forgot your password?</div>
        <div className={classes.subtitle}>
          {`Enter your email and we'll send you a link to reset your password.`}
        </div>
        <Box style={{ height: '2rem' }}></Box>
        <Typography className={classes.inputLabel}>Email</Typography>
        <TextField
          id='email'
          name='email'
          className={classes.inputField}
          onChange={handleEmailChange}
          inputProps={{
            style: {
              textTransform: 'none',
              fontSize: '14pt',
            },
          }}
          size='small'
          variant='outlined'
        />
        <Button
          type='button'
          fullWidth
          variant='contained'
          margin='normal'
          data-cy='reset-password-link'
          className={classes.reset}
          onClick={handleSubmit}
        >
          Reset My Password
        </Button>
        <Button
          type='button'
          fullWidth
          variant='text'
          margin='normal'
          className={classes.login}
          component={Link}
          to='/login'
        >
          Back to Sign In
        </Button>
      </Paper>
    );
  };

  return (
    <Container component='main' className={classes.container} maxWidth='sm'>
      {showResetNotifPage && <ResetPasswordNotif email={email} />}
      {isAuthenticated && <Redirect to='/' />}
      {!showResetNotifPage && renderResetPasswordForm()}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    resetAccount: (email) => dispatch(resetAccount(email, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ResetPassword);
